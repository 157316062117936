import ResultFormComponent from './components'
import { useAppDispatch, useAppSelector } from 'store/store'
import { useEffect, useState } from 'react'
import { fillModalActions } from 'store/fillModal/fillModalSlice'
import { BillService } from 'services/Bill'
import { FillStatus } from 'services/Hub/types'
import { FetchBillResponse } from 'services/Bill/types'
import { HubService } from 'services/Hub'
import { SoftnoteColor } from 'models/Softnote'

interface Props {
    onClose: () => void
    softnoteData: FetchBillResponse
}

const ResultForm: React.FC<Props> = props => {
    const { onClose, softnoteData } = props

    const { currencyInfo, nominal, color } = softnoteData

    const [status, setStatus] = useState<FillStatus>(FillStatus.Pending)

    const dispatch = useAppDispatch()

    const handleClickDone = () => {
        dispatch(fillModalActions.reset())
        onClose()
    }

    const address = useAppSelector(state => state.fillModal.address)
    const senderAddress = useAppSelector(state => state.fillModal.senderAddress)
    const hashTransaction = useAppSelector(
        state => state.fillModal.hashTransaction
    )
    const selectedNetwork = useAppSelector(
        state => state.fillModal.selectedNetwork
    )

    const fillId = useAppSelector(state => state.fillModal.fillId)

    const [checkFillBlue] = BillService.useLazyCheckFillQuery()
    const [checkFillGreen] = HubService.useLazyCheckFillSoftnotesGreenQuery()

    useEffect(() => {
        const timerId = setInterval(async () => {
            try {
                if (status === FillStatus.Success) {
                    return
                }

                let response

                if (color === SoftnoteColor.Blue) {
                    response = await checkFillBlue({ id: fillId }).unwrap()
                }

                if (color === SoftnoteColor.Green) {
                    response = await checkFillGreen({ id: fillId }).unwrap()
                }

                const responseStatus = response?.status as FillStatus

                if (responseStatus === FillStatus.Pending) {
                    return
                }

                setStatus(responseStatus)
                clearInterval(timerId)
            } catch (error) {
                setStatus(FillStatus.Failed)
                clearInterval(timerId)
            }
        }, 5000)

        return () => {
            clearInterval(timerId)
            if (status === FillStatus.Success) {
                dispatch(BillService.util.invalidateTags(['Bill']))
            }
        }
    }, [checkFillBlue, checkFillGreen, color, fillId, status])

    return (
        <ResultFormComponent
            onClickDone={handleClickDone}
            nominal={nominal}
            address={address}
            senderAddress={senderAddress}
            hashTransaction={hashTransaction}
            status={status}
            currency={currencyInfo}
            isBillGreen={color === SoftnoteColor.Green}
            selectedNetwork={selectedNetwork}
        />
    )
}

export default ResultForm
