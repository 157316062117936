import { useAppDispatch, useAppSelector } from 'store/store'
import EnterAddressStepComponent from './component'
import { fillActions } from 'store/fill/fillSlice'
import { FillSteps } from 'store/fill/fillTypes'
import { useMediaQuery } from 'hooks/useMediaQuery'
import { MEDIA_QUERY_MOBILE } from 'utils/constants/mediaQueryConstants'
import { FormikProps, useFormik } from 'formik'
import { Values } from './types'
import validationSchema from './validationSchema'
import { useEffect, useState } from 'react'
import { HubService } from 'services/Hub'
import handleErrors from 'utils/functions/handleErrors'

const EnterAddressStep: React.FC = () => {
    const dispatch = useAppDispatch()

    const selectedCurrency = useAppSelector(
        state => state.fill.selectedBlueCurrency
    )

    const selectedNominal = useAppSelector(
        state => state.fill.selectedBlueNominal
    )

    const senderAddress = useAppSelector(state => state.fill.senderAddress)
    const hashTransaction = useAppSelector(state => state.fill.hashTransaction)
    const fillId = useAppSelector(state => state.fill.fillId)
    const serialNumber = useAppSelector(state => state.fill.serialNumber)

    const copyFieldValuesToStore = () => {
        dispatch(fillActions.setSenderAddress(formik.values.senderAddress))
        dispatch(fillActions.setHashTransaction(formik.values.hashTransaction))
    }

    const [validateOnChange, setValidateOnChange] = useState<boolean>(false)

    const [fillAccept, { data, error, isFetching }] =
        HubService.useLazyFillSoftnotesBlueAcceptQuery()

    const formik: FormikProps<Values> = useFormik<Values>({
        initialValues: { senderAddress, hashTransaction },
        validationSchema,
        validateOnChange,

        onSubmit: async (values: Values) => {
            copyFieldValuesToStore()

            if (!selectedCurrency || !selectedNominal) {
                return
            }

            fillAccept({
                id: fillId,
                serialNumber,
                address,
                addressSender: formik.values.senderAddress,
                hash: formik.values.hashTransaction,
                nominal: selectedNominal,
                currencyKey: selectedCurrency.key
            })
        }
    })

    const handleSubmit = () => {
        setValidateOnChange(true)
        formik.handleSubmit()
    }

    useEffect(() => {
        if (data) {
            dispatch(fillActions.setStep(FillSteps.result))
        }
    }, [data, dispatch])

    const handleClickBack = () => {
        copyFieldValuesToStore()
        dispatch(fillActions.setStep(FillSteps.enterVault))
    }

    const isMobile = useMediaQuery(MEDIA_QUERY_MOBILE)

    const address = useAppSelector(state => state.fill.address)

    return (
        <EnterAddressStepComponent
            selectedCurrency={selectedCurrency}
            onClickBack={handleClickBack}
            selectedNominal={selectedNominal}
            onSubmitForm={handleSubmit}
            isMobile={isMobile}
            address={address}
            formik={formik}
            error={handleErrors(error)}
            isFetching={isFetching}
        />
    )
}

export default EnterAddressStep
