import { useFormik, FormikProps } from 'formik'
import { Values } from './types'
import validationSchema from './validationSchema'
import { useCallback, useEffect, useState } from 'react'
import { useAppDispatch } from 'store/store'
import { handoverActions } from 'store/handover/handoverSlice'
import { HandoverSteps } from 'store/handover/handoverTypes'
import HandoverRequestComponent from './component'
import { billsCounter } from 'utils/functions/billsCounter'
import { HubService } from 'services/Hub'
import { parseSoftnotes } from 'utils/functions/parseSoftnotes'
import handleErrors from 'utils/functions/handleErrors'
import { useSoftnoteLimits } from 'hooks/useSoftnoteLimits'
import { hubActions } from 'store/hub/hubSlice'
import { AppRoutes } from 'routes/AppRoutes'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { PINCODE_REGEX } from 'utils/constants/regex'

const HandoverRequest: React.FC = (): React.ReactElement => {
    const [counter, setCounter] = useState<number>(0)
    const [fieldError, setFieldError] = useState<string>('')
    const [validateOnChange, setValidateOnChange] = useState(false)
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)

    const navigate = useNavigate()

    const { maxHandoverGreenBanknotes } = useSoftnoteLimits()

    const dispatch = useAppDispatch()

    const { t } = useTranslation('validation')

    const [handoverSoftnotes, { data, error, isFetching }] =
        HubService.useLazyHandoverSoftnotesQuery()

    const formik: FormikProps<Values> = useFormik<Values>({
        initialValues: {
            email: '',
            softnotes: '',
            token: null
        } as Values,
        validationSchema,
        validateOnChange,

        onSubmit: async (values: Values) => {
            try {
                setFieldError('')
                if (counter > maxHandoverGreenBanknotes) {
                    setFieldError(
                        t('youHaveExceededTheMaximumLimitOfBillsAllowed')
                    )
                    return
                }

                const softnotes = parseSoftnotes(values.softnotes)

                const isValidPincodes = softnotes.every(softnote =>
                    PINCODE_REGEX.test(softnote.pinCode)
                )

                if (!isValidPincodes) {
                    setFieldError(
                        t('onlyLatinLettersAndDigits', {
                            field: t(`fields.passcode`)
                        })
                    )
                    return
                }

                handoverSoftnotes({
                    email: values.email,
                    banknotes: softnotes,
                    token: values.token
                })
            } catch (error) {
                setFieldError(t('invalidDataFormat'))
            }
        }
    })

    useEffect(() => {
        if (data) {
            dispatch(handoverActions.setBanknotes(data.banknotes))
            dispatch(handoverActions.setStep(HandoverSteps.result))
        }
    }, [data])

    useEffect(() => {
        setCounter(billsCounter(formik.values.softnotes))
    }, [formik.values.softnotes])

    const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue('email', e.target.value)
    }

    const handleChangeSoftnotes = (
        e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const value = e.target.value
        formik.setFieldValue('softnotes', value)
        setFieldError('')
    }

    const handleSubmit = () => {
        setValidateOnChange(true)
        formik.handleSubmit()
        setRefreshReCaptcha(r => !r)
    }

    const onChangeCaptcha = useCallback(
        (value: string | null) => {
            formik.setFieldValue('token', value)
        },
        [refreshReCaptcha]
    )

    const handleClickBack = () => {
        dispatch(hubActions.setOperation(null))
        navigate(AppRoutes.hub)
    }

    return (
        <HandoverRequestComponent
            counter={counter}
            handleChangeSoftnotes={handleChangeSoftnotes}
            handleChangeEmail={handleChangeEmail}
            formik={formik}
            onSubmit={handleSubmit}
            error={handleErrors(error)}
            isLoading={isFetching}
            fieldError={fieldError}
            onChangeCaptcha={onChangeCaptcha}
            refreshReCaptcha={refreshReCaptcha}
            onClickBack={handleClickBack}
        />
    )
}

export default HandoverRequest
