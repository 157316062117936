import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/store'
import { burnActions } from 'store/burn/burnSlice'
import { BurnSteps } from 'store/burn/burnTypes'
import validationSchema from './validationSchema'
import BurnGreenRequestComponent from './component'
import { HubService } from 'services/Hub'
import handleErrors from 'utils/functions/handleErrors'
import { BurnStatus } from 'services/Hub/types'
import { FormikProps, useFormik } from 'formik'
import { Values } from './types'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { validate as uuidValidate } from 'uuid'
import { AppRoutes } from 'routes/AppRoutes'
import { hubActions } from 'store/hub/hubSlice'
import { OptionModel } from '@crispmindltd/client-react-package/dist/components/Select/types'
import { useCurrencies } from 'hooks/useCurrencies'

const BurnGreenConfirmation: React.FC = (): React.ReactElement => {
    const [searchParams, setSearchParams] = useSearchParams()
    const idParam = searchParams.get('id')
    const isGuid = idParam ? uuidValidate(idParam) : false

    const navigate = useNavigate()

    const { currencies } = useCurrencies('green')

    const burnInfoRequest = useAppSelector(state => state.burn.infoRequestGreen)
    const burnInfoResult = useAppSelector(state => state.burn.infoResultGreen)
    const guid = useAppSelector(state => state.burn.guid)
    const selectedNetwork = useAppSelector(state => state.burn.selectedNetwork)

    const [validateOnChange, setValidateOnChange] = useState(false)
    const [isSent, setIsSent] = useState(false)
    const [totals, setTotals] = useState({
        networkFee: burnInfoResult?.networkFee ?? 0,
        tectumFee: burnInfoResult?.tectumFee ?? 0,
        totalFee: burnInfoResult?.totalFee ?? 0,
        totalAmount: burnInfoResult?.totalAmount ?? 0
    })
    const [updateInterval, setUpdateInterval] = useState(10)

    const [burnSoftnotes, { data, error: burnError, isFetching }] =
        HubService.useLazyBurnGreenQuery()

    const [burnCheck, { data: burnResult, error: checkError }] =
        HubService.useLazyBurnCheckGreenQuery()

    const [getFee, { data: feeData, isFetching: isGettingFee }] =
        HubService.useLazyGetFeeQuery()

    const dispatch = useAppDispatch()

    const banknotesRequest = burnInfoRequest?.banknotes
    const banknotesResponse = burnInfoResult?.banknotes

    const formik: FormikProps<Values> = useFormik<Values>({
        initialValues: {
            email: '',
            address: ''
        } as Values,
        validationSchema,
        validateOnChange,

        onSubmit: async (values: Values) => {
            burnInfoRequest &&
                burnInfoResult &&
                banknotes &&
                selectedNetwork &&
                burnSoftnotes({
                    banknotes,
                    walletAddress: values.address,
                    networkFee: totals.networkFee,
                    email: values.email,
                    subProtocol: selectedNetwork.protocol,
                    subNetwork: selectedNetwork.network
                })
            setIsSent(true)
        }
    })

    const banknotes = banknotesRequest?.filter(item => {
        return banknotesResponse?.some(
            responseItem => responseItem.serialNumber === item.serialNumber
        )
    })

    const currency = currencies?.find(
        currency => currency.id === burnInfoResult?.currency
    )

    const {
        data: masterAccountData,
        error: networkError,
        isFetching: isNetworkLoading
    } = HubService.useGetMasterAccountQuery(
        {
            currencyKey: currency?.key ?? ''
        },
        { skip: isGuid || !currency }
    )

    const availableNetworksData = masterAccountData?.account.supportProtocols

    const availableNetworks = availableNetworksData?.map(protocol => ({
        value: protocol.address,
        text: `${protocol.network} (${protocol.protocol})`
    }))

    useEffect(() => {
        if (availableNetworksData?.length) {
            dispatch(burnActions.setSelectedNetwork(availableNetworksData[0]))
        }
    }, [availableNetworksData])

    const handleChangeNetwork = (value: OptionModel) => {
        const selectedNetwork = availableNetworksData?.find(
            network => network.address === value.value
        )

        selectedNetwork &&
            dispatch(burnActions.setSelectedNetwork(selectedNetwork))
    }

    const selectedNetworkOption = availableNetworks?.find(
        network => network.value === selectedNetwork?.address
    )

    const currencyCheck = currencies?.find(
        currency => currency.id === burnResult?.currency
    )

    const handleSubmit = () => {
        setValidateOnChange(true)
        formik.handleSubmit()
    }

    const handleClickBack = () => {
        dispatch(burnActions.reset())
        navigate(AppRoutes.burnGreen)
    }

    const handleClickBackToHub = () => {
        dispatch(hubActions.setOperation(null))
        navigate(AppRoutes.hub)
        dispatch(burnActions.reset())
    }

    useEffect(() => {
        if (currency && !isGuid) {
            const interval = setInterval(() => {
                if (isGettingFee) {
                    setUpdateInterval(10)
                    return
                }
                if (!isFetching) {
                    if (updateInterval > 1) {
                        setUpdateInterval(prevTime => prevTime - 1)
                    } else {
                        setUpdateInterval(10)
                        getFee(currency.key)
                    }
                } else {
                    setUpdateInterval(10)
                }
            }, 1000)

            return () => {
                clearInterval(interval)
            }
        }
    }, [currency, updateInterval, isFetching, isGettingFee, isGuid, getFee])

    useEffect(() => {
        if (feeData) {
            setTotals(prevTotals => {
                const newTotalFee = prevTotals.tectumFee + feeData.networkFee
                const totalAmountDiff = prevTotals.totalFee - newTotalFee

                return {
                    networkFee: feeData.networkFee,
                    tectumFee: prevTotals.tectumFee,
                    totalFee: newTotalFee,
                    totalAmount: prevTotals.totalAmount + totalAmountDiff
                }
            })
        }
    }, [feeData])

    useEffect(() => {
        if (data && !guid) {
            setSearchParams({
                id: data.requestId
            })

            dispatch(burnActions.setGuid(data.requestId))
        }
    }, [data, guid])

    useEffect(() => {
        if (idParam && isGuid) {
            burnCheck({
                requestId: idParam
            })
            const interval = setInterval(() => {
                burnCheck({
                    requestId: idParam
                })
            }, 15000)

            return () => {
                clearInterval(interval)
            }
        }
    }, [burnCheck, idParam, isGuid])

    useEffect(() => {
        if (burnResult) {
            if (burnResult.burnStatus !== BurnStatus.Pending) {
                burnResult.banknotes &&
                    dispatch(
                        burnActions.setBanknotesResultGreen(
                            burnResult.banknotes
                        )
                    )
                burnResult.hashTransactionOut &&
                    dispatch(
                        burnActions.setHashTransactionGreen(
                            burnResult.hashTransactionOut
                        )
                    )
                burnResult.totalAmount &&
                    dispatch(
                        burnActions.setTotalsGreen({
                            totalAmount: burnResult.totalAmount,
                            totalFee: burnResult.totalFee,
                            networkFee: burnResult.networkFee,
                            tectumFee: burnResult.tectumFee,
                            currency: burnResult.currency
                        })
                    )
                dispatch(burnActions.setStepGreen(BurnSteps.resultGreen))
            }
        }
    }, [burnResult])

    return (
        <BurnGreenRequestComponent
            onClickBack={handleClickBack}
            error={handleErrors(checkError || burnError)}
            isLoading={isFetching}
            isGettingFee={isGettingFee}
            onSubmit={handleSubmit}
            info={burnInfoResult}
            infoCheck={burnResult}
            currency={currency}
            currencyCheck={currencyCheck}
            formik={formik}
            isCheckPage={isGuid || Boolean(guid)}
            isSent={isSent}
            totals={totals}
            updateInterval={updateInterval}
            onClickBackToHub={handleClickBackToHub}
            selectedNetworkOption={selectedNetworkOption}
            networks={availableNetworks}
            onChangeNetwork={handleChangeNetwork}
            networkError={handleErrors(networkError)}
            isNetworkLoading={isNetworkLoading}
        />
    )
}

export default BurnGreenConfirmation
