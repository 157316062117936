import IconReset from 'assets/icons/cross.svg'
import { ChangeEvent, useState } from 'react'
import InputMask from 'react-input-mask'
import { Button, CopyButton } from '@crispmindltd/client-react-package'
import classNames from 'classnames'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'

interface Props {
    value: string
    onChange: (value: string) => void
    onSubmit: (value: string) => void
    softnoteFound?: boolean
    error?: string
}

const MultiInput: React.FC<Props> = (props: Props): React.ReactElement => {
    const { value, error, onChange, onSubmit, softnoteFound = false } = props

    const { t } = useTranslation('common')

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/[\s]/g, '')
        onChange(value)
    }

    const handleReset = () => {
        onChange('')

        if (!softnoteFound) {
            return
        }

        setShowSearchButton(true)
    }

    const handleInputClick = () => {
        if (!softnoteFound) {
            return
        }

        setShowSearchButton(true)
    }

    const handleSubmit = () => {
        setShowSearchButton(false)
        onSubmit(value)
    }

    const showResetButton = value !== ''
    const showCopyButton = softnoteFound && value !== ''

    const [showSearchButton, setShowSearchButton] = useState(false)

    return (
        <div
            className={classNames(styles.wrapper, {
                [styles.showSearchButton]: showSearchButton
            })}
        >
            <div className={styles.content}>
                <div className={styles.container}>
                    {showCopyButton && <CopyButton value={value} />}

                    <div className={styles.input} onClick={handleInputClick}>
                        <InputMask
                            value={value}
                            onChange={handleChange}
                            mask='99999  999  999  999  99'
                            maskChar='-'
                            placeholder='00000  000  000  000  00'
                        />
                    </div>

                    {showResetButton && (
                        <button className={styles.reset} onClick={handleReset}>
                            <img src={IconReset} alt='reset' />
                        </button>
                    )}
                </div>
                <span className={styles.error}>{error}</span>

                <div className={styles.searchButtonWrapper}>
                    <Button
                        className={styles.searchButton}
                        onClick={handleSubmit}
                    >
                        {t('buttons.search')}
                    </Button>
                </div>
            </div>

            <div
                className={styles.backdrop}
                onClick={() => setShowSearchButton(false)}
            />
        </div>
    )
}
export default MultiInput
