import styles from './styles.module.scss'

interface Props {
    type?: 'paragraph' | 'title' | 'row'
    className?: string
}

const Skeleton: React.FC<Props> = (props: Props): React.ReactElement => {
    const { type = 'paragraph', className } = props

    return (
        <div className={`${styles.wrapper} ${className ? className : ''}`}>
            {type === 'paragraph' && (
                <div className={styles.paragraph}>
                    <div />
                    <div />
                    <div />
                </div>
            )}
            {type === 'title' && (
                <div className={styles.title}>
                    <div />
                    <div />
                </div>
            )}
            {type === 'row' && (
                <div className={styles.row}>
                    <div />
                </div>
            )}
        </div>
    )
}
export default Skeleton
