import ReactDOM from 'react-dom/client'
import '@crispmindltd/client-react-package/dist/main.css'
import './index.css'
import App from './App'
import './i18n'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Provider } from 'react-redux'
import { store } from './store/store'
import { BrowserRouter } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const captchaKey = process.env.REACT_APP_GOOGLE_CAPTCHA ?? ''
root.render(
    <GoogleReCaptchaProvider reCaptchaKey={captchaKey}>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </GoogleReCaptchaProvider>
)
