import React, { useEffect, useLayoutEffect } from 'react'
import { BurnSteps } from 'store/burn/burnTypes'
import { useAppDispatch, useAppSelector } from 'store/store'
import BurnGreenRequest from './request'
import BurnGreenResult from './result'
import BurnGreenConfirmation from './confirmation'
import { useSearchParams } from 'react-router-dom'
import { validate as uuidValidate } from 'uuid'
import { burnActions } from 'store/burn/burnSlice'
import styles from './styles.module.scss'
import { hubActions } from 'store/hub/hubSlice'
import { HubOperations } from 'store/hub/hubTypes'

const BurnPageGreen: React.FC = (): React.ReactElement => {
    const step = useAppSelector(state => state.burn.stepGreen)
    const guid = useAppSelector(state => state.burn.guid)
    const dispatch = useAppDispatch()

    const [searchParams, setSearchParams] = useSearchParams()
    const idParam = searchParams.get('id')

    useEffect(() => {
        dispatch(hubActions.setOperation(HubOperations.burnGreen))

        if (!guid && idParam && uuidValidate(idParam)) {
            dispatch(burnActions.setStepGreen(BurnSteps.confirmationGreen))
            dispatch(burnActions.setGuid(idParam))
        }
    }, [idParam, guid])

    useLayoutEffect(() => {
        if (guid) {
            setSearchParams({
                id: guid
            })
        }
    }, [guid])

    return (
        <section className={styles.section}>
            {step === BurnSteps.requestGreen && <BurnGreenRequest />}
            {step === BurnSteps.confirmationGreen && <BurnGreenConfirmation />}
            {step === BurnSteps.resultGreen && <BurnGreenResult />}
        </section>
    )
}

export default BurnPageGreen
