import SectionHeader from 'components/shared/SectionHeader'
import styles from '../styles.module.scss'
import { Alert, Button, TextArea } from '@crispmindltd/client-react-package'
import classNames from 'classnames'
import { BanknoteResponse } from 'services/Hub/types'
import { AlertData } from './types'
import { useTranslation } from 'react-i18next'

interface Props {
    onClickBack: () => void
    onClickDone: () => void
    alertData: AlertData
    banknotesWithError: BanknoteResponse[] | undefined
    banknotesWithSuccess: BanknoteResponse[] | undefined
}

const ImportResultComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        onClickBack,
        onClickDone,
        alertData,
        banknotesWithError,
        banknotesWithSuccess
    } = props

    const { t } = useTranslation(['common', 'import'])

    return (
        <>
            <div className={styles.wrapper}>
                <SectionHeader
                    onClickBack={onClickBack}
                    title={t('labels.import')}
                />

                <Alert
                    icon
                    type={alertData.type}
                    text={alertData.text}
                    disableAnimation
                />

                {banknotesWithSuccess && banknotesWithSuccess.length > 0 && (
                    <TextArea
                        readOnly
                        withCopy
                        className={styles.textArea}
                        label={
                            <div className={styles.textAreaLabel}>
                                <div className={styles.title}>
                                    {t('importSoftnotes', { ns: 'import' })}
                                </div>
                                <div>{`${banknotesWithSuccess.length} ${t(
                                    'labels.bills'
                                )}`}</div>
                            </div>
                        }
                        value={banknotesWithSuccess
                            .map(el => `${el.serialNumber}`)
                            .join('\n')}
                    />
                )}

                {banknotesWithError && banknotesWithError.length > 0 && (
                    <TextArea
                        readOnly
                        withCopy
                        className={classNames(
                            styles.textArea,
                            styles.textAreaErrors
                        )}
                        label={
                            <div className={styles.textAreaLabel}>
                                <div className={styles.title}>
                                    {t('labels.errorSoftnotes')}
                                </div>
                                <div>{`${banknotesWithError.length} ${t(
                                    'labels.bills'
                                )}`}</div>
                            </div>
                        }
                        value={banknotesWithError
                            .map(el => `${el.serialNumber}`)
                            .join('\n')}
                    />
                )}
            </div>

            <div className={styles.bottom}>
                <Button className={styles.btn} onClick={onClickDone}>
                    {t('buttons.done')}
                </Button>
            </div>
        </>
    )
}

export default ImportResultComponent
