export interface SendModalState {
    step: SendModalSteps
    operation: SendModalOperations
}

export enum SendModalSteps {
    request = 'request',
    result = 'result'
}

export enum SendModalOperations {
    import = 'import',
    deposit = 'deposit',
    handover = 'handover'
}
