import {
    BanknoteArg,
    BanknoteResponse,
    BurnPrepareGreenResponse,
    BurnSuccessFile
} from 'services/Hub/types'
import { Network } from 'store/fill/fillTypes'

export interface BurnState {
    stepBlue: BurnSteps
    stepGreen: BurnSteps
    banknotesWithErrorBlue: BanknoteResponse[] | null
    burnSuccessFileBlue: BurnSuccessFile | null
    banknotesResultGreen: BanknoteResponse[] | null
    infoRequestGreen: infoRequestGreen | null
    infoResultGreen: BurnPrepareGreenResponse | null
    hashTransactionGreen: string | null
    totalsGreen: TotalsGreen
    guid: string | null
    selectedNetwork: Network | null
}

export interface infoRequestGreen {
    banknotes: BanknoteArg[]
}

export enum BurnSteps {
    requestBlue = 'requestBlue',
    resultBlue = 'resultBlue',
    requestGreen = 'requestGreen',
    confirmationGreen = 'confirmationGreen',
    resultGreen = 'resultGreen'
}

export interface TotalsGreen {
    totalAmount: number
    totalFee: number
    tectumFee: number
    networkFee: number
    currency: number
}
