import { Link, useLocation } from 'react-router-dom'
import styles from './index.module.scss'
import { AppRoutes } from 'routes/AppRoutes'
import { useTranslation } from 'react-i18next'

const SoftNoteTabMenu: React.FC = (): React.ReactElement => {
    const { pathname } = useLocation()
    const { t } = useTranslation('common')

    const isActive = pathname === AppRoutes.bill
    const hubLink = isActive ? AppRoutes.hub : '#'

    return (
        <div className={styles.wrapper}>
            <Link
                to={hubLink}
                className={isActive ? styles.tab : styles.active}
            >
                {t('labels.softnoteHub')}
            </Link>
            <Link
                to={AppRoutes.bill}
                className={isActive ? styles.active : styles.tab}
            >
                {t('labels.softnoteBill')}
            </Link>
        </div>
    )
}
export default SoftNoteTabMenu
