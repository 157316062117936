import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Currency, FillState, FillSteps, Network } from './fillTypes'

const initialState: FillState = {
    step: FillSteps.selectCurrency,
    stepGreen: FillSteps.selectCurrency,
    selectedBlueCurrency: null,
    selectedGreenCurrency: null,
    selectedBlueNominal: null,
    vaultTab: 'email',
    tectumId: '',
    vaultId: '',
    email: '',
    address: '',
    senderAddress: '',
    hashTransaction: '',
    nominalTab: 'pool',
    fillAmount: 0,
    selectedGreenNominals: {},
    fillId: '',
    serialNumber: '',
    selectedNetwork: null
}

const fillSlice = createSlice({
    name: 'fill',
    initialState,
    reducers: {
        setStep: (state, { payload }: PayloadAction<FillSteps>) => {
            state.step = payload
        },
        setStepGreen: (state, { payload }: PayloadAction<FillSteps>) => {
            state.stepGreen = payload
        },
        setSelectedBlueCurrency: (
            state,
            { payload }: PayloadAction<Currency>
        ) => {
            state.selectedBlueCurrency = payload
        },
        setSelectedGreenCurrency: (
            state,
            { payload }: PayloadAction<Currency>
        ) => {
            state.selectedGreenCurrency = payload
        },
        setSelectedNominal: (state, { payload }: PayloadAction<number>) => {
            state.selectedBlueNominal = payload
        },
        setVaultTab: (state, { payload }: PayloadAction<string>) => {
            state.vaultTab = payload
        },
        setTectumId: (state, { payload }: PayloadAction<string>) => {
            state.tectumId = payload
        },
        setVaultId: (state, { payload }: PayloadAction<string>) => {
            state.vaultId = payload
        },
        setEmail: (state, { payload }: PayloadAction<string>) => {
            state.email = payload
        },
        setAddress: (state, { payload }: PayloadAction<string>) => {
            state.address = payload
        },
        setSenderAddress: (state, { payload }: PayloadAction<string>) => {
            state.senderAddress = payload
        },
        setHashTransaction: (state, { payload }: PayloadAction<string>) => {
            state.hashTransaction = payload
        },
        setNominalTab: (state, { payload }: PayloadAction<string>) => {
            state.nominalTab = payload
        },
        setFillAmount: (state, { payload }: PayloadAction<number>) => {
            state.fillAmount = payload
        },
        setSelectedGreenNominals: (
            state,
            { payload }: PayloadAction<Record<number, number>>
        ) => {
            state.selectedGreenNominals = payload
        },
        setFillId: (state, { payload }: PayloadAction<string>) => {
            state.fillId = payload
        },
        setSerialNumber: (state, { payload }: PayloadAction<string>) => {
            state.serialNumber = payload
        },
        setSelectedNetwork: (state, { payload }: PayloadAction<Network | null>) => {
            state.selectedNetwork = payload
        },
        reset: () => initialState
    }
})

export const { actions: fillActions } = fillSlice

export default fillSlice
