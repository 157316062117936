import { FillSteps } from 'store/fill/fillTypes'
import { useAppDispatch, useAppSelector } from 'store/store'
import SelectCurrencyStep from './selectCurrency'
import SelectNominalStep from './selectNominal'
import EnterVaultStep from './enterVault'
import EnterAddressStep from './enterAddress'
import ResultStep from './result'
import styles from './styles.module.scss'
import { hubActions } from 'store/hub/hubSlice'
import { HubOperations } from 'store/hub/hubTypes'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { validate as uuidValidate } from 'uuid'
import StatusCheck from './statusCheck'

const FillBluePage: React.FC = (): React.ReactElement => {
    const step = useAppSelector(state => state.fill.step)
    const dispatch = useAppDispatch()

    const [searchParams] = useSearchParams()
    const idParam = searchParams.get('id')
    const isUuid = idParam ? uuidValidate(idParam) : false

    useEffect(() => {
        !isUuid && dispatch(hubActions.setOperation(HubOperations.fillBlue))
    }, [isUuid])

    const stepMap = {
        [FillSteps.selectCurrency]: <SelectCurrencyStep />,
        [FillSteps.selectNominal]: <SelectNominalStep />,
        [FillSteps.enterVault]: <EnterVaultStep />,
        [FillSteps.enterAddress]: <EnterAddressStep />,
        [FillSteps.result]: <ResultStep />
    }

    const getComponentByStep = (step: FillSteps) => {
        return stepMap[step]
    }

    return (
        <section className={styles.section}>
            {isUuid ? <StatusCheck /> : getComponentByStep(step)}
        </section>
    )
}

export default FillBluePage
