import classNames from 'classnames'
import { Button } from '@crispmindltd/client-react-package'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'

interface Props {
    tab: string
    onChangeTab: (tab: string) => void
}

const AmountPollTab: React.FC<Props> = props => {
    const { tab, onChangeTab } = props

    const { t } = useTranslation(['common', 'fill'])

    return (
        <div className={classNames(styles.tab)}>
            <Button
                type={tab === 'pool' ? 'primary' : 'secondary'}
                onClick={() => onChangeTab('pool')}
            >
                {t('poolSoftnotes', { ns: 'fill' })}
            </Button>
            <Button
                type={tab === 'amount' ? 'primary' : 'secondary'}
                onClick={() => onChangeTab('amount')}
            >
                {t('labels.amount')}
            </Button>
        </div>
    )
}

export default AmountPollTab
