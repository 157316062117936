import { Network } from 'store/fill/fillTypes'

export interface FillModalState {
    step: FillModalSteps
    vaultTab: string
    tectumId: string
    vaultId: string
    email: string
    address: string
    senderAddress: string
    hashTransaction: string
    fillId: string
    selectedNetwork: Network | null
}
export enum FillModalSteps {
    enterVault = 'enterVault',
    enterAddress = 'enterAddress',
    result = 'result'
}
