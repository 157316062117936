import classnames from 'classnames'
import IconCheck from 'assets/icons/check.svg'
import styles from './styles.module.scss'

interface Props {
    checked?: boolean
    onClick?: () => void
    size?: string
    className?: string
}

const Checkbox: React.FC<Props> = (props: Props): React.ReactElement => {
    const { checked, onClick, size, className } = props
    return (
        <div
            className={classnames(
                styles.wrapper,
                className,
                size === 'small' ? styles.small : ''
            )}
        >
            <input
                type='checkbox'
                checked={checked}
                onClick={onClick}
                readOnly
            />
            <img src={IconCheck} alt='' />
            <span></span>
        </div>
    )
}
export default Checkbox
