import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ImportState, ImportSteps } from './importTypes'
import { BanknoteResponse } from 'services/Hub/types'

const initialState: ImportState = {
    step: ImportSteps.request,
    banknotes: null
}

const importSlice = createSlice({
    name: 'import',
    initialState,
    reducers: {
        setStep: (state, { payload }: PayloadAction<ImportSteps>) => {
            state.step = payload
        },
        setBanknotes: (
            state,
            { payload }: PayloadAction<BanknoteResponse[] | null>
        ) => {
            state.banknotes = payload
        },
        reset: () => initialState
    }
})

export const { actions: importActions } = importSlice

export default importSlice
