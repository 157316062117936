import EnterVaultEmailFormComponent from './components'
import { FormikProps, useFormik } from 'formik'
import validationSchema from './validationSchema'
import { useAppDispatch, useAppSelector } from 'store/store'
import { Values } from './types'
import { useCallback, useEffect, useState } from 'react'
import { fillModalActions } from 'store/fillModal/fillModalSlice'
import { FillModalSteps } from 'store/fillModal/fillModalTypes'
import { BillService } from 'services/Bill'
import handleErrors from 'utils/functions/handleErrors'
import { FetchBillResponse } from 'services/Bill/types'
import { SoftnoteColor } from 'models/Softnote'

interface Props {
    softnoteData: FetchBillResponse
}

const EnterVaultEmailForm: React.FC<Props> = props => {
    const { softnoteData } = props

    const { serialNumber, color } = softnoteData

    const [validateOnChange, setValidateOnChange] = useState(false)
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)

    const dispatch = useAppDispatch()

    const tectumId = useAppSelector(state => state.fillModal.tectumId)
    const vaultId = useAppSelector(state => state.fillModal.vaultId)
    const email = useAppSelector(state => state.fillModal.email)
    const fillId = useAppSelector(state => state.fillModal.fillId)

    const [
        startFillBlue,
        { data: dataBlue, error: errorBlue, isFetching: isFetchingBlue }
    ] = BillService.useLazyFillBlueStartQuery()

    const [
        startFillGreen,
        { data: dataGreen, error: errorGreen, isFetching: isFetchingGreen }
    ] = BillService.useLazyFillGreenStartQuery()

    const formik: FormikProps<Values> = useFormik<Values>({
        initialValues: { tectumId, vaultId, email, token: null },
        validationSchema,
        validateOnChange,

        onSubmit: (values: Values) => {
            if (fillId) {
                dispatch(fillModalActions.setStep(FillModalSteps.enterAddress))
                return
            }
            const { email, tectumId, token } = values

            dispatch(fillModalActions.setTectumId(tectumId))
            dispatch(fillModalActions.setVaultId(vaultId))
            dispatch(fillModalActions.setEmail(email))

            const startFillByColor = {
                [SoftnoteColor.Blue]: startFillBlue,
                [SoftnoteColor.Green]: startFillGreen
            }

            const startFill = startFillByColor[color]

            startFill({
                serialNumber,
                email,
                vaultId: null,
                tectumId: tectumId === '' ? null : Number(tectumId),
                token
            })
        }
    })

    const handleSubmit = () => {
        setValidateOnChange(true)
        formik.handleSubmit()
        setRefreshReCaptcha(r => !r)
    }

    const vaultTab = useAppSelector(state => state.fillModal.vaultTab)

    const handleChangeVaultTab = (tab: string) => {
        dispatch(fillModalActions.setVaultTab(tab))
    }

    useEffect(() => {
        if (dataBlue) {
            const { address, id } = dataBlue
            dispatch(fillModalActions.setFillId(id))
            dispatch(fillModalActions.setAddress(address))
            dispatch(fillModalActions.setStep(FillModalSteps.enterAddress))
        }

        if (dataGreen) {
            dispatch(fillModalActions.setFillId(dataGreen.id))
            dispatch(fillModalActions.setStep(FillModalSteps.enterAddress))
        }
    }, [dataBlue, dataGreen, dispatch])

    const onChangeCaptcha = useCallback(
        (value: string | null) => {
            formik.setFieldValue('token', value)
        },
        [refreshReCaptcha]
    )

    return (
        <EnterVaultEmailFormComponent
            formik={formik}
            onChangeVaultTab={handleChangeVaultTab}
            vaultTab={vaultTab}
            onSubmitForm={handleSubmit}
            error={handleErrors(errorBlue || errorGreen)}
            isFetching={isFetchingBlue || isFetchingGreen}
            onChangeCaptcha={onChangeCaptcha}
            refreshReCaptcha={refreshReCaptcha}
        />
    )
}

export default EnterVaultEmailForm
