import React, { useLayoutEffect } from 'react'
import styles from './index.module.scss'
import ActionLink from 'components/shared/ActionLink'
import { ReactComponent as IconImport } from 'assets/icons/import.svg'
import { ReactComponent as IconFill } from 'assets/icons/fill.svg'
import { ReactComponent as IconDeposit } from 'assets/icons/deposit.svg'
import { ReactComponent as IconHandover } from 'assets/icons/handover.svg'
import { ReactComponent as IconAccept } from 'assets/icons/accept.svg'
import { ReactComponent as IconBurn } from 'assets/icons/burn.svg'
import { AppRoutes } from 'routes/AppRoutes'
import { useAppSelector } from 'store/store'
import { useNavigate } from 'react-router-dom'
import { operationRoutes } from 'routes/OperationRoutes'
import { useTranslation } from 'react-i18next'

const HubPage: React.FC = (): React.ReactElement => {
    const navigate = useNavigate()

    const { t } = useTranslation('common')

    const operation = useAppSelector(state => state.hub.operation)

    useLayoutEffect(() => {
        if (operation) {
            const route = operationRoutes[operation]
            navigate(route, { replace: true })
        }
    }, [operation])

    return (
        <div className={styles.container}>
            <h3 className={styles.title}>{t('whatDoYouWantToDo')}</h3>
            <div className={styles.links}>
                <ActionLink
                    label={t('labels.fill')}
                    link={AppRoutes.fillBlue}
                    icon={<IconFill />}
                    className={styles.link}
                />
                <ActionLink
                    label={t('labels.import')}
                    link={AppRoutes.import}
                    icon={<IconImport />}
                    className={styles.link}
                />
                <ActionLink
                    label={t('labels.deposit')}
                    link={AppRoutes.deposit}
                    icon={<IconDeposit />}
                    className={styles.link}
                    disabled
                />
                <ActionLink
                    label={t('labels.handover')}
                    link={AppRoutes.handover}
                    icon={<IconHandover />}
                    className={styles.link}
                />
                <ActionLink
                    label={t('labels.accept')}
                    link={AppRoutes.accept}
                    icon={<IconAccept />}
                    className={styles.link}
                />
                <ActionLink
                    label={t('labels.burn')}
                    link={AppRoutes.burnBlue}
                    icon={<IconBurn />}
                    className={styles.link}
                />
            </div>
        </div>
    )
}

export default HubPage
