import { networks } from 'utils/constants/socialNetworks'
import styles from './index.module.scss'
import { IconLink } from 'components/Icons'
import { MEDIA_QUERY_TABLET } from 'utils/constants/mediaQueryConstants'
import { useMediaQuery } from 'hooks/useMediaQuery'
import { useLocation } from 'react-router-dom'
import { AppRoutes } from 'routes/AppRoutes'
import { useTranslation } from 'react-i18next'
import { Trans } from 'react-i18next'

const Footer: React.FC = () => {
    const { t } = useTranslation('common')
    const isMobile = useMediaQuery(MEDIA_QUERY_TABLET)
    const { pathname } = useLocation()

    const isHiddenOnMobile =
        isMobile && pathname !== AppRoutes.hub && pathname !== AppRoutes.bill

    if (isHiddenOnMobile) {
        return null
    }

    return (
        <footer className={styles.wrapper}>
            <span className={styles.beta}>{t('beta')}</span>

            <span className={styles.beta}>
                <Trans
                    i18nKey='recaptchaText'
                    t={t}
                    components={{
                        privacyLink: (
                            <a
                                href={'https://policies.google.com/privacy'}
                                target='_blank'
                                rel='noreferrer'
                            >
                                Privacy Policy
                            </a>
                        ),
                        termsLink: (
                            <a
                                href={'https://policies.google.com/terms'}
                                target='_blank'
                                rel='noreferrer'
                            >
                                Terms of Service
                            </a>
                        )
                    }}
                />
            </span>

            <span className={styles.beta}>©2024 Crispmind</span>

            <div className={styles.container}>
                {networks.map(item => (
                    <a
                        href={item.link}
                        key={item.id}
                        className={styles.link}
                        target='_blank'
                        rel='noreferrer'
                    >
                        {item.icon}
                        {!isMobile && <span>{item.title}</span>}
                        {!isMobile && <IconLink />}
                    </a>
                ))}
            </div>
        </footer>
    )
}
export default Footer
