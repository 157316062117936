import { ImportSteps } from 'store/import/importTypes'
import { useAppDispatch, useAppSelector } from 'store/store'
import ImportRequest from './request'
import ImportResult from './result'
import styles from './styles.module.scss'
import { useEffect } from 'react'
import { hubActions } from 'store/hub/hubSlice'
import { HubOperations } from 'store/hub/hubTypes'

const ImportPage: React.FC = (): React.ReactElement => {
    const step = useAppSelector(state => state.import.step)

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(hubActions.setOperation(HubOperations.import))
    }, [])

    return (
        <section className={styles.section}>
            {step === ImportSteps.request && <ImportRequest />}
            {step === ImportSteps.result && <ImportResult />}
        </section>
    )
}

export default ImportPage
