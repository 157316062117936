import SectionHeader from 'components/shared/SectionHeader'
import { Currency } from 'store/fill/fillTypes'
import { Alert, Button, TextField } from '@crispmindltd/client-react-package'
import AmountPollTab from 'pages/FillGreen/components/AmountPollTab'
import { FormikProps } from 'formik'
import { Values } from './types'
import styles from '../../styles.module.scss'
import { getCurrencyLabel } from 'utils/functions/currency'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useTranslation } from 'react-i18next'

interface Props {
    selectedCurrency: Currency | null
    onClickBack: () => void
    nominalTab: string
    onChangeNominalTab: (tab: string) => void
    formik: FormikProps<Values>
    closestAmount: number | null
    onClickClosestAmountButton: () => void
    error: string
    isFetching: boolean
    onChangeCaptcha: (value: string | null) => void
    refreshReCaptcha: boolean
    onChangeFillAmount: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const AmountTabComponent: React.FC<Props> = props => {
    const {
        selectedCurrency,
        onClickBack,
        nominalTab,
        onChangeNominalTab,
        formik,
        closestAmount,
        onClickClosestAmountButton,
        error,
        isFetching,
        onChangeCaptcha,
        refreshReCaptcha,
        onChangeFillAmount
    } = props

    const { t } = useTranslation(['common', 'fill'])

    return (
        <>
            <SectionHeader
                title={`${t('labels.fill')} ${getCurrencyLabel(
                    selectedCurrency
                )}`}
                subTitle={t('greenHub', { ns: 'fill' })}
                subTitleColor='#00E979'
                onClickBack={onClickBack}
            />

            <AmountPollTab tab={nominalTab} onChangeTab={onChangeNominalTab} />

            <div className={styles.label}>{t('labels.newFeature')}</div>

            <TextField
                name='fillAmount'
                value={formik.values.fillAmount}
                onChange={onChangeFillAmount}
                placeholder='0'
                handleClear={() => formik.setFieldValue('fillAmount', '')}
                label={`${t('labels.amount')}, ${selectedCurrency?.ticker}`}
                error={formik.errors.fillAmount}
                disabled
            />

            {error && !closestAmount && (
                <Alert
                    type='error'
                    text={error}
                    icon
                    className={styles.alert}
                />
            )}

            {closestAmount && (
                <div className={styles.closestAmountWrapper}>
                    <TextField
                        value={closestAmount}
                        readOnly
                        label='Closest number to preferred amount, USDT'
                    />
                    <div className={styles.closestAmountButtonWrapper}>
                        <button
                            className={styles.closestAmountButton}
                            onClick={onClickClosestAmountButton}
                        >
                            Select given amount
                        </button>
                    </div>
                </div>
            )}

            <div className={styles.bottom}>
                <Button
                    className={styles.nextButton}
                    disabled={
                        !Number(formik.values.fillAmount) ||
                        Boolean(formik.errors.fillAmount)
                    }
                    onClick={formik.handleSubmit}
                    isLoading={isFetching}
                >
                    {t('buttons.next')}
                </Button>

                <GoogleReCaptcha
                    refreshReCaptcha={refreshReCaptcha}
                    onVerify={onChangeCaptcha}
                />
            </div>
        </>
    )
}

export default AmountTabComponent
