import { useAppDispatch, useAppSelector } from 'store/store'
import { handoverActions } from 'store/handover/handoverSlice'
import HandoverResultComponent from './component'
import { useNavigate } from 'react-router-dom'
import { AlertData } from './types'
import { AppRoutes } from 'routes/AppRoutes'
import { hubActions } from 'store/hub/hubSlice'
import { useTranslation } from 'react-i18next'

const HandoverResult: React.FC = (): React.ReactElement => {
    const dispatch = useAppDispatch()

    const navigate = useNavigate()

    const banknotes = useAppSelector(state => state.handover.banknotes)

    const banknotesWithSuccess = banknotes?.filter(el => el.errorCode === null)

    const banknotesWithError = banknotes?.filter(el => el.errorCode !== null)

    const { t } = useTranslation('handover')

    const getAlertData = (): AlertData => {
        if (banknotesWithSuccess && banknotesWithError) {
            if (
                banknotesWithSuccess.length === 0 &&
                banknotesWithError.length > 0
            ) {
                return {
                    type: 'error',
                    text: t('notHandedOver')
                }
            }
            if (
                banknotesWithSuccess.length > 0 &&
                banknotesWithError.length > 0
            ) {
                return {
                    type: 'warning',
                    text: t('halfHandedOver')
                }
            }
            if (
                banknotesWithSuccess.length > 0 &&
                banknotesWithError.length === 0
            ) {
                return { type: 'success', text: t('success') }
            }
        }

        return { type: 'error', text: t('error') }
    }

    const onClickBack = () => {
        dispatch(handoverActions.reset())
    }

    const handleClickDone = () => {
        dispatch(handoverActions.reset())
        dispatch(hubActions.setOperation(null))
        navigate(AppRoutes.hub)
    }

    return (
        <HandoverResultComponent
            onClickBack={onClickBack}
            onClickDone={handleClickDone}
            alertData={getAlertData()}
            banknotesWithSuccess={banknotesWithSuccess}
            banknotesWithError={banknotesWithError}
        />
    )
}

export default HandoverResult
