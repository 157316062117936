import { useAppDispatch, useAppSelector } from 'store/store'
import AcceptResultComponent from './component'
import { useNavigate } from 'react-router'
import { AppRoutes } from 'routes/AppRoutes'
import { burnActions } from 'store/burn/burnSlice'
import { AlertData } from './types'
import { useEffect, useRef } from 'react'
import { createDownloadUrlFromBase64 } from 'utils/functions/createDownloadUrlFromBase64 '
import styles from '../styles.module.scss'
import { hubActions } from 'store/hub/hubSlice'
import { useTranslation } from 'react-i18next'

const BurnBlueResult: React.FC = (): React.ReactElement => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const banknotesWithError = useAppSelector(
        state => state.burn.banknotesWithErrorBlue
    )

    const burnSuccessFile = useAppSelector(
        state => state.burn.burnSuccessFileBlue
    )

    const { t } = useTranslation('burn')

    const linkRef = useRef<HTMLAnchorElement | null>(null)

    const downloadLink = burnSuccessFile && (
        <a
            ref={linkRef}
            href={createDownloadUrlFromBase64(burnSuccessFile.content)}
            className={styles.link}
            download={`${t('burn')}-${Date.now()}.zip`}
        >
            {t('clickHere')}
        </a>
    )

    useEffect(() => {
        if (burnSuccessFile && linkRef.current) {
            linkRef.current.click()
        }
    }, [burnSuccessFile])

    const getAlertData = (): AlertData => {
        if (banknotesWithError) {
            if (!burnSuccessFile && banknotesWithError.length > 0) {
                return {
                    type: 'error',
                    text: t('notBurned')
                }
            }
            if (burnSuccessFile && banknotesWithError.length > 0) {
                return {
                    type: 'warning',
                    text: (
                        <>
                            {t('halfBurned')}
                            <br />
                            <br />
                            {t('zipWillBeDownloaded')}
                            <br />
                            <br />
                            {t('ifNotStarted')} {downloadLink}
                            <br />
                            <br />
                            {t('afterBurn')}
                        </>
                    )
                }
            }
            if (burnSuccessFile && banknotesWithError.length === 0) {
                return {
                    type: 'success',
                    text: (
                        <>
                            {t('success')}
                            <br />
                            <br />
                            {t('zipWillBeDownloaded')}
                            <br />
                            <br />
                            {t('ifNotStarted')} {downloadLink}
                            <br />
                            <br />
                            {t('afterBurn')}
                        </>
                    )
                }
            }
        }

        return { type: 'error', text: t('error') }
    }

    const handleClickDone = () => {
        dispatch(burnActions.reset())
        dispatch(hubActions.setOperation(null))
        navigate(AppRoutes.hub)
    }

    const handleClickBack = () => {
        dispatch(burnActions.reset())
    }

    return (
        <AcceptResultComponent
            onClickDone={handleClickDone}
            onClickBack={handleClickBack}
            alertData={getAlertData()}
            banknotesWithError={banknotesWithError}
        />
    )
}

export default BurnBlueResult
