import { BanknoteArg } from 'services/Hub/types'

export const parseSoftnotes = (data: string): BanknoteArg[] => {
    const lines = data.trim().replaceAll(' ', '\n').split('\n')
    const banknotes: BanknoteArg[] = []

    for (const line of lines) {
        if (line.trim() !== '') {
            const [serialNumber, pinCode] = line.split('/')

            banknotes.push({
                serialNumber: serialNumber.trim(),
                pinCode: pinCode.trim()
            })
        }
    }

    return banknotes
}
