import EnterAddressFormComponent from './components'
import { FormikProps, useFormik } from 'formik'
import { useAppDispatch, useAppSelector } from 'store/store'
import validationSchema from './validationSchema'
import { useEffect, useState } from 'react'
import { fillModalActions } from 'store/fillModal/fillModalSlice'
import { FillModalSteps } from 'store/fillModal/fillModalTypes'
import { Values } from './types'
import { useMediaQuery } from 'hooks/useMediaQuery'
import { MEDIA_QUERY_MOBILE } from 'utils/constants/mediaQueryConstants'
import { BillService } from 'services/Bill'
import handleErrors from 'utils/functions/handleErrors'
import { FetchBillResponse } from 'services/Bill/types'
import { SoftnoteColor } from 'models/Softnote'
import { HubService } from 'services/Hub'
import { OptionModel } from '@crispmindltd/client-react-package/dist/components/Select/types'

interface Props {
    softnoteData: FetchBillResponse
}

const EnterAddressForm: React.FC<Props> = props => {
    const { softnoteData } = props

    const { currencyInfo, serialNumber, nominal, color } = softnoteData

    const [validateOnChange, setValidateOnChange] = useState<boolean>(false)

    const dispatch = useAppDispatch()

    const isBillGreen = color === SoftnoteColor.Green
    const selectedNetwork = useAppSelector(
        state => state.fillModal.selectedNetwork
    )

    const copyFieldValuesToStore = () => {
        dispatch(fillModalActions.setSenderAddress(formik.values.senderAddress))
        dispatch(
            fillModalActions.setHashTransaction(formik.values.hashTransaction)
        )
    }

    const {
        data: masterAccountData,
        error: networkError,
        isFetching: isNetworkLoading
    } = HubService.useGetMasterAccountQuery(
        {
            currencyKey: currencyInfo.key
        },
        { skip: !isBillGreen }
    )

    const availableNetworksData = masterAccountData?.account.supportProtocols

    const availableNetworks = availableNetworksData?.map(protocol => ({
        value: protocol.address,
        text: `${protocol.network} (${protocol.protocol})`
    }))

    useEffect(() => {
        if (availableNetworksData?.length) {
            dispatch(
                fillModalActions.setSelectedNetwork(availableNetworksData[0])
            )
            dispatch(
                fillModalActions.setAddress(availableNetworksData[0].address)
            )
        }
    }, [availableNetworksData])

    const handleChangeNetwork = (value: OptionModel) => {
        const selectedNetwork = availableNetworksData?.find(
            network => network.address === value.value
        )

        if (selectedNetwork) {
            dispatch(fillModalActions.setSelectedNetwork(selectedNetwork))
            dispatch(fillModalActions.setAddress(selectedNetwork.address))
        }
    }

    const selectedNetworkOption = availableNetworks?.find(
        network => network.value === selectedNetwork?.address
    )

    const address = useAppSelector(state => state.fillModal.address)
    const senderAddress = useAppSelector(state => state.fillModal.senderAddress)
    const hashTransaction = useAppSelector(
        state => state.fillModal.hashTransaction
    )
    const fillId = useAppSelector(state => state.fillModal.fillId)

    const [
        fillAcceptBlue,
        { data: dataBlue, error: errorBlue, isFetching: isFetchingBlue }
    ] = BillService.useLazyFillBlueAcceptQuery()

    const [
        fillAcceptGreen,
        { data: dataGreen, error: errorGreen, isFetching: isFetchingGreen }
    ] = HubService.useLazyFillGreenAcceptQuery()

    const isMobile = useMediaQuery(MEDIA_QUERY_MOBILE)

    const formik: FormikProps<Values> = useFormik<Values>({
        initialValues: { senderAddress, hashTransaction },
        validationSchema,
        validateOnChange,

        onSubmit: ({ senderAddress, hashTransaction }: Values) => {
            copyFieldValuesToStore()

            if (color === SoftnoteColor.Blue) {
                fillAcceptBlue({
                    id: fillId,
                    serialNumber,
                    address,
                    addressSender: senderAddress,
                    hash: hashTransaction,
                    nominal,
                    currency: currencyInfo.key
                })
            }

            if (isBillGreen) {
                if (!selectedNetwork) return

                fillAcceptGreen({
                    id: fillId,
                    addressSender: senderAddress,
                    hash: hashTransaction,
                    protocol: currencyInfo.protocol,
                    ticker: currencyInfo.ticker,
                    subProtocol: selectedNetwork.protocol,
                    subNetwork: selectedNetwork.network,
                    serialNumber,
                    amount: nominal
                })
            }
        }
    })

    const handleSubmit = () => {
        setValidateOnChange(true)
        formik.handleSubmit()
    }

    useEffect(() => {
        if (dataBlue || dataGreen) {
            dispatch(fillModalActions.setStep(FillModalSteps.result))
        }
    }, [dataBlue, dataGreen, dispatch])

    const handleClickBack = () => {
        copyFieldValuesToStore()
        dispatch(fillModalActions.setStep(FillModalSteps.enterVault))
    }

    return (
        <EnterAddressFormComponent
            formik={formik}
            onSubmitForm={handleSubmit}
            onClickBack={handleClickBack}
            address={address}
            nominal={nominal}
            isMobile={isMobile}
            error={handleErrors(errorBlue || errorGreen)}
            isFetching={isFetchingBlue || isFetchingGreen}
            currency={currencyInfo}
            isBillGreen={isBillGreen}
            networks={availableNetworks}
            selectedNetworkOption={selectedNetworkOption}
            onChangeNetwork={handleChangeNetwork}
            networkError={handleErrors(networkError)}
            isNetworkLoading={isNetworkLoading}
        />
    )
}

export default EnterAddressForm
