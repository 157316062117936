import i18n from 'i18n'
import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

export default function handleErrors(error: unknown) {
    const typedError = error as
        | FetchBaseQueryError
        | SerializedError
        | undefined

    if (typedError === undefined) {
        return ''
    }

    if (
        'status' in typedError &&
        typeof typedError.data === 'object' &&
        typedError.data !== null &&
        'errorMsgs' in typedError.data &&
        'errorCode' in typedError.data
    ) {
        if (
            Array.isArray(typedError.data.errorMsgs) &&
            typedError.data.errorMsgs.length > 0
        ) {
            return (typedError.data.errorMsgs as string[])[0]
        } else {
            return i18n.t('somethingWrong', { ns: 'validation' })
        }
    }

    if ('message' in typedError && typedError.message) {
        return typedError.message
    }

    return i18n.t('somethingWrong', { ns: 'validation' })
}
