export const NFTBills = [
    '0000100000000271',
    '0000100000000272',
    '0000100000000273',
    '0000100000000274',
    '0000100000000275',
    '0000100000000276',
    '0000100000000277',
    '0000100000000278',
    '0000100000000279',
    '0000100000000280'
]

export const rocketNFT = '0000100000000271'
