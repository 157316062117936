import { useAppSelector } from 'store/store'
import RequestForm from './SendForm'
import { useTranslation } from 'react-i18next'
import { ModalWrapper } from '@crispmindltd/client-react-package'

interface Props {
    isOpen: boolean
    onClose: () => void
    data: any
}

const SendModal: React.FC<Props> = (props: Props) => {
    const { isOpen, onClose, data } = props

    const { t } = useTranslation('common')

    const title = useAppSelector(state => state.sendModal.operation)

    return (
        <ModalWrapper
            open={isOpen}
            onClose={onClose}
            title={t(`labels.${title}`)}
        >
            <RequestForm data={data} onClose={onClose} />
        </ModalWrapper>
    )
}

export default SendModal
