import { ModalWrapper } from '@crispmindltd/client-react-package'
import AcceptForm from './AcceptForm'
import { useTranslation } from 'react-i18next'

interface Props {
    isOpen: boolean
    onClose: () => void
    data: any
}

const AcceptModal: React.FC<Props> = (props: Props) => {
    const { isOpen, onClose, data } = props

    const { t } = useTranslation('common')

    return (
        <ModalWrapper
            open={isOpen}
            onClose={onClose}
            title={t('labels.accept')}
        >
            <AcceptForm data={data} onClose={onClose} />
        </ModalWrapper>
    )
}

export default AcceptModal
