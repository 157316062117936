import { AlertStatus } from '@crispmindltd/client-react-package/dist/components/Alert/types'
import { useTranslation } from 'react-i18next'
import { FillStatus } from 'services/Hub/types'

export function useFillStatuses() {
    const { t } = useTranslation('common')

    const statuses: Record<
        FillStatus,
        { alertType: AlertStatus; alertText: string }
    > = {
        Init: { alertType: 'warning', alertText: t('statuses.pending') },
        Pending: { alertType: 'warning', alertText: t('statuses.pending') },
        Failed: { alertType: 'error', alertText: t('statuses.failedToFill') },
        Cancel: { alertType: 'error', alertText: t('statuses.error') },
        Success: { alertType: 'success', alertText: t('statuses.success') }
    }

    return statuses
}
