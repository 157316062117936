export enum SoftnoteColor {
    Blue = 'Blue',
    Green = 'Green'
}

export enum SoftnoteStatus {
    None = 0,
    Filled = 1,
    InWallet = 10,
    Coinaged = 11,
    Burned = 12,
    InVault = 111
}
