import React, { useEffect, useState } from 'react'
import BillPageComponent from './component'
import { BillService } from 'services/Bill'
import { useSearchParams } from 'react-router-dom'
import { useAppDispatch } from 'store/store'
import { fillModalActions } from 'store/fillModal/fillModalSlice'
import { NFTBills } from 'utils/constants/NFTBills'
import handleErrors from 'utils/functions/handleErrors'

const BillPage: React.FC = (): React.ReactElement => {
    const [isAcceptModalOpen, setAcceptModalOpen] = useState(false)
    const [isSendModalOpen, setSendModalOpen] = useState(false)
    const [isBurnModalOpen, setBurnModalOpen] = useState(false)
    const [isFillModalOpen, setFillModalOpen] = useState(false)

    const [fetchBill, { data, error, isFetching }] =
        BillService.useLazyFetchBillQuery()

    const dispatch = useAppDispatch()

    const openModal = (operation: string) => {
        if (!data) {
            return
        }
        switch (operation) {
            case 'accept':
                setAcceptModalOpen(true)
                break
            case 'send':
                setSendModalOpen(true)
                break
            case 'burn':
                setBurnModalOpen(true)
                break
            case 'fill':
                setFillModalOpen(true)
                break
            default:
                return
        }
    }

    const closeModal = (operation: string) => {
        switch (operation) {
            case 'accept':
                setAcceptModalOpen(false)
                break
            case 'send':
                setSendModalOpen(false)
                break
            case 'burn':
                setBurnModalOpen(false)
                break
            case 'fill':
                setFillModalOpen(false)
                break
            default:
                return
        }
    }

    const [searchParams, setSearchParams] = useSearchParams()

    const [number, setNumber] = useState(
        () => searchParams.get('serialNumber') ?? ''
    )

    const onSubmit = () => {
        setSearchParams({
            serialNumber: number
        })
    }

    useEffect(() => {
        const serialNumberParam = searchParams.get('serialNumber')

        if (serialNumberParam) {
            fetchBill({
                serialNumber: serialNumberParam
            })
        }
    }, [searchParams, fetchBill])

    useEffect(() => {
        dispatch(fillModalActions.reset())
    }, [data])

    const isNFT = data ? NFTBills.includes(data.serialNumber) : false

    return (
        <BillPageComponent
            number={number}
            onChangeNumber={setNumber}
            onSubmit={onSubmit}
            isLoading={isFetching}
            error={handleErrors(error)}
            data={data}
            isAcceptModalOpen={isAcceptModalOpen}
            isSendModalOpen={isSendModalOpen}
            isBurnModalOpen={isBurnModalOpen}
            isFillModalOpen={isFillModalOpen}
            closeModal={closeModal}
            openModal={openModal}
            isNFT={isNFT}
        />
    )
}

export default BillPage
