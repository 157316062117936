import { Button } from '@crispmindltd/client-react-package'
import { AppRoutes } from 'routes/AppRoutes'

import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'

const Page404: React.FC = (): React.ReactElement => {
    const { t } = useTranslation('404')

    return (
        <section className={styles.wrapper}>
            <div className={styles.container}>
                <h1 className={styles.title}>
                    404 <br />
                    {t('notFound')}
                </h1>

                <p className={styles.text}>{t('pageCannotBeFound')}</p>

                <Button className={styles.button} href={AppRoutes.hub}>
                    {t('backToCash')}
                </Button>
            </div>
        </section>
    )
}
export default Page404
