export interface FillState {
    step: FillSteps
    stepGreen: FillSteps
    selectedBlueCurrency: Currency | null
    selectedGreenCurrency: Currency | null
    selectedBlueNominal: number | null
    vaultTab: string
    tectumId: string
    vaultId: string
    email: string
    address: string
    senderAddress: string
    hashTransaction: string
    nominalTab: string
    fillAmount: number
    selectedGreenNominals: Record<number, number>
    fillId: string
    serialNumber: string
    selectedNetwork: Network | null
}
export enum FillSteps {
    selectCurrency = 'selectCurrency',
    selectNominal = 'selectNominal',
    enterVault = 'enterVault',
    enterAddress = 'enterAddress',
    result = 'result'
}

export interface Currency {
    id: number
    network: string
    protocol: string
    ticker: string
    key: string
    decimal: number
    isActual: boolean
}

export interface Network {
    protocol: string
    network: string
    address: string
}
