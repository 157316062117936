import SectionHeader from 'components/shared/SectionHeader'
import styles from '../styles.module.scss'
import {
    Alert,
    Button,
    TextArea,
    TextField
} from '@crispmindltd/client-react-package'
import { FormikProps } from 'formik'
import { Values } from './types'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useSoftnoteLimits } from 'hooks/useSoftnoteLimits'
import { useTranslation } from 'react-i18next'
import ExampleDataFormat from 'components/shared/ExampleDataFormat'

interface Props {
    formik: FormikProps<Values>
    handleChangeId: (e: React.ChangeEvent<HTMLInputElement>) => void
    handleChangeSoftnotes: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
    counter: number
    onSubmit: () => void
    error: string
    isLoading: boolean
    fieldError: string
    onChangeCaptcha: (value: string | null) => void
    refreshReCaptcha: boolean
    onClickBack: () => void
}

const ImportRequestComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        formik,
        handleChangeId,
        handleChangeSoftnotes,
        counter,
        onSubmit,
        error,
        isLoading,
        fieldError,
        onChangeCaptcha,
        refreshReCaptcha,
        onClickBack
    } = props

    const { t } = useTranslation(['common', 'import'])

    const { maxImportBanknotes } = useSoftnoteLimits()

    return (
        <>
            <div className={styles.wrapper}>
                <SectionHeader
                    title={t('labels.import')}
                    onClickBack={onClickBack}
                />

                {error && <Alert type='error' text={error} icon />}

                <TextField
                    className={styles.input}
                    label={t('labels.tectumID')}
                    onChange={handleChangeId}
                    name='id'
                    value={formik.values.id}
                    error={formik.errors.id}
                />
                <TextArea
                    className={styles.textArea}
                    placeholder={t('placeholders.softNoteSerialPasscode')}
                    onChange={handleChangeSoftnotes}
                    label={
                        <div className={styles.textAreaLabel}>
                            <div className={styles.title}>
                                {t('labels.softnotes')}
                            </div>
                            <div
                                className={
                                    counter <= maxImportBanknotes
                                        ? styles.counter
                                        : styles.counterError
                                }
                            >
                                {t('billsCount', {
                                    counter: counter,
                                    maxImportBanknotes: maxImportBanknotes,
                                    ns: 'import'
                                })}
                            </div>
                        </div>
                    }
                    value={formik.values.softnotes}
                    name='softnotes'
                    error={fieldError || formik.errors.softnotes}
                />
            </div>

            <ExampleDataFormat />

            <div className={styles.bottom}>
                <Button
                    className={styles.btn}
                    onClick={onSubmit}
                    isLoading={isLoading}
                    disabled={!formik.values.id || !formik.values.softnotes}
                >
                    {t('buttons.finish')}
                </Button>

                <GoogleReCaptcha
                    refreshReCaptcha={refreshReCaptcha}
                    onVerify={onChangeCaptcha}
                />
            </div>
        </>
    )
}

export default ImportRequestComponent
