import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
    BurnState,
    BurnSteps,
    TotalsGreen,
    infoRequestGreen
} from './burnTypes'
import {
    BanknoteResponse,
    BurnPrepareGreenResponse,
    BurnSuccessFile
} from 'services/Hub/types'
import { Network } from 'store/fill/fillTypes'

const initialState: BurnState = {
    stepBlue: BurnSteps.requestBlue,
    stepGreen: BurnSteps.requestGreen,
    banknotesWithErrorBlue: null,
    burnSuccessFileBlue: null,
    banknotesResultGreen: null,
    hashTransactionGreen: null,
    infoRequestGreen: null,
    infoResultGreen: null,
    totalsGreen: {
        totalAmount: 0,
        totalFee: 0,
        tectumFee: 0,
        networkFee: 0,
        currency: 501
    },
    guid: null,
    selectedNetwork: null
}

const burnSlice = createSlice({
    name: 'burn',
    initialState,
    reducers: {
        setStepBlue: (state, { payload }: PayloadAction<BurnSteps>) => {
            state.stepBlue = payload
        },
        setStepGreen: (state, { payload }: PayloadAction<BurnSteps>) => {
            state.stepGreen = payload
        },
        setBanknotesWithErrorBlue: (
            state,
            { payload }: PayloadAction<BanknoteResponse[] | null>
        ) => {
            state.banknotesWithErrorBlue = payload
        },
        setBanknotesResultGreen: (
            state,
            { payload }: PayloadAction<BanknoteResponse[] | null>
        ) => {
            state.banknotesResultGreen = payload
        },
        setSuccessFileBlue: (
            state,
            { payload }: PayloadAction<BurnSuccessFile>
        ) => {
            state.burnSuccessFileBlue = payload
        },
        setHashTransactionGreen: (
            state,
            { payload }: PayloadAction<string>
        ) => {
            state.hashTransactionGreen = payload
        },
        setinfoRequestGreen: (
            state,
            { payload }: PayloadAction<infoRequestGreen>
        ) => {
            state.infoRequestGreen = payload
        },
        setinfoResultGreen: (
            state,
            { payload }: PayloadAction<BurnPrepareGreenResponse>
        ) => {
            state.infoResultGreen = payload
        },
        setTotalsGreen: (state, { payload }: PayloadAction<TotalsGreen>) => {
            state.totalsGreen = payload
        },
        setGuid: (state, { payload }: PayloadAction<string>) => {
            state.guid = payload
        },
        setSelectedNetwork: (state, { payload }: PayloadAction<Network>) => {
            state.selectedNetwork = payload
        },
        reset: () => initialState
    }
})

export const { actions: burnActions } = burnSlice

export default burnSlice
