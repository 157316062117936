import SectionHeader from 'components/shared/SectionHeader'
import styles from '../styles.module.scss'
import { Alert, Button, TextArea } from '@crispmindltd/client-react-package'
import classNames from 'classnames'
import { AlertData } from './types'
import { BanknoteResponse } from 'services/Hub/types'
import { useTranslation } from 'react-i18next'

interface Props {
    onClickBack: () => void
    onClickDone: () => void
    alertData: AlertData
    banknotesWithError: BanknoteResponse[] | null
}

const BurnBlueResultComponent: React.FC<Props> = (
    props
): React.ReactElement => {
    const { onClickBack, onClickDone, alertData, banknotesWithError } = props

    const { t } = useTranslation(['common', 'burn'])

    return (
        <>
            <div className={styles.wrapper}>
                <SectionHeader
                    onClickBack={onClickBack}
                    title={t('labels.burn')}
                    subTitle={t('blueHub', { ns: 'burn' })}
                    subTitleColor='#336BFF'
                />

                <Alert icon {...alertData} disableAnimation />

                {banknotesWithError && banknotesWithError.length > 0 && (
                    <TextArea
                        readOnly
                        withCopy
                        className={classNames(
                            styles.textArea,
                            styles.textAreaErrors
                        )}
                        label={
                            <div className={styles.textAreaLabel}>
                                <div className={styles.title}>
                                    {t('labels.errorSoftnotes')}
                                </div>
                                <div>{`${banknotesWithError.length} ${t(
                                    'labels.bills'
                                )}`}</div>
                            </div>
                        }
                        value={banknotesWithError
                            .map(el => `${el.serialNumber}`)
                            .join('\n')}
                    />
                )}
            </div>

            <div className={styles.bottom}>
                <Button onClick={onClickDone} className={styles.btn}>
                    {t('buttons.done')}
                </Button>
            </div>
        </>
    )
}

export default BurnBlueResultComponent
