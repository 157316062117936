import { Link } from 'react-router-dom'
import styles from './index.module.scss'

interface Props {
    icon?: string
    onClick?: () => void
    disabled?: boolean
    label: string
    href?: string
}

const OperationButton: React.FC<Props> = (props: Props) => {
    const { icon, onClick, disabled, label, href } = props

    const handleClick = () => {
        if (!disabled) onClick?.()
    }

    const buttonChildren = (
        <>
            {icon && <img src={icon} alt='icon' />}
            <span>{label}</span>
        </>
    )

    const buttonProps = {
        className: disabled ? styles.disabled : styles.button,
        onClick: handleClick
    }

    return href ? (
        href.startsWith('http') ? (
            <a href={href} target='_blank' rel='noreferrer' {...buttonProps}>
                {buttonChildren}
            </a>
        ) : (
            <Link to={href} {...buttonProps}>
                {buttonChildren}
            </Link>
        )
    ) : (
        <button {...buttonProps}>{buttonChildren}</button>
    )
}

export default OperationButton
