import { HubService } from 'services/Hub'
import {
    maxSoftnotesBurnLimit,
    maxSoftnotesBurnLimitGreen,
    maxSoftnotesFillLimitGreen,
    maxSoftnotesSendLimit
} from 'utils/constants/hubConstants'

export function useSoftnoteLimits() {
    const {
        data: {
            maxHandoverBanknotes = maxSoftnotesSendLimit,
            maxHandoverGreenBanknotes = maxSoftnotesSendLimit,
            maxAcceptBanknotes = maxSoftnotesSendLimit,
            maxAcceptGreenBanknotes = maxSoftnotesSendLimit,
            maxBurnBanknotes = maxSoftnotesBurnLimit,
            maxBurnGreenBanknotes = maxSoftnotesBurnLimitGreen,
            maxImportBanknotes = maxSoftnotesSendLimit,
            maxFillGreenBanknotes = maxSoftnotesFillLimitGreen
        } = {}
    } = HubService.useGetLimitsQuery()

    return {
        maxHandoverBanknotes,
        maxHandoverGreenBanknotes,
        maxAcceptBanknotes,
        maxAcceptGreenBanknotes,
        maxBurnBanknotes,
        maxBurnGreenBanknotes,
        maxImportBanknotes,
        maxFillGreenBanknotes
    }
}
