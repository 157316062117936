import SectionHeader from 'components/shared/SectionHeader'
import styles from '../styles.module.scss'
import {
    Alert,
    Button,
    Select,
    TextField,
    Tooltip
} from '@crispmindltd/client-react-package'
import BillsInfo from './BillsInfo'
import {
    BurnCheckGreenResponse,
    BurnPrepareGreenResponse
} from 'services/Hub/types'
import { Currency } from 'store/fill/fillTypes'
import { FormikProps } from 'formik'
import { Values } from './types'
import Skeleton from 'components/shared/Skeleton'
import { formatNumber } from 'utils/functions/format'
import { useTranslation } from 'react-i18next'
import { OptionModel } from '@crispmindltd/client-react-package/dist/components/Select/types'
import classNames from 'classnames'

interface Props {
    onClickBack: () => void
    error: string
    isLoading: boolean
    onSubmit: () => void
    info: BurnPrepareGreenResponse | null
    infoCheck: BurnCheckGreenResponse | undefined
    currency: Currency | undefined
    currencyCheck: Currency | undefined
    formik: FormikProps<Values>
    isCheckPage: boolean
    isSent: boolean
    totals: {
        networkFee: number
        tectumFee: number
        totalFee: number
        totalAmount: number
    }
    isGettingFee: boolean
    updateInterval: number
    onClickBackToHub: () => void
    networks: OptionModel[] | undefined
    selectedNetworkOption: OptionModel | undefined
    onChangeNetwork: (value: OptionModel) => void
    networkError: string
    isNetworkLoading: boolean
}

const BurnGreenConfirmationComponent: React.FC<Props> = props => {
    const {
        onClickBack,
        error,
        isLoading,
        onSubmit,
        info,
        currency,
        formik,
        isCheckPage,
        infoCheck,
        currencyCheck,
        isSent,
        totals,
        isGettingFee,
        updateInterval,
        onClickBackToHub,
        networks,
        selectedNetworkOption,
        onChangeNetwork,
        networkError,
        isNetworkLoading
    } = props

    const { t } = useTranslation(['common', 'burn', 'fill'])

    return (
        <>
            <div className={styles.wrapper}>
                <SectionHeader
                    onClickBack={onClickBack}
                    title={t('labels.burn')}
                    subTitle={t('greenHub', { ns: 'fill' })}
                    subTitleColor='#00E979'
                    withBackButton={!isCheckPage}
                />

                {error && <Alert type='error' text={error} icon />}

                {isCheckPage && !error && (
                    <Alert
                        type='warning'
                        text={`${t('statuses.pending')}${
                            isSent
                                ? `. ${t('linkHasBeenSent', { ns: 'burn' })}`
                                : ''
                        }`}
                        icon
                        disableAnimation
                    />
                )}

                {info && !isCheckPage && (
                    <BillsInfo info={info} currency={currency} />
                )}

                {infoCheck && (
                    <BillsInfo info={infoCheck} currency={currencyCheck} />
                )}

                {infoCheck && (
                    <TextField
                        name='address'
                        label={t('labels.address')}
                        value={infoCheck.walletAddress}
                        readOnly
                    />
                )}

                {!isCheckPage && (
                    <Select
                        className={styles.select}
                        options={networks}
                        selected={selectedNetworkOption?.text}
                        onChange={onChangeNetwork}
                        readOnly={networks && networks.length <= 1}
                        label={
                            <div className={styles.selectLabel}>
                                {t('labels.network')}{' '}
                                <Tooltip
                                    value={t('labels.burnNetwork')}
                                    pointerPosition='left-top'
                                />
                            </div>
                        }
                        error={networkError}
                        isLoading={isNetworkLoading}
                    />
                )}

                {!isCheckPage && (
                    <TextField
                        error={formik.errors.address}
                        name='address'
                        label={t('labels.address')}
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        handleClear={() => formik.setFieldValue('address', '')}
                    />
                )}

                {!isCheckPage && (
                    <TextField
                        error={formik.errors.email}
                        name='email'
                        label={t('labels.email')}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        handleClear={() => formik.setFieldValue('email', '')}
                    />
                )}
            </div>

            {currency && !isCheckPage && (
                <div className={styles.sofnotesSummaryWrapper}>
                    <div className={styles.sofnotesSummary}>
                        <div className={styles.sofnotesSummaryRow}>
                            <span className={styles.fee}>
                                {t('networkFee', { ns: 'burn' })}:
                            </span>
                            {isGettingFee ? (
                                <Skeleton
                                    type='row'
                                    className={styles.skeleton}
                                />
                            ) : (
                                <span className={styles.fee}>
                                    {`${formatNumber(
                                        totals.networkFee,
                                        currency.decimal
                                    )} ${currency.ticker}`}
                                </span>
                            )}
                        </div>
                        <div className={styles.sofnotesSummaryRow}>
                            <span className={styles.fee}>
                                {t('tectumFee', { ns: 'burn' })}:
                            </span>
                            {isGettingFee ? (
                                <Skeleton
                                    type='row'
                                    className={styles.skeleton}
                                />
                            ) : (
                                <span className={styles.fee}>
                                    {`${formatNumber(
                                        totals.tectumFee,
                                        currency.decimal
                                    )} ${currency.ticker}`}
                                </span>
                            )}
                        </div>
                        <div className={styles.sofnotesSummaryRow}>
                            <span className={styles.sofnotesSummaryLabel}>
                                {t('totalFee', { ns: 'burn' })}:
                            </span>
                            {isGettingFee ? (
                                <Skeleton
                                    type='row'
                                    className={styles.skeleton}
                                />
                            ) : (
                                <span className={styles.sofnotesSummaryValue}>
                                    {`${formatNumber(
                                        totals.totalFee,
                                        currency.decimal
                                    )} ${currency.ticker}`}
                                </span>
                            )}
                        </div>

                        <div className={styles.sofnotesSummaryRow}>
                            <span className={styles.sofnotesSummaryLabel}>
                                {t('totalAmount', { ns: 'burn' })}:
                            </span>
                            {isGettingFee ? (
                                <Skeleton
                                    type='row'
                                    className={styles.skeleton}
                                />
                            ) : (
                                <span
                                    className={classNames(
                                        styles.sofnotesSummaryValue,
                                        {
                                            [styles.errorAmount]:
                                                totals.totalAmount < 0
                                        }
                                    )}
                                >
                                    {`${formatNumber(
                                        totals.totalAmount,
                                        currency.decimal
                                    )} ${currency.ticker}`}
                                </span>
                            )}
                        </div>

                        {!isLoading && (
                            <span className={styles.fee}>
                                {isGettingFee
                                    ? t('updatingFee', { ns: 'burn' })
                                    : t('updateFee', {
                                          updateInterval: updateInterval,
                                          ns: 'burn'
                                      })}
                            </span>
                        )}
                    </div>

                    <Button
                        className={styles.btn}
                        onClick={onSubmit}
                        isLoading={isLoading}
                        disabled={
                            totals.totalAmount < 0 || isGettingFee || isLoading
                        }
                    >
                        {t('buttons.finish')}
                    </Button>
                </div>
            )}

            {currencyCheck && infoCheck && (
                <div className={styles.sofnotesSummaryWrapper}>
                    <div className={styles.sofnotesSummary}>
                        <div className={styles.sofnotesSummaryRow}>
                            <span className={styles.fee}>
                                {t('networkFee', { ns: 'burn' })}:
                            </span>
                            <span className={styles.fee}>
                                {`${formatNumber(
                                    infoCheck.networkFee,
                                    currencyCheck.decimal
                                )} ${currencyCheck.ticker}`}
                            </span>
                        </div>
                        <div className={styles.sofnotesSummaryRow}>
                            <span className={styles.fee}>
                                {t('tectumFee', { ns: 'burn' })}:
                            </span>
                            <span className={styles.fee}>
                                {`${formatNumber(
                                    infoCheck.tectumFee,
                                    currencyCheck.decimal
                                )} ${currencyCheck.ticker}`}
                            </span>
                        </div>
                        <div className={styles.sofnotesSummaryRow}>
                            <span className={styles.sofnotesSummaryLabel}>
                                {t('totalFee', { ns: 'burn' })}:
                            </span>
                            <span className={styles.sofnotesSummaryValue}>
                                {`${formatNumber(
                                    infoCheck.totalFee,
                                    currencyCheck.decimal
                                )} ${currencyCheck.ticker}`}
                            </span>
                        </div>

                        <div className={styles.sofnotesSummaryRow}>
                            <span className={styles.sofnotesSummaryLabel}>
                                {t('totalAmount', { ns: 'burn' })}:
                            </span>
                            <span className={styles.sofnotesSummaryValue}>
                                {`${formatNumber(
                                    infoCheck.totalAmount,
                                    currencyCheck.decimal
                                )} ${currencyCheck.ticker}`}
                            </span>
                        </div>
                    </div>

                    <Button className={styles.btn} onClick={onClickBackToHub}>
                        {t('buttons.backToHub')}
                    </Button>
                </div>
            )}
        </>
    )
}

export default BurnGreenConfirmationComponent
