import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import * as yup from 'yup'

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        supportedLngs: ['en', 'zh', 'tr'],
        detection: {
            order: ['localStorage', 'navigator', 'querystring'],
            caches: ['localStorage']
        },
        lowerCaseLng: true,
        cleanCode: true,
        interpolation: {
            escapeValue: false
        },
        react: {
            useSuspense: true
        },
        ns: ['validation']
    })
    .then(setLocale)

i18n.on('languageChanged', setLocale)

const t = (key, args) => {
    return i18n.t(key, { ...args, ns: 'validation' })
}

function setLocale() {
    yup.setLocale({
        mixed: {
            default: t('mixed.default'),
            required: params =>
                t('mixed.required', { field: t(`fields.${params.path}`) }),
            oneOf: params => t(`oneOf.${params.path}`),
            notOneOf: params => t('mixed.notOneOf', { values: params.values })
        },
        string: {
            length: params =>
                t('string.length', {
                    length: params.length,
                    field: t(`fields.${params.path}`)
                }),
            min: params =>
                t('string.min', {
                    min: params.min,
                    field: t(`fields.${params.path}`)
                }),
            max: params =>
                t('string.max', {
                    max: params.max,
                    field: t(`fields.${params.path}`)
                }),
            email: t('string.email'),
            url: t('string.url'),
            trim: t('string.trim'),
            lowercase: t('string.lowercase'),
            uppercase: t('string.uppercase')
        },
        number: {
            min: params => t('number.min', { min: params.min }),
            max: params => t('number.max', { max: params.max }),
            lessThan: params => t('number.lessThan', { less: params.less }),
            moreThan: params => t('number.moreThan', { more: params.more }),
            positive: t('number.positive'),
            negative: t('number.negative'),
            integer: t('number.integer')
        }
    })
}

export default i18n
