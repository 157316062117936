import { useState } from 'react'
import IconHelp from 'assets/icons/faqRound.svg'
import styles from './index.module.scss'
import HelpContent from './HelpContent'
import { useTranslation } from 'react-i18next'
import { ModalWrapper } from '@crispmindltd/client-react-package'

const HelpButton: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false)

    const { t } = useTranslation(['common', 'help'])

    const handleOpen = () => {
        setIsOpen(true)
    }

    const handleClose = () => {
        setIsOpen(false)
    }

    return (
        <>
            <button onClick={handleOpen} className={styles.button}>
                <img src={IconHelp} alt='help' className={styles.icon} />
            </button>

            <ModalWrapper
                open={isOpen}
                onClose={handleClose}
                title={t('labels.help')}
            >
                <HelpContent onClose={handleClose} />
            </ModalWrapper>
        </>
    )
}

export default HelpButton
