import {
    Alert,
    Button,
    ModalBody,
    ModalFooter,
    TextField
} from '@crispmindltd/client-react-package'
import styles from '../../styles.module.scss'
import { useTranslation } from 'react-i18next'
import { formatNumber } from 'utils/functions/format'
import { BurnCheckGreenResponse } from 'services/Hub/types'
import { useCurrencies } from 'hooks/useCurrencies'

interface Props {
    onClose: () => void
    resultData: BurnCheckGreenResponse
}

const BurnGreenSuccess: React.FC<Props> = props => {
    const { onClose, resultData } = props

    const { t } = useTranslation(['common', 'burn'])

    const { currencies } = useCurrencies('green')

    const currency = currencies?.find(item => item.id === resultData.currency)

    return (
        <>
            <ModalBody className={styles.content}>
                <Alert
                    style={{ marginBottom: '24px' }}
                    type='success'
                    text={t('successSingleGreen', { ns: 'burn' })}
                    icon
                />

                <div className={styles.fieldsWrapper}>
                    <TextField
                        name='hash'
                        value={resultData.hashTransactionOut}
                        readOnly
                        label={t('labels.hashTransaction')}
                        withCopy
                    />
                </div>
            </ModalBody>

            <ModalFooter className={styles.footer}>
                <div className={styles.sofnotesSummary}>
                    <div className={styles.sofnotesSummaryRow}>
                        <span className={styles.fee}>
                            {t('networkFee', { ns: 'burn' })}:
                        </span>

                        <span className={styles.fee}>
                            {`${formatNumber(
                                resultData.networkFee,
                                3
                            )} ${currency?.ticker}`}
                        </span>
                    </div>
                    <div className={styles.sofnotesSummaryRow}>
                        <span className={styles.fee}>
                            {t('tectumFee', { ns: 'burn' })}:
                        </span>

                        <span className={styles.fee}>
                            {`${formatNumber(
                                resultData.tectumFee,
                                3
                            )} ${currency?.ticker}`}
                        </span>
                    </div>
                    <div className={styles.sofnotesSummaryRow}>
                        <span className={styles.sofnotesSummaryLabel}>
                            {t('totalFee', { ns: 'burn' })}:
                        </span>

                        <span className={styles.sofnotesSummaryValue}>
                            {`${formatNumber(
                                resultData.totalFee,
                                3
                            )} ${currency?.ticker}`}
                        </span>
                    </div>

                    <div className={styles.sofnotesSummaryRow}>
                        <span className={styles.sofnotesSummaryLabel}>
                            {t('totalAmount', { ns: 'burn' })}:
                        </span>

                        <span className={styles.sofnotesSummaryValue}>
                            {`${formatNumber(
                                resultData.totalAmount,
                                3
                            )} ${currency?.ticker}`}
                        </span>
                    </div>
                </div>
                <Button className={styles.nextButton} onClick={onClose}>
                    {t('buttons.done')}
                </Button>
            </ModalFooter>
        </>
    )
}

export default BurnGreenSuccess
