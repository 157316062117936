import {
    IconDiscord,
    IconFacebook,
    IconInstagram,
    IconLinkedin,
    IconTelegram,
    IconTwitter,
    IconYoutube
} from 'components/Icons'

export const networks = [
    {
        id: 1,
        title: 'Facebook',
        link: 'https://www.facebook.com/Tectum.io/',
        icon: <IconFacebook />
    },
    {
        id: 2,
        title: 'Telegram',
        link: 'https://t.me/tectumglobal',
        icon: <IconTelegram />
    },
    {
        id: 3,
        title: 'LinkedIn',
        link: 'https://www.linkedin.com/showcase/tectum-blockchain',
        icon: <IconLinkedin />
    },
    {
        id: 4,
        title: 'YouTube',
        link: 'https://www.youtube.com/channel/UCn17IrKSqmIFn8illLRR2-g',
        icon: <IconYoutube />
    },
    {
        id: 5,
        title: 'Instagram',
        link: 'https://www.instagram.com/tectum_softnote',
        icon: <IconInstagram />
    },
    {
        id: 6,
        title: 'X',
        link: 'https://twitter.com/tectumsocial',
        icon: <IconTwitter />
    },
    {
        id: 7,
        title: 'Discord',
        link: 'https://discord.gg/TXREkPY8C7',
        icon: <IconDiscord />
    }
]
