import { useFormik } from 'formik'
import validationSchema from './validationSchema'
import { Values } from './types'
import { useCallback, useEffect, useState } from 'react'
import handleErrors from 'utils/functions/handleErrors'
import BurnFormGreenComponent from './components'
import { HubService } from 'services/Hub'
import BurnGreenSuccess from './BurnSuccess'
import { BurnStatus } from 'services/Hub/types'
import { OptionModel } from '@crispmindltd/client-react-package/dist/components/Select/types'
import { Network } from 'store/fill/fillTypes'
import { useCurrencies } from 'hooks/useCurrencies'

interface Props {
    data: any
    onClose: () => void
}

const BurnFormGreen: React.FC<Props> = props => {
    const { data, onClose } = props

    const [validateOnChange, setValidateOnChange] = useState(false)
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)
    const [selectedNetwork, setSelectedNetwork] = useState<Network | null>(null)

    const { currencies } = useCurrencies('green')

    const currency = currencies?.find(item => item.id === data?.currencyInfo.id)

    const [burnSoftnotes, { data: burnData, error, isFetching }] =
        HubService.useLazyBurnGreenQuery()

    const {
        data: infoData,
        error: infoError,
        isFetching: isInfoFetching,
        refetch
    } = HubService.useBurnPrepareGreenQuery({
        banknotes: [{ serialNumber: data.serialNumber }]
    })

    const [skipCheck, setSkipCheck] = useState(false)

    const { data: burnResult, error: checkError } =
        HubService.useBurnCheckGreenQuery(
            { requestId: burnData?.requestId || '' },
            { skip: !burnData?.requestId || skipCheck, pollingInterval: 15000 }
        )

    useEffect(() => {
        if (burnResult && burnResult.burnStatus !== BurnStatus.Pending) {
            setSkipCheck(true)
        }
    }, [burnResult])

    const banknoteError = burnResult?.banknotes[0]?.errorMessage ?? ''

    const formik = useFormik<Values>({
        initialValues: { passcode: '', token: null, address: '', email: '' },
        validationSchema,
        validateOnChange,

        onSubmit: (values: Values) => {
            if (!infoData || !selectedNetwork) return
            burnSoftnotes({
                banknotes: [
                    {
                        serialNumber: data.serialNumber,
                        pinCode: values.passcode
                    }
                ],
                walletAddress: values.address,
                email: values.email,
                networkFee: infoData?.networkFee,
                subNetwork: selectedNetwork?.network,
                subProtocol: selectedNetwork?.protocol
            })
            setRefreshReCaptcha(r => !r)
        }
    })

    const {
        data: masterAccountData,
        error: networkError,
        isFetching: isNetworkLoading
    } = HubService.useGetMasterAccountQuery(
        {
            currencyKey: currency?.key ?? ''
        },
        { skip: !currency }
    )

    const availableNetworksData = masterAccountData?.account.supportProtocols

    const availableNetworks = availableNetworksData?.map(protocol => ({
        value: protocol.address,
        text: `${protocol.network} (${protocol.protocol})`
    }))

    useEffect(() => {
        if (availableNetworksData?.length) {
            setSelectedNetwork(availableNetworksData[0])
        }
    }, [availableNetworksData])

    const handleChangeNetwork = (value: OptionModel) => {
        const selectedNetwork = availableNetworksData?.find(
            network => network.address === value.value
        )

        selectedNetwork && setSelectedNetwork(selectedNetwork)
    }

    const selectedNetworkOption = availableNetworks?.find(
        network => network.value === selectedNetwork?.address
    )

    const [updateInterval, setUpdateInterval] = useState(10)

    useEffect(() => {
        if (
            burnData ||
            isFetching ||
            (burnResult && burnResult.burnStatus !== BurnStatus.Failed)
        )
            return

        const updateFee = () => {
            setUpdateInterval(timeLeft => {
                if (timeLeft <= 0) {
                    Promise.resolve().then(() => {
                        refetch()
                    })

                    return 10
                } else {
                    return timeLeft - 1
                }
            })
        }

        let intervalId: NodeJS.Timer | undefined
        if (isInfoFetching) {
            clearInterval(intervalId)
        } else {
            intervalId = setInterval(updateFee, 1000)
        }

        return () => clearInterval(intervalId)
    }, [refetch, data, isInfoFetching, burnData, isFetching, burnResult])

    const handleSubmit = () => {
        setValidateOnChange(true)
        formik.handleSubmit()
    }

    const handleClose = () => {
        formik.resetForm()
        onClose()
    }

    const onChangeCaptcha = useCallback(
        (value: string | null) => {
            formik.setFieldValue('token', value)
        },
        [refreshReCaptcha]
    )

    return burnResult?.burnStatus === BurnStatus.Success ? (
        <BurnGreenSuccess onClose={handleClose} resultData={burnResult} />
    ) : (
        <BurnFormGreenComponent
            formik={formik}
            onSubmitForm={handleSubmit}
            onClose={handleClose}
            data={data}
            isLoading={isFetching}
            error={
                handleErrors(error || infoError || checkError) || banknoteError
            }
            onChangeCaptcha={onChangeCaptcha}
            refreshReCaptcha={refreshReCaptcha}
            currency={currency}
            timeLeft={updateInterval}
            infoData={infoData}
            isFetchingInfo={isInfoFetching}
            isChecking={
                !!(burnResult && burnResult.burnStatus !== BurnStatus.Failed)
            }
            selectedNetworkOption={selectedNetworkOption}
            networks={availableNetworks}
            onChangeNetwork={handleChangeNetwork}
            networkError={handleErrors(networkError)}
            isNetworkLoading={isNetworkLoading}
        />
    )
}

export default BurnFormGreen
