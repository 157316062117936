import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { HubOperations, HubState } from './hubTypes'

const initialState: HubState = {
    operation: null
}

const hubSlice = createSlice({
    name: 'hub',
    initialState,
    reducers: {
        setOperation: (
            state,
            { payload }: PayloadAction<HubOperations | null>
        ) => {
            state.operation = payload
        }
    }
})

export const { actions: hubActions } = hubSlice

export default hubSlice
