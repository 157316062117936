import SectionHeader from 'components/shared/SectionHeader'
import styles from '../styles.module.scss'
import {
    Alert,
    Button,
    TextArea,
    TextField
} from '@crispmindltd/client-react-package'
import classNames from 'classnames'
import { BanknoteResponse } from 'services/Hub/types'
import { AlertData } from './types'
import { TotalsGreen } from 'store/burn/burnTypes'
import { Currency } from 'store/fill/fillTypes'
import { useTranslation } from 'react-i18next'

interface Props {
    copySuccess: () => void
    onClickDone: () => void
    alertData: AlertData
    banknotesWithError: BanknoteResponse[] | undefined
    hashTransaction: string | null
    totals: TotalsGreen
    currency: Currency | undefined
}

const BurnGreenResultComponent: React.FC<Props> = (
    props
): React.ReactElement => {
    const {
        copySuccess,
        onClickDone,
        alertData,
        banknotesWithError,
        hashTransaction,
        totals,
        currency
    } = props

    const { t } = useTranslation(['common', 'burn', 'fill'])

    return (
        <>
            <div className={styles.wrapper}>
                <SectionHeader
                    title={t('labels.burn')}
                    subTitle={t('greenHub', { ns: 'fill' })}
                    subTitleColor='#00E979'
                    withBackButton={false}
                />

                <Alert icon {...alertData} disableAnimation />

                {banknotesWithError && banknotesWithError.length > 0 && (
                    <TextArea
                        readOnly
                        withCopy
                        className={classNames(
                            styles.textArea,
                            styles.textAreaErrors
                        )}
                        label={
                            <div className={styles.textAreaLabel}>
                                <div className={styles.title}>
                                    {t('labels.errorSoftnotes')}
                                </div>
                                <div>{`${banknotesWithError.length} ${t(
                                    'labels.bills'
                                )}`}</div>
                            </div>
                        }
                        value={banknotesWithError
                            .map(el => `${el.serialNumber}`)
                            .join('\n')}
                    />
                )}

                {hashTransaction && (
                    <TextField
                        readOnly
                        withCopy
                        onCopy={copySuccess}
                        label={t('labels.hashTransaction')}
                        value={hashTransaction}
                        name='hash'
                    />
                )}
            </div>

            <div className={styles.sofnotesSummaryWrapper}>
                {hashTransaction && (
                    <div className={styles.sofnotesSummary}>
                        <div className={styles.sofnotesSummaryRow}>
                            <span className={styles.fee}>
                                {t('networkFee', { ns: 'burn' })}:
                            </span>
                            <span className={styles.fee}>
                                {`${totals.networkFee} ${
                                    currency?.ticker ?? ''
                                }`}
                            </span>
                        </div>
                        <div className={styles.sofnotesSummaryRow}>
                            <span className={styles.fee}>
                                {t('tectumFee', { ns: 'burn' })}:
                            </span>
                            <span className={styles.fee}>
                                {`${totals.tectumFee} ${
                                    currency?.ticker ?? ''
                                }`}
                            </span>
                        </div>
                        <div className={styles.sofnotesSummaryRow}>
                            <span className={styles.sofnotesSummaryLabel}>
                                {t('totalFee', { ns: 'burn' })}:
                            </span>
                            <span className={styles.sofnotesSummaryValue}>
                                {`${totals.totalFee} ${currency?.ticker ?? ''}`}
                            </span>
                        </div>

                        <div className={styles.sofnotesSummaryRow}>
                            <span className={styles.sofnotesSummaryLabel}>
                                {t('totalAmount', { ns: 'burn' })}:
                            </span>
                            <span className={styles.sofnotesSummaryValue}>
                                {`${totals.totalAmount} ${
                                    currency?.ticker ?? ''
                                }`}
                            </span>
                        </div>
                    </div>
                )}

                <Button onClick={onClickDone} className={styles.btn}>
                    {t('buttons.done')}
                </Button>
            </div>
        </>
    )
}

export default BurnGreenResultComponent
