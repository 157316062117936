import React, { useEffect, useRef, useState } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames'
import i18next from 'i18next'
import {
    LanguageOption,
    supportedLanguages
} from 'utils/constants/supportedLanguages'
import IconEarth from 'assets/icons/earth.svg'

const LanguageSelection: React.FC = (): React.ReactElement => {
    const [showLanguageList, setShowLanguageList] = useState(false)
    const languageRef = useRef(null)

    const currentLanguage = i18next.language

    const changeLanguage = (
        event: React.MouseEvent,
        language: LanguageOption['value']
    ) => {
        event.stopPropagation()
        i18next.changeLanguage(language)
        setShowLanguageList(false)
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                languageRef.current &&
                !(languageRef.current as HTMLElement).contains(
                    event.target as HTMLElement
                )
            ) {
                setShowLanguageList(false)
            }
        }

        document.addEventListener('click', handleClickOutside)

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [])

    return (
        <button
            ref={languageRef}
            className={styles.button}
            onClick={() => setShowLanguageList(prev => !prev)}
        >
            <img className={styles.icon} src={IconEarth} alt='language' />
            <div
                className={
                    showLanguageList
                        ? styles.languageList
                        : classNames(styles.languageList, styles.off)
                }
                onClick={e => {
                    e.stopPropagation()
                }}
            >
                {supportedLanguages &&
                    supportedLanguages.map(({ value, text }) => (
                        <span
                            key={value}
                            className={
                                currentLanguage === value
                                    ? styles.active
                                    : styles.language
                            }
                            onClick={e => changeLanguage(e, value)}
                        >
                            {text}
                        </span>
                    ))}
            </div>
        </button>
    )
}

export default LanguageSelection
