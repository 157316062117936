import SectionHeader from 'components/shared/SectionHeader'
import styles from '../styles.module.scss'
import { Alert, Button, TextArea } from '@crispmindltd/client-react-package'
import classNames from 'classnames'
import { AlertData } from './types'
import { BanknoteResponse } from 'services/Hub/types'
import { useTranslation } from 'react-i18next'

interface Props {
    onClickBack: () => void
    onClickDone: () => void
    alertData: AlertData
    banknotesWithError: BanknoteResponse[] | undefined
    banknotesWithSuccess: BanknoteResponse[] | undefined
}

const AcceptResultComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        onClickBack,
        onClickDone,
        alertData,
        banknotesWithError,
        banknotesWithSuccess
    } = props

    const { t } = useTranslation(['common', 'accept'])

    return (
        <>
            <div className={styles.wrapper}>
                <SectionHeader
                    onClickBack={onClickBack}
                    title={t('labels.accept')}
                />

                <Alert icon {...alertData} disableAnimation />

                {banknotesWithSuccess && banknotesWithSuccess.length > 0 && (
                    <>
                        <TextArea
                            readOnly
                            withCopy
                            className={styles.textArea}
                            label={
                                <div className={styles.textAreaLabel}>
                                    <div className={styles.title}>
                                        {t('acceptSoftnotes', { ns: 'accept' })}
                                    </div>
                                    <div>{`${banknotesWithSuccess.length} ${t(
                                        'labels.bills'
                                    )}`}</div>
                                </div>
                            }
                            value={banknotesWithSuccess
                                .map(el => `${el.serialNumber}/${el.pinCode}`)
                                .join('\n')}
                        />

                        <Alert
                            type='warning'
                            text={t('warning', { ns: 'accept' })}
                            description={t('cannotBeRecovered', {
                                ns: 'accept'
                            })}
                            icon
                            disableAnimation
                        />
                    </>
                )}

                {banknotesWithError && banknotesWithError.length > 0 && (
                    <TextArea
                        readOnly
                        withCopy
                        className={classNames(
                            styles.textArea,
                            styles.textAreaErrors
                        )}
                        label={
                            <div className={styles.textAreaLabel}>
                                <div className={styles.title}>
                                    {t('labels.errorSoftnotes')}
                                </div>
                                <div>{`${banknotesWithError.length} ${t(
                                    'labels.bills'
                                )}`}</div>
                            </div>
                        }
                        value={banknotesWithError
                            .map(el => `${el.serialNumber}`)
                            .join('\n')}
                    />
                )}
            </div>

            <div className={styles.bottom}>
                <Button onClick={onClickDone} className={styles.btn}>
                    {t('buttons.done')}
                </Button>
            </div>
        </>
    )
}

export default AcceptResultComponent
