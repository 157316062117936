const IconLink: React.FC = (): React.ReactElement => {
    return (
        <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M15.9081 7.54902V4.28107H12.6401'
                stroke='currentColor'
                strokeWidth='1.22548'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M11.0062 9.183L15.9082 4.28107'
                stroke='currentColor'
                strokeWidth='1.22548'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M16.7251 10.817V12.451C16.7251 14.707 14.8963 16.5359 12.6402 16.5359H7.73827C5.48221 16.5359 3.65332 14.707 3.65332 12.451V7.54903C3.65332 5.29297 5.48221 3.46408 7.73827 3.46408H9.37224'
                stroke='currentColor'
                strokeWidth='1.22548'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
export default IconLink
