import {
    AnyAction,
    combineReducers,
    configureStore,
    ThunkAction,
    ThunkDispatch
} from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { cashApi } from '../services'
import importSlice from './import/importSlice'
import depositSlice from './deposit/depositSlice'
import handoverSlice from './handover/handoverSlice'
import fillSlice from './fill/fillSlice'
import acceptSlice from './accept/acceptSlice'
import burnSlice from './burn/burnSlice'
import fillModalSlice from './fillModal/fillModalSlice'
import sendModalSlice from './sendModal/sendModaltSlice'
import hubSlice from './hub/hubSlice'

const rootReducer = combineReducers({
    [cashApi.reducerPath]: cashApi.reducer,
    import: importSlice.reducer,
    deposit: depositSlice.reducer,
    handover: handoverSlice.reducer,
    fill: fillSlice.reducer,
    accept: acceptSlice.reducer,
    burn: burnSlice.reducer,
    fillModal: fillModalSlice.reducer,
    sendModal: sendModalSlice.reducer,
    hub: hubSlice.reducer
})

export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat([cashApi.middleware])
})

export type AppState = ReturnType<typeof store.getState>
export type AppThunk<T = void> = ThunkAction<T, AppState, unknown, AnyAction>
export type AppDispatch = ThunkDispatch<AppState, unknown, AnyAction>

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector
