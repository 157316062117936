export function getCurrencyLink(currencyID: number, token: string) {
    switch (currencyID) {
        case 1:
            return `https://blockchair.com/bitcoin/address/${token}`
        case 2:
        case 6:
            return `https://etherscan.io/address/${token}`
        case 3:
        case 4:
            return `https://tronscan.org/#/address/${token}`
        case 7:
            return `https://blockchair.com/litecoin/address/${token}`
        default:
            return '#'
    }
}
