import { useAppDispatch, useAppSelector } from 'store/store'
import EnterVaultStepComponent from './component'
import { fillActions } from 'store/fill/fillSlice'
import { FillSteps } from 'store/fill/fillTypes'
import { FormikProps, useFormik } from 'formik'
import { Values } from './types'
import validationSchema from './validationSchema'
import { useCallback, useEffect, useState } from 'react'
import { HubService } from 'services/Hub'
import handleErrors from 'utils/functions/handleErrors'

const EnterVaultStep: React.FC = () => {
    const [validateOnChange, setValidateOnChange] = useState(false)
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)

    const dispatch = useAppDispatch()

    const selectedCurrency = useAppSelector(
        state => state.fill.selectedBlueCurrency
    )

    const selectedNominal = useAppSelector(
        state => state.fill.selectedBlueNominal
    )

    const tectumId = useAppSelector(state => state.fill.tectumId)
    const vaultId = useAppSelector(state => state.fill.vaultId)
    const email = useAppSelector(state => state.fill.email)

    const copyFieldValuesToStore = () => {
        dispatch(fillActions.setTectumId(formik.values.tectumId))
        dispatch(fillActions.setVaultId(formik.values.vaultId))
        dispatch(fillActions.setEmail(formik.values.email))
    }

    const formik: FormikProps<Values> = useFormik<Values>({
        initialValues: { tectumId, vaultId, email, token: null },
        validationSchema,
        validateOnChange,

        onSubmit: async (values: Values) => {
            copyFieldValuesToStore()

            if (!selectedCurrency || !selectedNominal) {
                return
            }

            startFill({
                currency: selectedCurrency.key,
                email: values.email,
                nominal: selectedNominal,
                vaultId: null,
                tectumId:
                    formik.values.tectumId === ''
                        ? null
                        : Number(formik.values.tectumId),
                token: values.token
            })
        }
    })

    const handleSubmit = () => {
        setValidateOnChange(true)
        formik.handleSubmit()
        setRefreshReCaptcha(r => !r)
    }

    const vaultTab = useAppSelector(state => state.fill.vaultTab)

    const handleChangeVaultTab = (tab: string) => {
        dispatch(fillActions.setVaultTab(tab))
    }

    const handleClickBack = () => {
        copyFieldValuesToStore()
        dispatch(fillActions.setStep(FillSteps.selectNominal))
    }

    const [startFill, { data, error, isFetching }] =
        HubService.useLazyFillSoftnotesBlueStartQuery()

    useEffect(() => {
        if (data) {
            const { address, serialNumber, id } = data
            dispatch(fillActions.setSerialNumber(serialNumber))
            dispatch(fillActions.setFillId(id))
            dispatch(fillActions.setAddress(address))
            dispatch(fillActions.setStep(FillSteps.enterAddress))
        }
    }, [data, dispatch])

    const onChangeCaptcha = useCallback(
        (value: string | null) => {
            formik.setFieldValue('token', value)
        },
        [refreshReCaptcha]
    )

    return (
        <EnterVaultStepComponent
            selectedCurrency={selectedCurrency}
            onClickBack={handleClickBack}
            selectedNominal={selectedNominal}
            formik={formik}
            onSubmitForm={handleSubmit}
            vaultTab={vaultTab}
            onChangeVaultTab={handleChangeVaultTab}
            error={handleErrors(error)}
            isFetching={isFetching}
            onChangeCaptcha={onChangeCaptcha}
            refreshReCaptcha={refreshReCaptcha}
        />
    )
}

export default EnterVaultStep
