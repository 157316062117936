import { useAppSelector } from 'store/store'
import AmountTab from './AmountTab'
import PoolTab from './PoolTab'

const SelectNominalStep: React.FC = () => {
    const nominalTab = useAppSelector(state => state.fill.nominalTab)

    return (
        <>
            {nominalTab === 'amount' && <AmountTab />}
            {nominalTab === 'pool' && <PoolTab />}
        </>
    )
}

export default SelectNominalStep
