import { useCurrencies } from 'hooks/useCurrencies'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { AppRoutes } from 'routes/AppRoutes'

const OldCashProvider: React.FC = (): React.ReactElement => {
    const { pathname } = useLocation()

    const { currencies } = useCurrencies()

    const pathNameArr = pathname.split('/').slice(2)

    const [pathNetwork, pathProtocol, pathTicker, pathSerial] = pathNameArr

    const matchedCurrency = currencies?.find(
        currency =>
            currency.network.toLowerCase() === pathNetwork &&
            currency.protocol.toLowerCase() === pathProtocol &&
            currency.ticker.toLowerCase() === pathTicker
    )

    if (matchedCurrency) {
        const currencyNumber = String(matchedCurrency.id).padStart(5, '0')
        const serialNumber = `${currencyNumber}${pathSerial.padStart(11, '0')}`
        const newPath = `${AppRoutes.bill}?serialNumber=${serialNumber}`

        return <Navigate to={newPath} replace />
    }

    return <Outlet />
}
export default OldCashProvider
