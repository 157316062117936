import { BanknoteResponse } from 'services/Hub/types'

export interface HandoverState {
    step: HandoverSteps
    banknotes: BanknoteResponse[] | null
}
export enum HandoverSteps {
    request = 'request',
    result = 'result'
}
