import SectionHeader from 'components/shared/SectionHeader'
import { Currency } from 'store/fill/fillTypes'
import styles from '../styles.module.scss'
import {
    Alert,
    Button,
    CopyButton,
    TextField
} from '@crispmindltd/client-react-package'
import { useQRCode } from 'next-qrcode'
import { FormikProps } from 'formik'
import { Values } from './types'
import { getCurrencyLabel } from 'utils/functions/currency'
import { useTranslation } from 'react-i18next'
import { exponentialToDecimal } from 'utils/functions/renderBillsFunctions'

interface Props {
    selectedCurrency: Currency | null
    onClickBack: () => void
    selectedNominal: number | null
    onSubmitForm: () => void
    isMobile: boolean
    address: string
    formik: FormikProps<Values>
    error: string
    isFetching: boolean
}

const EnterAddressStepComponent: React.FC<Props> = props => {
    const {
        selectedCurrency,
        onClickBack,
        selectedNominal,
        onSubmitForm,
        isMobile,
        address,
        formik,
        error,
        isFetching
    } = props

    const { Canvas } = useQRCode()

    const { t } = useTranslation(['common', 'fill'])

    return (
        <>
            <SectionHeader
                title={`${t('labels.fill')} ${getCurrencyLabel(
                    selectedCurrency
                )}`}
                subTitle={t('blueHub', { ns: 'fill' })}
                subTitleColor='#336BFF'
                onClickBack={onClickBack}
            />

            <div className={styles.qrWrapper}>
                <Canvas
                    text={address}
                    options={{
                        margin: 2,
                        width: isMobile ? 285 : 160,
                        color: {
                            dark: '#000000',
                            light: '#ffffff'
                        }
                    }}
                />
            </div>

            <div className={styles.amountLabel}>{t('labels.amount')}</div>
            <div className={styles.amountValue}>
                {selectedNominal} {selectedCurrency?.ticker}{' '}
                <CopyButton
                    className={styles.copyAmount}
                    value={exponentialToDecimal(selectedNominal ?? 0)}
                />
            </div>

            <div className={styles.fieldsWrapper}>
                <TextField
                    name='address'
                    label={t('labels.address')}
                    value={address}
                    readOnly
                    withCopy
                />

                <TextField
                    name='senderAddress'
                    label={t('labels.senderAddress')}
                    value={formik.values.senderAddress}
                    onChange={formik.handleChange}
                    handleClear={() =>
                        formik.setFieldValue('senderAddress', '')
                    }
                    error={formik.errors.senderAddress}
                />

                <TextField
                    name='hashTransaction'
                    label={t('labels.hashTransaction')}
                    value={formik.values.hashTransaction}
                    onChange={formik.handleChange}
                    handleClear={() =>
                        formik.setFieldValue('hashTransaction', '')
                    }
                    error={formik.errors.hashTransaction}
                />
            </div>

            {error && (
                <Alert
                    type='error'
                    text={error}
                    icon
                    className={styles.alert}
                />
            )}

            <div className={styles.bottom}>
                <Button
                    className={styles.nextButton}
                    onClick={onSubmitForm}
                    disabled={
                        !formik.values.senderAddress ||
                        !formik.values.hashTransaction
                    }
                    isLoading={isFetching}
                >
                    {t('buttons.finish')}
                </Button>
            </div>
        </>
    )
}

export default EnterAddressStepComponent
