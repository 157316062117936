export interface HubState {
    operation: HubOperations | null
}

export enum HubOperations {
    fillBlue = 'fillBlue',
    fillGreen = 'fillGreen',
    import = 'import',
    handover = 'handover',
    accept = 'accept',
    burnBlue = 'burnBlue',
    burnGreen = 'burnGreen',
    deposit = 'deposit'
}
