import { useAppDispatch, useAppSelector } from 'store/store'
import ResultStepComponent from './component'
import { fillActions } from 'store/fill/fillSlice'
import { useEffect, useState } from 'react'
import { HubService } from 'services/Hub'
import { useNavigate } from 'react-router-dom'
import { hubActions } from 'store/hub/hubSlice'
import { AppRoutes } from 'routes/AppRoutes'
import { FillStatus } from 'services/Hub/types'

const ResultStep: React.FC = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const selectedCurrency = useAppSelector(
        state => state.fill.selectedGreenCurrency
    )

    const fillAmount = useAppSelector(state => state.fill.fillAmount)

    const selectedNetwork = useAppSelector(state => state.fill.selectedNetwork)
    const senderAddress = useAppSelector(state => state.fill.senderAddress)
    const hashTransaction = useAppSelector(state => state.fill.hashTransaction)
    const fillId = useAppSelector(state => state.fill.fillId)

    const handleClickDone = () => {
        dispatch(fillActions.reset())
        dispatch(hubActions.setOperation(null))
        navigate(AppRoutes.hub)
    }

    const [status, setStatus] = useState<FillStatus>(FillStatus.Pending)

    const [checkFill] = HubService.useLazyCheckFillSoftnotesGreenQuery()

    useEffect(() => {
        const timerId = setInterval(async () => {
            try {
                const response = await checkFill({ id: fillId }).unwrap()

                const responseStatus = response.status as FillStatus

                if (responseStatus === FillStatus.Pending) {
                    return
                }

                setStatus(responseStatus)
                clearInterval(timerId)
            } catch (error) {
                setStatus(FillStatus.Failed)
                clearInterval(timerId)
            }
        }, 5000)

        return () => clearInterval(timerId)
    }, [checkFill, fillId])

    return (
        <ResultStepComponent
            selectedCurrency={selectedCurrency}
            fillAmount={fillAmount}
            onClickDone={handleClickDone}
            address={selectedNetwork?.address ?? ''}
            senderAddress={senderAddress}
            hashTransaction={hashTransaction}
            status={status}
        />
    )
}

export default ResultStep
