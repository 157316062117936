import { SoftnoteColor } from 'models/Softnote'
import {
    blueTemplates,
    greenTemplates,
    yellowTemplates
} from 'utils/constants/renderBillsInfo'

export const formatNumber = (number: any, currencyId: number) => {
    if (currencyId === 1) {
        return number
    }
    const suffixes = ['', 'K', 'M', 'B', 'T']
    const threshold = 1000

    if (number < 10000) {
        return number.toFixed(10).replace(/\.?0+$/, '')
    }

    let magnitude = 0
    while (Math.abs(number) >= threshold && magnitude < suffixes.length - 1) {
        number /= threshold
        magnitude++
    }

    return number + suffixes[magnitude]
}

export const numberToText = (number: number, ticker: string): string => {
    number = +number.toFixed()

    const units = [
        '',
        'one',
        'two',
        'three',
        'four',
        'five',
        'six',
        'seven',
        'eight',
        'nine'
    ]
    const teens = [
        'ten',
        'eleven',
        'twelve',
        'thirteen',
        'fourteen',
        'fifteen',
        'sixteen',
        'seventeen',
        'eighteen',
        'nineteen'
    ]
    const tens = [
        '',
        '',
        'twenty',
        'thirty',
        'forty',
        'fifty',
        'sixty',
        'seventy',
        'eighty',
        'ninety'
    ]

    const scales = [
        '',
        'thousand',
        'million',
        'billion',
        'trillion',
        'quadrillion',
        'quintillion',
        'sextillion',
        'septillion',
        'octillion',
        'nonillion',
        'decillion'
    ]

    if (number === 0) {
        return 'ZERO'
    }

    if (number < 0) {
        return 'MINUS ' + numberToText(Math.abs(number), ticker)
    }

    let text = ''
    let scaleIndex = 0

    while (number > 0) {
        let threeDigits = number % 1000
        number = Math.floor(number / 1000)

        if (threeDigits !== 0) {
            let threeDigitsText = ''

            if (threeDigits >= 100) {
                threeDigitsText +=
                    units[Math.floor(threeDigits / 100)] + ' hundred '
                threeDigits %= 100
            }

            if (threeDigits >= 10 && threeDigits <= 19) {
                threeDigitsText += teens[threeDigits - 10]
                threeDigits = 0
            } else if (threeDigits >= 20) {
                threeDigitsText += tens[Math.floor(threeDigits / 10)] + ' '
                threeDigits %= 10
            }

            if (threeDigits !== 0) {
                threeDigitsText += units[threeDigits] + ' '
            }

            text =
                threeDigitsText.trim() + ' ' + scales[scaleIndex] + ' ' + text
        }

        scaleIndex++
    }

    if (ticker === 'BTC') {
        const result = `${text.trim()} satoshi`
            .toUpperCase()
            .split('')
            .join(' ')
        return result
    } else {
        const result = text.trim().toUpperCase().split('').join(' ')
        return result
    }
}

export const numberWithSpaces = (number: number | string) => {
    if (+number < 10000) {
        return Number(number)
            .toFixed(10)
            .replace(/\.?0+$/, '')
    }
    const parts = number.toString().split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    return parts.join('.')
}

export const getTemplate = (
    ticker: string,
    color: SoftnoteColor,
    status: string | number
) => {
    if (status === 'Coinaged') {
        return yellowTemplates[color][ticker]
    }

    if (color === SoftnoteColor.Blue) {
        return blueTemplates[ticker]
    }

    if (color === SoftnoteColor.Green) {
        return greenTemplates[ticker]
    }

    return blueTemplates[0]
}

export const exponentialToDecimal = (nominal: number) => {
    return nominal.toFixed(10).replace(/\.?0+$/, '')
}
