import { FormikProps, useFormik } from 'formik'
import validationSchema from './validationSchema'
import { Values } from './types'
import { useCallback, useEffect, useRef, useState } from 'react'
import { BillService } from 'services/Bill'
import handleErrors from 'utils/functions/handleErrors'
import { createDownloadUrlFromBase64 } from 'utils/functions/createDownloadUrlFromBase64 '
import styles from '../styles.module.scss'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from 'store/store'
import BurnFormBlueComponent from './components'

interface Props {
    data: any
    onClose: () => void
}

const BurnFormBlue: React.FC<Props> = (props: Props) => {
    const { data, onClose } = props

    const [validateOnChange, setValidateOnChange] = useState<boolean>(false)
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)
    const [checkbox, setCheckbox] = useState(false)

    const dispatch = useAppDispatch()

    const { t } = useTranslation('burn')

    const [burnSoftnote, { data: burnData, isSuccess, error, isFetching }] =
        BillService.useLazyBurnBlueQuery()

    const formik: FormikProps<Values> = useFormik<Values>({
        initialValues: { passcode: '', token: null },
        validationSchema,
        validateOnChange,

        onSubmit: async (values: Values) => {
            burnSoftnote({
                pinCode: values.passcode,
                serialNumber: data.serialNumber,
                token: values.token
            })
        }
    })

    useEffect(() => {
        if (isSuccess) {
            dispatch(BillService.util.invalidateTags(['Bill']))
        }
    }, [isSuccess])

    const linkRef = useRef<HTMLAnchorElement | null>(null)

    const downloadLink = burnData && (
        <a
            ref={linkRef}
            href={createDownloadUrlFromBase64(burnData.file.content)}
            className={styles.link}
            download={`${t('burn')}-${Date.now()}.zip`}
        >
            {t('clickHere')}
        </a>
    )

    useEffect(() => {
        if (burnData && linkRef.current) {
            linkRef.current.click()
        }
    }, [burnData])

    const successAlertText = (
        <>
            {t('success')}
            <br />
            <br />
            {t('zipWillBeDownloaded')}
            <br />
            <br />
            {t('ifNotStarted')} {downloadLink}
            <br />
            <br />
            {t('afterBurn')}
        </>
    )

    const handleSubmit = () => {
        setValidateOnChange(true)
        formik.handleSubmit()
        setRefreshReCaptcha(r => !r)
    }

    const handleClose = () => {
        formik.resetForm()
        onClose()
    }

    const toggleCheckbox = () => {
        setCheckbox(prev => !prev)
    }

    const onChangeCaptcha = useCallback(
        (value: string | null) => {
            formik.setFieldValue('token', value)
        },
        [refreshReCaptcha]
    )

    return (
        <BurnFormBlueComponent
            formik={formik}
            onSubmitForm={handleSubmit}
            onClose={handleClose}
            isSuccess={isSuccess}
            data={data}
            isLoading={isFetching}
            error={handleErrors(error)}
            successAlertText={successAlertText}
            checkbox={checkbox}
            downloadLink={downloadLink}
            toggleCheckbox={toggleCheckbox}
            onChangeCaptcha={onChangeCaptcha}
            refreshReCaptcha={refreshReCaptcha}
        />
    )
}

export default BurnFormBlue
