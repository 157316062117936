import './App.css'
import { Route, Routes } from 'react-router-dom'
import Layout from './components/Layout'
import { AppRoutes } from './routes/AppRoutes'
import EventPage from 'pages/Event'
import BillPage from 'pages/Bill'
import HubPage from 'pages/Hub'
import ImportPage from 'pages/Import'
import DepositPage from 'pages/Deposit'
import HandoverPage from 'pages/Handover'
import AcceptPage from 'pages/Accept'
import Page404 from 'pages/Page404'
import { AlertNotification } from '@crispmindltd/client-react-package'
import FillBluePage from 'pages/FillBlue'
import FillGreenPage from 'pages/FillGreen'
import BurnPageBlue from 'pages/BurnBlue'
import BurnPageGreen from 'pages/BurnGreen'
import OldCashProvider from 'routes/OldCashProvider'

function App() {
    const routes = [
        { path: AppRoutes.hub, element: <HubPage /> },
        { path: AppRoutes.bill, element: <BillPage /> },
        // { path: AppRoutes.event, element: <EventPage /> },
        { path: AppRoutes.fillBlue, element: <FillBluePage /> },
        { path: AppRoutes.fillGreen, element: <FillGreenPage /> },
        { path: AppRoutes.import, element: <ImportPage /> },
        // { path: AppRoutes.deposit, element: <DepositPage /> },
        { path: AppRoutes.handover, element: <HandoverPage /> },
        { path: AppRoutes.accept, element: <AcceptPage /> },
        { path: AppRoutes.burnBlue, element: <BurnPageBlue /> },
        { path: AppRoutes.burnGreen, element: <BurnPageGreen /> }
    ]

    return (
        <div className='App'>
            <Routes>
                <Route element={<Layout />}>
                    <Route element={<OldCashProvider />}>
                        {routes.map((item, index) => (
                            <Route
                                path={item.path}
                                element={item.element}
                                key={index}
                            />
                        ))}
                        <Route path='*' element={<Page404 />} />
                    </Route>
                </Route>
            </Routes>
            <AlertNotification />
        </div>
    )
}

export default App
