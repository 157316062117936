import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { FillModalState, FillModalSteps } from './fillModalTypes'
import { Currency } from 'services/Hub/types'
import { Network } from 'store/fill/fillTypes'

const initialState: FillModalState = {
    step: FillModalSteps.enterVault,
    vaultTab: 'email',
    tectumId: '',
    vaultId: '',
    email: '',
    address: '',
    senderAddress: '',
    hashTransaction: '',
    fillId: '',
    selectedNetwork: null
}

const fillModalSlice = createSlice({
    name: 'fillModal',
    initialState,
    reducers: {
        setStep: (state, { payload }: PayloadAction<FillModalSteps>) => {
            state.step = payload
        },

        setVaultTab: (state, { payload }: PayloadAction<string>) => {
            state.vaultTab = payload
        },
        setTectumId: (state, { payload }: PayloadAction<string>) => {
            state.tectumId = payload
        },
        setVaultId: (state, { payload }: PayloadAction<string>) => {
            state.vaultId = payload
        },
        setEmail: (state, { payload }: PayloadAction<string>) => {
            state.email = payload
        },
        setAddress: (state, { payload }: PayloadAction<string>) => {
            state.address = payload
        },
        setSenderAddress: (state, { payload }: PayloadAction<string>) => {
            state.senderAddress = payload
        },
        setHashTransaction: (state, { payload }: PayloadAction<string>) => {
            state.hashTransaction = payload
        },
        setFillId: (state, { payload }: PayloadAction<string>) => {
            state.fillId = payload
        },
        setSelectedNetwork: (
            state,
            { payload }: PayloadAction<Network | null>
        ) => {
            state.selectedNetwork = payload
        },
        reset: () => initialState
    }
})

export const { actions: fillModalActions } = fillModalSlice

export default fillModalSlice
