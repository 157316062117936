import {
    Alert,
    Button,
    ModalBody,
    ModalFooter,
    Select,
    Skeleton,
    TextField,
    Tooltip
} from '@crispmindltd/client-react-package'
import { FormikProps } from 'formik'
import { Values } from './types'
import styles from '../styles.module.scss'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useTranslation } from 'react-i18next'
import { formatNumber } from 'utils/functions/format'
import { Currency } from 'store/fill/fillTypes'
import { BurnPrepareGreenResponse } from 'services/Hub/types'
import { OptionModel } from '@crispmindltd/client-react-package/dist/components/Select/types'
import { FetchBillResponse } from 'services/Bill/types'
import classNames from 'classnames'

interface Props {
    formik: FormikProps<Values>
    onSubmitForm: () => void
    onClose: () => void
    data: FetchBillResponse
    isLoading: boolean
    error: string
    onChangeCaptcha: (value: string | null) => void
    refreshReCaptcha: boolean
    currency: Currency | undefined
    timeLeft: number
    infoData: BurnPrepareGreenResponse | undefined
    isFetchingInfo: boolean
    isChecking: boolean
    networks: OptionModel[] | undefined
    selectedNetworkOption: OptionModel | undefined
    onChangeNetwork: (value: OptionModel) => void
    networkError: string
    isNetworkLoading: boolean
}

const BurnFormGreenComponent: React.FC<Props> = (props: Props) => {
    const {
        formik,
        onSubmitForm,
        onClose,
        data,
        isLoading,
        error,
        onChangeCaptcha,
        refreshReCaptcha,
        currency,
        timeLeft,
        infoData,
        isFetchingInfo,
        isChecking,
        networks,
        selectedNetworkOption,
        onChangeNetwork,
        networkError,
        isNetworkLoading
    } = props

    const { t } = useTranslation(['common', 'burn'])

    return (
        <>
            <ModalBody className={styles.content}>
                {error && (
                    <Alert
                        style={{ marginBottom: '24px' }}
                        type='error'
                        text={error}
                        icon
                    />
                )}

                {infoData?.totalAmount !== undefined &&
                    infoData.totalAmount < 0 &&
                    !isChecking && (
                        <Alert
                            style={{ marginBottom: '24px', fontSize: '16px' }}
                            icon
                            type='error'
                            text='It is impossible to burn the bill. The amount of the fee exceeds the denomination of the bill.'
                            disableAnimation
                        />
                    )}

                {!isChecking && (
                    <Alert
                        type='warning'
                        text='Attention'
                        description={
                            <>
                                <span>
                                    After burning the bill(s) in Green mode,
                                    your crypto assets will be transferred to
                                    the address of the wallet you provided. The
                                    fee for burning is equal to 0.1% of the
                                    denomination of the bill(s) plus the
                                    network's transfer fees.
                                </span>
                                <br />
                                <br />
                                <span>
                                    The bill(s) will get a new status and can be
                                    filled with liquidity again.
                                </span>
                            </>
                        }
                        icon
                        className={styles.alert}
                        disableAnimation
                    />
                )}

                {isChecking && (
                    <Alert
                        type='warning'
                        text={`${t('statuses.pending')}. ${t(
                            'linkHasBeenSent',
                            {
                                ns: 'burn'
                            }
                        )}`}
                        icon
                        disableAnimation
                        style={{ marginBottom: '24px' }}
                    />
                )}

                <div className={styles.amountLabel}>{t('labels.nominal')}</div>
                <div className={styles.amountValue}>
                    {data.nominal} {data.currencyInfo.ticker}
                </div>

                <div className={styles.fieldsWrapper}>
                    {!isChecking && (
                        <TextField
                            name='passcode'
                            value={formik.values.passcode}
                            onChange={formik.handleChange}
                            className={styles.passcodeField}
                            handleClear={() =>
                                formik.setFieldValue('passcode', '')
                            }
                            label={
                                <div className={styles.inputLabel}>
                                    {t('labels.passcode')}
                                </div>
                            }
                            error={formik.errors.passcode}
                        />
                    )}

                    <Select
                        options={networks}
                        selected={selectedNetworkOption?.text}
                        onChange={onChangeNetwork}
                        className={styles.select}
                        label={
                            <div className={styles.selectLabel}>
                                {t('labels.network')}{' '}
                                <Tooltip
                                    value={t('labels.burnNetwork')}
                                    pointerPosition='left-top'
                                />
                            </div>
                        }
                        readOnly={
                            (networks && networks.length <= 1) || isChecking
                        }
                        error={networkError}
                        isLoading={isNetworkLoading}
                    />

                    <TextField
                        name='address'
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        handleClear={() => formik.setFieldValue('address', '')}
                        label={t('labels.address')}
                        error={formik.errors.address}
                        readOnly={isChecking}
                    />

                    <TextField
                        name='email'
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        handleClear={() => formik.setFieldValue('email', '')}
                        label={t('labels.email')}
                        error={formik.errors.email}
                        readOnly={isChecking}
                    />
                </div>
            </ModalBody>

            <ModalFooter className={styles.footer}>
                <div className={styles.sofnotesSummary}>
                    <div className={styles.sofnotesSummaryRow}>
                        <span className={styles.fee}>
                            {t('networkFee', { ns: 'burn' })}:
                        </span>
                        {isFetchingInfo || !infoData ? (
                            <Skeleton type='row' className={styles.skeleton} />
                        ) : (
                            <span className={styles.fee}>
                                {`${formatNumber(
                                    infoData.networkFee,
                                    3
                                )} ${currency?.ticker}`}
                            </span>
                        )}
                    </div>
                    <div className={styles.sofnotesSummaryRow}>
                        <span className={styles.fee}>
                            {t('tectumFee', { ns: 'burn' })}:
                        </span>
                        {isFetchingInfo || !infoData ? (
                            <Skeleton type='row' className={styles.skeleton} />
                        ) : (
                            <span className={styles.fee}>
                                {`${formatNumber(
                                    infoData.tectumFee,
                                    3
                                )} ${currency?.ticker}`}
                            </span>
                        )}
                    </div>
                    <div className={styles.sofnotesSummaryRow}>
                        <span className={styles.sofnotesSummaryLabel}>
                            {t('totalFee', { ns: 'burn' })}:
                        </span>
                        {isFetchingInfo || !infoData ? (
                            <Skeleton type='row' className={styles.skeleton} />
                        ) : (
                            <span className={styles.sofnotesSummaryValue}>
                                {`${formatNumber(
                                    infoData.totalFee,
                                    3
                                )} ${currency?.ticker}`}
                            </span>
                        )}
                    </div>

                    <div className={styles.sofnotesSummaryRow}>
                        <span className={styles.sofnotesSummaryLabel}>
                            {t('totalAmount', { ns: 'burn' })}:
                        </span>
                        {isFetchingInfo || !infoData ? (
                            <Skeleton type='row' className={styles.skeleton} />
                        ) : (
                            <span
                                className={classNames(
                                    styles.sofnotesSummaryValue,
                                    {
                                        [styles.errorAmount]:
                                            infoData?.totalAmount !==
                                                undefined &&
                                            infoData.totalAmount < 0
                                    }
                                )}
                            >
                                {`${formatNumber(
                                    infoData.totalAmount,
                                    3
                                )} ${currency?.ticker}`}
                            </span>
                        )}
                    </div>

                    {!isChecking && (
                        <span className={styles.fee}>
                            {isFetchingInfo
                                ? t('updatingFee', { ns: 'burn' })
                                : t('updateFee', {
                                      updateInterval: timeLeft,
                                      ns: 'burn'
                                  })}
                        </span>
                    )}
                </div>

                {isChecking ? (
                    <Button className={styles.nextButton} onClick={onClose}>
                        {t('buttons.close')}
                    </Button>
                ) : (
                    <Button
                        className={styles.nextButton}
                        disabled={
                            !formik.values.passcode ||
                            !formik.values.address ||
                            !formik.values.email ||
                            (infoData?.totalAmount !== undefined &&
                                infoData.totalAmount < 0) ||
                            isFetchingInfo
                        }
                        onClick={onSubmitForm}
                        isLoading={isLoading}
                    >
                        {t('buttons.finish')}
                    </Button>
                )}

                <GoogleReCaptcha
                    refreshReCaptcha={refreshReCaptcha}
                    onVerify={onChangeCaptcha}
                />
            </ModalFooter>
        </>
    )
}

export default BurnFormGreenComponent
