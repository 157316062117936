import React from 'react'
import styles from './component.module.scss'
import MultiInput from 'components/shared/MultiInput'
import { Button } from '@crispmindltd/client-react-package'
import IconAccept from 'assets/icons/accept.svg'
import IconSend from 'assets/icons/handover.svg'
import IconBurn from 'assets/icons/burn.svg'
import IconFill from 'assets/icons/fill.svg'
import IconEvents from 'assets/icons/events.svg'
import IconProof from 'assets/icons/proof.svg'
import FillModal from './FillModal'
import SendModal from './SendModal'
import AcceptModal from './AcceptModal'
import CanvasBill from './CanvasBill'
import OperationButton from 'components/shared/OperationButton'
import { getCurrencyLink } from 'utils/functions/currencyLink'
import BurnModal from './BurnModal'
import { useTranslation } from 'react-i18next'
import { FetchBillResponse } from 'services/Bill/types'

interface Props {
    number: string
    onChangeNumber: (value: string) => void
    onSubmit: () => void
    isLoading: boolean
    error: string
    data: FetchBillResponse | undefined
    isAcceptModalOpen: boolean
    isSendModalOpen: boolean
    isBurnModalOpen: boolean
    isFillModalOpen: boolean
    closeModal: (operation: string) => void
    openModal: (operation: string) => void
    isNFT: boolean
}

const BillPageComponent: React.FC<Props> = (
    props: Props
): React.ReactElement => {
    const {
        number,
        onChangeNumber,
        onSubmit,
        isLoading,
        error,
        data,
        isAcceptModalOpen,
        isBurnModalOpen,
        isFillModalOpen,
        isSendModalOpen,
        openModal,
        closeModal,
        isNFT
    } = props

    const { t } = useTranslation('common')

    return (
        <div className={data ? styles.withData : styles.container}>
            <MultiInput
                value={number}
                onChange={onChangeNumber}
                onSubmit={onSubmit}
                softnoteFound={Boolean(data)}
                error={error}
            />

            {data && !isNFT && (
                <div className={styles.billButtons}>
                    {(data.status === 'Filled' ||
                        data.status === 'InWallet') && (
                        <OperationButton
                            icon={IconAccept}
                            onClick={() => openModal('accept')}
                            label={t('labels.accept')}
                        />
                    )}

                    {(data.status === 'Filled' ||
                        data.status === 'InWallet') && (
                        <OperationButton
                            icon={IconSend}
                            onClick={() => openModal('send')}
                            label={t('labels.send')}
                        />
                    )}
                    {(data.status === 'Filled' ||
                        data.status === 'InWallet' ||
                        data.status === 12) && (
                        <OperationButton
                            icon={IconBurn}
                            onClick={() => openModal('burn')}
                            label={t('labels.burn')}
                        />
                    )}

                    {data.status === 'Coinaged' && (
                        <OperationButton
                            icon={IconFill}
                            onClick={() => openModal('fill')}
                            label={t('labels.fill')}
                            disabled={!data.currencyInfo.isActual}
                        />
                    )}
                </div>
            )}

            {data && <CanvasBill softnoteData={data} />}

            {data && (
                <div className={styles.bottomButton}>
                    {!isNFT && (
                        <OperationButton
                            disabled
                            icon={IconEvents}
                            label={t('labels.events')}
                        />
                    )}

                    <OperationButton
                        icon={IconProof}
                        label={t('labels.proofOfBalance')}
                        href={getCurrencyLink(data.currencyInfo.id, data.token)}
                        disabled={data.color === 'Green'}
                    />
                </div>
            )}

            {!data && (
                <Button
                    className={styles.button}
                    onClick={onSubmit}
                    isLoading={isLoading}
                >
                    {t('buttons.search')}
                </Button>
            )}

            {data && (
                <>
                    <FillModal
                        title={`${t('labels.fill')} ${
                            data.currencyInfo.ticker
                        } (${data.currencyInfo.protocol})`}
                        data={data}
                        isOpen={isFillModalOpen}
                        onClose={() => closeModal('fill')}
                    />

                    <SendModal
                        data={data}
                        isOpen={isSendModalOpen}
                        onClose={() => closeModal('send')}
                    />

                    <AcceptModal
                        data={data}
                        isOpen={isAcceptModalOpen}
                        onClose={() => closeModal('accept')}
                    />

                    <BurnModal
                        data={data}
                        isOpen={isBurnModalOpen}
                        onClose={() => closeModal('burn')}
                    />
                </>
            )}
        </div>
    )
}

export default BillPageComponent
