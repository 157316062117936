import { useEffect, useRef, useState } from 'react'
import { SATOSHI_PER_BTC } from 'utils/constants/renderBillsInfo'

import WebFont from 'webfontloader'
import styles from './index.module.scss'
import Loader from 'components/shared/Loader'
import classNames from 'classnames'
import { NFTBills, rocketNFT } from 'utils/constants/NFTBills'
import { NFTBillTemplate } from 'assets/billTemplates'
import rocketImage from 'assets/rocket.svg'
import NFTVideo from 'assets/NFTAnimation.mp4'
import {
    exponentialToDecimal,
    formatNumber,
    getTemplate,
    numberToText,
    numberWithSpaces
} from 'utils/functions/renderBillsFunctions'
import { FetchBillResponse } from 'services/Bill/types'

interface Props {
    softnoteData: FetchBillResponse
}

const CanvasBill: React.FC<Props> = (props: Props): React.ReactElement => {
    const { softnoteData } = props

    const [isLoaded, setIsLoaded] = useState(false)

    const canvasRef = useRef<HTMLCanvasElement | null>(null)
    const animationRef = useRef<number | null>(null)

    useEffect(() => {
        if (animationRef.current) {
            window.cancelAnimationFrame(animationRef.current)
            animationRef.current = null
        }

        if (softnoteData && canvasRef.current) {
            const {
                nominal,
                token,
                currencyInfo,
                serialNumber,
                color,
                status
            } = softnoteData

            const isNFT =
                softnoteData && NFTBills.includes(softnoteData.serialNumber)

            const canvas = canvasRef.current
            const ctx = canvas.getContext('2d')
            const billTemplate = new Image()
            const qrCode = new Image()

            const loadResources = [
                new Promise(resolve => {
                    billTemplate.onload = resolve
                    if (isNFT) {
                        billTemplate.src = NFTBillTemplate
                    } else {
                        billTemplate.src = getTemplate(
                            currencyInfo.ticker,
                            color,
                            status
                        )
                    }
                }),

                new Promise(resolve => {
                    qrCode.onload = resolve
                    if (isNFT) {
                        qrCode.src = `https://api.qrserver.com/v1/create-qr-code/?size=84x84&color=FFF&bgcolor=34-40-55&data=${window.location.href}`
                    } else {
                        qrCode.src = `https://api.qrserver.com/v1/create-qr-code/?size=84x84&data=${window.location.href}`
                    }
                }),
                new Promise<void>(resolve => {
                    WebFont.load({
                        google: {
                            families: ['Inter:400,600,700', 'Roboto Mono']
                        },
                        active: () => {
                            resolve()
                        }
                    })
                })
            ]

            Promise.all(loadResources).then(() => {
                setIsLoaded(true)
                if (ctx) {
                    ctx.clearRect(0, 0, canvas.width, canvas.height)
                    ctx.drawImage(
                        billTemplate,
                        0,
                        0,
                        canvas.width,
                        canvas.height
                    )

                    if (isNFT) {
                        ctx.fillStyle = 'white'
                        ctx.font = 'bold 34px Inter'
                        ctx.fillText('SoftNote Ordinals', 23, 103)
                        ctx.font = '16px Roboto Mono'
                        ctx.fillText(serialNumber, 25, 161)
                        ctx.font = '600 13px Inter'
                        ctx.drawImage(qrCode, 25, 183, 84, 84)
                        ctx.fillText(currencyInfo.network, 140, 38)
                        ctx.font = '500 13px Inter'
                        ctx.fillText(currencyInfo.protocol, 140, 50)

                        ctx.save()
                        ctx.translate(45, 618)
                        ctx.rotate((3 * Math.PI) / 2)

                        if (serialNumber === rocketNFT) {
                            ctx.font = '12px Roboto Mono'
                        } else {
                            ctx.font = '16px Roboto Mono'
                        }

                        ctx.fillText(token, -37, 243)

                        ctx.restore()

                        if (serialNumber === rocketNFT) {
                            const rocketPicture = new Image()
                            rocketPicture.src = rocketImage
                            rocketPicture.onload = () =>
                                ctx.drawImage(rocketPicture, 0, 281, 265, 405)
                        } else {
                            const video = document.createElement('video')
                            video.src = NFTVideo
                            video.muted = true
                            video.loop = true
                            video.autoplay = true
                            video.play()

                            const drawAnimation = () => {
                                ctx.drawImage(video, 0, 281, 265, 405)
                                animationRef.current =
                                    requestAnimationFrame(drawAnimation)
                            }
                            video.onloadeddata = drawAnimation
                        }

                        return
                    }
                    ctx.fillStyle = 'black'
                    ctx.font = 'bold 45px Inter'
                    ctx.fillText(
                        formatNumber(nominal, currencyInfo.id),
                        23,
                        112
                    )

                    ctx.font = '600 8px Inter'

                    if (currencyInfo.ticker === 'BTC') {
                        ctx.fillText(
                            numberToText(
                                nominal * SATOSHI_PER_BTC,
                                currencyInfo.ticker
                            ),
                            25,
                            126
                        )
                    }
                    if (nominal % 1 === 0 && nominal >= 10000) {
                        ctx.fillText(
                            numberToText(nominal, currencyInfo.ticker),
                            25,
                            126
                        )
                    }
                    ctx.font = '16px Roboto Mono'
                    ctx.fillText(serialNumber, 25, 161)
                    ctx.drawImage(qrCode, 23, 184, 84, 84)

                    ctx.font = '600 11px Inter'
                    ctx.fillStyle =
                        color === 'Blue' && status !== 'Coinaged'
                            ? 'white'
                            : 'black'

                    if (currencyInfo.ticker.length > 3) {
                        ctx.fillText(
                            `${currencyInfo.network} / ${currencyInfo.protocol}`,
                            167,
                            45
                        )
                    } else {
                        ctx.fillText(
                            `${currencyInfo.network} / ${currencyInfo.protocol}`,
                            150,
                            45
                        )
                    }

                    ctx.save()
                    ctx.translate(45, 618)
                    ctx.rotate((3 * Math.PI) / 2)
                    ctx.font = 'bold 27px Inter'
                    ctx.fillStyle = 'white'
                    ctx.globalAlpha = 0.8

                    if (currencyInfo.ticker === 'BTC') {
                        const nominalWidth = ctx.measureText(
                            `${exponentialToDecimal(nominal)} BTC | `
                        ).width

                        ctx.font = '17px Inter'
                        const satoshiWidth = ctx.measureText(
                            `${numberWithSpaces(
                                (nominal * SATOSHI_PER_BTC).toFixed()
                            )} SATS`
                        ).width
                        ctx.font = 'bold 27px Inter'

                        ctx.fillRect(-3, -30, nominalWidth + satoshiWidth, 39)
                    } else {
                        ctx.fillRect(
                            -3,
                            -30,
                            ctx.measureText(
                                `${numberWithSpaces(nominal)} ${
                                    currencyInfo.ticker
                                } `
                            ).width,
                            39
                        )
                    }

                    ctx.globalAlpha = 1
                    ctx.fillStyle = 'black'
                    ctx.fillText(
                        `${numberWithSpaces(nominal)} ${currencyInfo.ticker}`,
                        0,
                        0
                    )
                    if (currencyInfo.ticker === 'BTC') {
                        ctx.font = '27px Inter'
                        const nominalWidth = ctx.measureText(
                            `${exponentialToDecimal(nominal)} BTC |`
                        ).width

                        ctx.font = '17px Inter'

                        ctx.fillText(
                            `| ${numberWithSpaces(
                                (nominal * SATOSHI_PER_BTC).toFixed()
                            )} SATS`,
                            nominalWidth,
                            -4
                        )
                    }

                    // token

                    ctx.font = '500 15px Roboto Mono'
                    ctx.fillText(token, -39, 274)

                    // vertical network + protocol
                    ctx.fillStyle =
                        color === 'Blue' && status !== 'Coinaged'
                            ? 'white'
                            : 'black'
                    ctx.font = '600 7.8px Inter'
                    ctx.fillText(
                        `${currencyInfo.network} / ${currencyInfo.protocol}`
                            .split('')
                            .join(' '),
                        -31,
                        253.4
                    )

                    ctx.restore()
                }
            })
        }
        return () => {
            setIsLoaded(false)
        }
    }, [softnoteData])

    return (
        softnoteData && (
            <>
                <canvas
                    className={classNames({ [styles.notLoaded]: !isLoaded })}
                    ref={canvasRef}
                    width={343}
                    height={686}
                />
                {!isLoaded && (
                    <div className={styles.loaderWrapper}>
                        <Loader />
                    </div>
                )}
            </>
        )
    )
}

export default CanvasBill
