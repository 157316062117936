import { useEffect, useState } from 'react'
import { HubService } from 'services/Hub'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AppRoutes } from 'routes/AppRoutes'
import { FillStatus } from 'services/Hub/types'
import handleErrors from 'utils/functions/handleErrors'
import StatusCheckComponent from './component'
import { useCurrencies } from 'hooks/useCurrencies'

const StatusCheck: React.FC = () => {
    const navigate = useNavigate()

    const { currencies } = useCurrencies('green')

    const [searchParams] = useSearchParams()
    const id = searchParams.get('id') ?? ''

    const [skipPolling, setSkipPolling] = useState(false)

    const {
        data: {
            currency = '',
            amount = 0,
            address = '',
            addressSender = '',
            hashTransaction = '',
            status = FillStatus.Pending
        } = {},
        error,
        isLoading
    } = HubService.useCheckFillSoftnotesGreenQuery(
        { id },
        { pollingInterval: 5000, skip: skipPolling }
    )

    useEffect(() => {
        if (status !== FillStatus.Pending) {
            setSkipPolling(true)
        }
    }, [status])

    const selectedCurrency = currencies?.find(el => el.id === currency) ?? null

    const handleClickDone = () => {
        navigate(AppRoutes.hub)
    }

    return (
        <StatusCheckComponent
            currency={selectedCurrency}
            amount={amount}
            onClickDone={handleClickDone}
            address={address}
            senderAddress={addressSender}
            hashTransaction={hashTransaction}
            status={status}
            isLoading={isLoading}
            error={handleErrors(error)}
        />
    )
}

export default StatusCheck
