import i18n from 'i18n'
import * as Yup from 'yup'

const validationSchema = () =>
    Yup.object().shape({
        address: Yup.string()
            .required()
            .min(25)
            .matches(/^[a-zA-Z0-9]+$/, ({ path }) =>
                i18n.t('onlyLatinLettersAndDigits', {
                    ns: 'validation',
                    field: i18n.t(`fields.${path}`, { ns: 'validation' })
                })
            ),
        email: Yup.string().email().max(100).required()
    })

export default validationSchema
