import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { HandoverState, HandoverSteps } from './handoverTypes'
import { BanknoteResponse } from 'services/Hub/types'

const initialState: HandoverState = {
    step: HandoverSteps.request,
    banknotes: null
}

const handoverSlice = createSlice({
    name: 'handover',
    initialState,
    reducers: {
        setStep: (state, { payload }: PayloadAction<HandoverSteps>) => {
            state.step = payload
        },
        setBanknotes: (
            state,
            { payload }: PayloadAction<BanknoteResponse[] | null>
        ) => {
            state.banknotes = payload
        },
        reset: () => initialState
    }
})

export const { actions: handoverActions } = handoverSlice

export default handoverSlice
