import { useAppDispatch, useAppSelector } from 'store/store'
import { alert } from '@crispmindltd/client-react-package'
import AcceptResultComponent from './component'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { AppRoutes } from 'routes/AppRoutes'
import { burnActions } from 'store/burn/burnSlice'
import { AlertData } from './types'
import { hubActions } from 'store/hub/hubSlice'
import { useTranslation } from 'react-i18next'
import { useCurrencies } from 'hooks/useCurrencies'

const BurnGreenResult: React.FC = (): React.ReactElement => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [copied, setCopied] = useState(false)

    const { currencies } = useCurrencies('green')

    const { t } = useTranslation('burn')

    const banknotes = useAppSelector(state => state.burn.banknotesResultGreen)

    const banknotesWithError = banknotes?.filter(banknote => banknote.errorCode)

    const hashTransaction = useAppSelector(
        state => state.burn.hashTransactionGreen
    )

    const totals = useAppSelector(state => state.burn.totalsGreen)

    const currency = currencies?.find(
        currency => currency.id === totals.currency
    )

    const getAlertData = (): AlertData => {
        if (banknotesWithError) {
            if (!hashTransaction && banknotesWithError.length > 0) {
                return {
                    type: 'error',
                    text: t('notBurned')
                }
            }
            if (hashTransaction && banknotesWithError.length > 0) {
                return {
                    type: 'warning',
                    text: t('halfBurnedGreen')
                }
            }
            if (hashTransaction && banknotesWithError.length === 0) {
                return {
                    type: 'success',
                    text: t('successGreen')
                }
            }
        }

        return { type: 'error', text: t('error') }
    }

    const copySuccess = () => {
        setCopied(true)
    }

    const handleClickDone = () => {
        if (hashTransaction) {
            if (copied) {
                dispatch(burnActions.reset())
                dispatch(hubActions.setOperation(null))
                navigate(AppRoutes.hub)
            } else {
                alert.warning(t('keepTheTransactionHashInASafePlace'))
            }
        } else {
            dispatch(burnActions.reset())
            dispatch(hubActions.setOperation(null))
            navigate(AppRoutes.hub)
        }
    }

    return (
        <AcceptResultComponent
            onClickDone={handleClickDone}
            copySuccess={copySuccess}
            alertData={getAlertData()}
            banknotesWithError={banknotesWithError}
            hashTransaction={hashTransaction}
            totals={totals}
            currency={currency}
        />
    )
}

export default BurnGreenResult
