import i18n from 'i18n'
import * as Yup from 'yup'

const validationSchema = () =>
    Yup.object().shape({
        senderAddress: Yup.string()
            .required()
            .matches(/^\S*$/, ({ path }) =>
                i18n.t('noSpaces', {
                    ns: 'validation',
                    field: i18n.t(`fields.${path}`, { ns: 'validation' })
                })
            )
            .min(25),
        hashTransaction: Yup.string()
            .required()
            .matches(/^\S*$/, ({ path }) =>
                i18n.t('noSpaces', {
                    ns: 'validation',
                    field: i18n.t(`fields.${path}`, { ns: 'validation' })
                })
            )
            .min(64)
    })

export default validationSchema
