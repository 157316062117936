import classnames from 'classnames'
import { useNavigate } from 'react-router-dom'
import ArrowIcon from 'assets/icons/back.svg'
import IconInfo from 'assets/icons/info.svg'
import styles from './styles.module.scss'

interface Props {
    title: string
    subTitle?: string
    subTitleColor?: string
    onClickBack?: () => void
    onClickInfo?: () => void
    withInfo?: boolean
    withBackButton?: boolean
    className?: string
}

const SectionHeader: React.FC<Props> = (props: Props): React.ReactElement => {
    const {
        title,
        subTitle,
        subTitleColor,
        onClickBack,
        className,
        onClickInfo,
        withInfo,
        withBackButton = true
    } = props
    const navigate = useNavigate()

    const handleClickBack = () => {
        if (onClickBack) {
            onClickBack()
        } else {
            navigate(-1)
        }
    }

    return (
        <div className={classnames(styles.header, className)}>
            <button
                onClick={handleClickBack}
                className={classnames(withBackButton ? '' : styles.hidden)}
            >
                <img className={styles.buttonIcon} src={ArrowIcon} alt='back' />
            </button>
            <div className={styles.textWrapper}>
                <span className={styles.label}>{title}</span>
                {subTitle && (
                    <span
                        className={styles.subTitle}
                        style={{ color: subTitleColor ?? '#ffffff' }}
                    >
                        {subTitle}
                    </span>
                )}
            </div>
            <button
                onClick={onClickInfo}
                className={withInfo ? '' : styles.hidden}
            >
                <img className={styles.buttonIcon} src={IconInfo} alt='info' />
            </button>
        </div>
    )
}
export default SectionHeader
