import SectionHeader from 'components/shared/SectionHeader'
import { Currency } from 'store/fill/fillTypes'
import styles from '../styles.module.scss'
import {
    Alert,
    Button,
    CopyButton,
    Select,
    TextField,
    Tooltip
} from '@crispmindltd/client-react-package'
import { useQRCode } from 'next-qrcode'
import { FormikProps } from 'formik'
import { Values } from './types'
import { getCurrencyLabel } from 'utils/functions/currency'
import { formatNumber } from 'utils/functions/format'
import { useTranslation } from 'react-i18next'
import { OptionModel } from '@crispmindltd/client-react-package/dist/components/Select/types'
import { exponentialToDecimal } from 'utils/functions/renderBillsFunctions'

interface Props {
    selectedCurrency: Currency | null
    onClickBack: () => void
    fillAmount: number
    onSubmitForm: () => void
    isMobile: boolean
    address: string
    formik: FormikProps<Values>
    error: string
    isFetching: boolean
    networks: OptionModel[] | undefined
    selectedNetworkOption: OptionModel | undefined
    onChangeNetwork: (value: OptionModel) => void
    networkError: string
    isNetworkLoading: boolean
}

const EnterAddressStepComponent: React.FC<Props> = props => {
    const {
        selectedCurrency,
        onClickBack,
        fillAmount,
        onSubmitForm,
        isMobile,
        address,
        formik,
        error,
        isFetching,
        networks,
        onChangeNetwork,
        selectedNetworkOption,
        networkError,
        isNetworkLoading
    } = props

    const { Canvas } = useQRCode()

    const { t } = useTranslation(['common', 'fill'])

    return (
        <>
            <SectionHeader
                title={`${t('labels.fill')} ${getCurrencyLabel(
                    selectedCurrency
                )}`}
                subTitle={t('greenHub', { ns: 'fill' })}
                subTitleColor='#00E979'
                onClickBack={onClickBack}
            />

            {address && (
                <div className={styles.qrWrapper}>
                    <Canvas
                        text={address}
                        options={{
                            margin: 2,
                            width: isMobile ? 285 : 160,
                            color: {
                                dark: '#000000',
                                light: '#ffffff'
                            }
                        }}
                    />
                </div>
            )}

            <div className={styles.amountLabel}>{t('labels.amount')}</div>
            <div className={styles.amountValue}>
                {formatNumber(fillAmount, selectedCurrency?.decimal ?? 8)}{' '}
                {selectedCurrency?.ticker}{' '}
                <CopyButton
                    value={exponentialToDecimal(fillAmount)}
                    className={styles.copyAmount}
                />
            </div>

            <div className={styles.fieldsWrapper}>
                <Select
                    options={networks}
                    selected={selectedNetworkOption?.text}
                    onChange={onChangeNetwork}
                    readOnly={networks && networks.length <= 1}
                    label={
                        <div className={styles.selectLabel}>
                            {t('labels.network')}{' '}
                            <Tooltip
                                value={t('labels.transactionNetwork')}
                                pointerPosition='left-top'
                            />
                        </div>
                    }
                    className={styles.select}
                    error={networkError}
                    isLoading={isNetworkLoading}
                />

                <TextField
                    name='address'
                    label={t('labels.address')}
                    value={address}
                    readOnly
                    withCopy
                />

                <TextField
                    name='senderAddress'
                    label={t('labels.senderAddress')}
                    value={formik.values.senderAddress}
                    onChange={formik.handleChange}
                    handleClear={() =>
                        formik.setFieldValue('senderAddress', '')
                    }
                    error={formik.errors.senderAddress}
                />

                <TextField
                    name='hashTransaction'
                    label={t('labels.hashTransaction')}
                    value={formik.values.hashTransaction}
                    onChange={formik.handleChange}
                    handleClear={() =>
                        formik.setFieldValue('hashTransaction', '')
                    }
                    error={formik.errors.hashTransaction}
                />
            </div>

            {error && (
                <Alert
                    type='error'
                    text={error}
                    icon
                    className={styles.alert}
                />
            )}

            <div className={styles.bottom}>
                <Button
                    className={styles.nextButton}
                    onClick={onSubmitForm}
                    disabled={
                        !formik.values.senderAddress ||
                        !formik.values.hashTransaction
                    }
                    isLoading={isFetching}
                >
                    {t('buttons.finish')}
                </Button>
            </div>
        </>
    )
}

export default EnterAddressStepComponent
