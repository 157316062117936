import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import i18next from 'i18next'

const baseQueryArgs: FetchBaseQueryArgs = {
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders(headers) {
        const language = i18next.language
        headers.set('lang', language)
    }
}

const baseQuery = fetchBaseQuery(baseQueryArgs)

export const cashApi = createApi({
    reducerPath: 'cashApi',
    baseQuery,
    endpoints: () => ({}),
    tagTypes: ['Nominals', 'Bill']
})
