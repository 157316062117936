import { useTranslation } from 'react-i18next'
import styles from './styles.module.scss'

const ExampleDataFormat: React.FC = () => {
    const { t } = useTranslation('common')
    return (
        <div className={styles.example}>
            <div className={styles.exampleTitle}>{t('exampleDataFormat')}</div>
            <div className={styles.exampleRow}>0000100000000077/21436587</div>
            <div className={styles.exampleRow}>0000100000000077/21436587</div>
            <div className={styles.exampleRow}>0000100000000077/21436587</div>
        </div>
    )
}

export default ExampleDataFormat
