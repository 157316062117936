import { Outlet, useLocation } from 'react-router-dom'
import Footer from './Footer'
import Header from './Header'
import s from './index.module.scss'
import SoftNoteTabMenu from 'components/shared/SoftNoteTabMenu'
import { AppRoutes } from 'routes/AppRoutes'
import { Suspense, useMemo } from 'react'
import Loader from 'components/shared/Loader'

const Layout: React.FC = (): React.ReactElement => {
    const { pathname } = useLocation()

    const shouldDisplayTabs = useMemo(() => {
        return Object.values(AppRoutes).includes(pathname)
    }, [pathname])

    return (
        <div className={s.wrapper}>
            <Header />

            <div className={s.content}>
                {shouldDisplayTabs && <SoftNoteTabMenu />}

                <Suspense fallback={<Loader className={s.loader} />}>
                    <Outlet />
                </Suspense>
            </div>

            <Footer />
        </div>
    )
}
export default Layout
