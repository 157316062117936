import SectionHeader from 'components/shared/SectionHeader'
import { Currency } from 'store/fill/fillTypes'
import styles from '../styles.module.scss'
import Checkbox from 'components/shared/Checkbox'
import { Alert, Button } from '@crispmindltd/client-react-package'
import { getCurrencyLabel } from 'utils/functions/currency'
import Loader from 'components/shared/Loader'
import { useTranslation } from 'react-i18next'
import { formatNumber } from 'utils/functions/format'

interface Props {
    selectedCurrency: Currency | null
    onClickBack: () => void
    nominals: number[]
    selectedNominal: number | null
    onSelectNominal: (value: number) => void
    onClickNext: () => void
    error: string
    isFetching: boolean
}

const SelectNominalStepComponent: React.FC<Props> = props => {
    const {
        selectedCurrency,
        onClickBack,
        nominals,
        selectedNominal,
        onSelectNominal,
        onClickNext,
        error,
        isFetching
    } = props

    const { t } = useTranslation(['common', 'fill'])

    return (
        <>
            <SectionHeader
                title={`${t('labels.fill')} ${getCurrencyLabel(
                    selectedCurrency
                )}`}
                subTitle={t('blueHub', { ns: 'fill' })}
                subTitleColor='#336BFF'
                onClickBack={onClickBack}
            />

            <div className={styles.poolLabel}>
                {t('blueSoftnotePool', { ns: 'fill' })}
            </div>

            {isFetching && <Loader />}

            <div className={styles.nominals}>
                {nominals.map(nominal => (
                    <div
                        key={nominal}
                        className={styles.nominalItem}
                        onClick={() => onSelectNominal(nominal)}
                    >
                        <Checkbox
                            checked={selectedNominal === nominal}
                            className={styles.checkbox}
                        />
                        <span>
                            {formatNumber(
                                nominal,
                                selectedCurrency?.decimal ?? 8
                            )}{' '}
                            {selectedCurrency?.ticker}
                        </span>
                    </div>
                ))}
            </div>

            {error && (
                <Alert
                    type='error'
                    text={error}
                    icon
                    className={styles.alert}
                />
            )}

            <div className={styles.bottom}>
                <Button
                    className={styles.nextButton}
                    disabled={!selectedNominal}
                    onClick={onClickNext}
                >
                    {t('buttons.next')}
                </Button>
            </div>
        </>
    )
}

export default SelectNominalStepComponent
