import {
    Alert,
    Button,
    CopyButton,
    ModalFooter,
    ModalBody,
    TextField,
    Select,
    Tooltip
} from '@crispmindltd/client-react-package'
import { useQRCode } from 'next-qrcode'
import { FormikProps } from 'formik'
import styles from '../styles.module.scss'
import { Values } from './types'
import { useTranslation } from 'react-i18next'
import { Currency } from 'store/fill/fillTypes'
import { OptionModel } from '@crispmindltd/client-react-package/dist/components/Select/types'
import { exponentialToDecimal } from 'utils/functions/renderBillsFunctions'

interface Props {
    onClickBack: () => void
    nominal: any
    onSubmitForm: () => void
    isMobile: boolean
    address: string
    formik: FormikProps<Values>
    error: string
    isFetching: boolean
    currency: Currency
    isBillGreen: boolean
    networks: OptionModel[] | undefined
    selectedNetworkOption: OptionModel | undefined
    onChangeNetwork: (value: OptionModel) => void
    networkError: string
    isNetworkLoading: boolean
}

const EnterAddressFormComponent: React.FC<Props> = (props: Props) => {
    const {
        onClickBack,
        nominal,
        onSubmitForm,
        isMobile,
        address,
        formik,
        error,
        isFetching,
        currency,
        isBillGreen,
        networks,
        selectedNetworkOption,
        onChangeNetwork,
        networkError,
        isNetworkLoading
    } = props

    const { Canvas } = useQRCode()

    const { t } = useTranslation('common')

    return (
        <>
            <ModalBody className={styles.content}>
                {address && (
                    <div className={styles.qrWrapper}>
                        <Canvas
                            text={address}
                            options={{
                                margin: 2,
                                width: isMobile ? 285 : 160,
                                color: {
                                    dark: '#000000',
                                    light: '#ffffff'
                                }
                            }}
                        />
                    </div>
                )}

                <div className={styles.amountLabel}>{t('labels.amount')}</div>
                <div className={styles.amountValue}>
                    {nominal} {currency.ticker}{' '}
                    <CopyButton
                        className={styles.copyAmount}
                        value={exponentialToDecimal(nominal)}
                    />
                </div>

                <div className={styles.fieldsWrapper}>
                    {isBillGreen && (
                        <Select
                            options={networks}
                            selected={selectedNetworkOption?.text}
                            onChange={onChangeNetwork}
                            readOnly={networks && networks.length <= 1}
                            label={
                                <div className={styles.selectLabel}>
                                    {t('labels.network')}{' '}
                                    <Tooltip
                                        value={t('labels.transactionNetwork')}
                                        pointerPosition='left-top'
                                    />
                                </div>
                            }
                            className={styles.select}
                            error={networkError}
                            isLoading={isNetworkLoading}
                        />
                    )}

                    <TextField
                        name='address'
                        label={t('labels.address')}
                        value={address}
                        readOnly
                        withCopy
                    />

                    <TextField
                        name='senderAddress'
                        label={t('labels.senderAddress')}
                        value={formik.values.senderAddress}
                        onChange={formik.handleChange}
                        handleClear={() =>
                            formik.setFieldValue('senderAddress', '')
                        }
                        error={formik.errors.senderAddress}
                    />

                    <TextField
                        name='hashTransaction'
                        label={t('labels.hashTransaction')}
                        value={formik.values.hashTransaction}
                        onChange={formik.handleChange}
                        handleClear={() =>
                            formik.setFieldValue('hashTransaction', '')
                        }
                        error={formik.errors.hashTransaction}
                    />
                </div>

                {error && (
                    <Alert
                        type='error'
                        text={error}
                        icon
                        className={styles.alert}
                    />
                )}
            </ModalBody>

            <ModalFooter>
                <Button
                    type='secondary'
                    className={styles.nextButton}
                    onClick={onClickBack}
                >
                    {t('buttons.back')}
                </Button>

                <Button
                    className={styles.nextButton}
                    onClick={onSubmitForm}
                    disabled={
                        !formik.values.senderAddress ||
                        !formik.values.hashTransaction
                    }
                    isLoading={isFetching}
                >
                    {t('buttons.finish')}
                </Button>
            </ModalFooter>
        </>
    )
}

export default EnterAddressFormComponent
