import {
    btcBlue,
    bnbBlue,
    ethBlue,
    usdtBlue,
    ltcBlue,
    trxBlue,
    kasBlue,
    btcGreen,
    bnbGreen,
    ethGreen,
    usdtGreen,
    ltcGreen,
    trxGreen,
    kasGreen,
    btcYellowBlue,
    bnbYellowBlue,
    ethYellowBlue,
    usdtYellowBlue,
    ltcYellowBlue,
    trxYellowBlue,
    kasYellowBlue,
    btcYellowGreen,
    bnbYellowGreen,
    ethYellowGreen,
    usdtYellowGreen,
    ltcYellowGreen,
    trxYellowGreen,
    kasYellowGreen
} from 'assets/billTemplates'

import { BillTemplates, YellowTemplates } from './types'

export const SATOSHI_PER_BTC = 100000000

export const blueTemplates: BillTemplates = {
    BTC: btcBlue,
    ETH: ethBlue,
    BNB: bnbBlue,
    USDT: usdtBlue,
    TRX: trxBlue,
    LTC: ltcBlue,
    KAS: kasBlue
}

export const greenTemplates: BillTemplates = {
    BTC: btcGreen,
    ETH: ethGreen,
    BNB: bnbGreen,
    USDT: usdtGreen,
    TRX: trxGreen,
    LTC: ltcGreen,
    KAS: kasGreen
}

export const yellowTemplates: YellowTemplates = {
    Blue: {
        BTC: btcYellowBlue,
        ETH: ethYellowBlue,
        BNB: bnbYellowBlue,
        USDT: usdtYellowBlue,
        TRX: trxYellowBlue,
        LTC: ltcYellowBlue,
        KAS: kasYellowBlue
    },
    Green: {
        BTC: btcYellowGreen,
        ETH: ethYellowGreen,
        BNB: bnbYellowGreen,
        USDT: usdtYellowGreen,
        TRX: trxYellowGreen,
        LTC: ltcYellowGreen,
        KAS: kasYellowGreen
    }
}
