import { useAppDispatch, useAppSelector } from 'store/store'
import SelectNominalStepComponent from './component'
import { fillActions } from 'store/fill/fillSlice'
import { FillSteps } from 'store/fill/fillTypes'
import React, { useEffect } from 'react'
import { HubService } from 'services/Hub'
import { SoftnoteColor } from 'models/Softnote'
import handleErrors from 'utils/functions/handleErrors'

const SelectNominalStep: React.FC = () => {
    const dispatch = useAppDispatch()

    const selectedCurrency = useAppSelector(
        state => state.fill.selectedBlueCurrency
    )

    const [fetchNominals, { data, error, isFetching }] =
        HubService.useLazyGetNominalsQuery()

    useEffect(() => {
        if (!selectedCurrency) {
            return
        }

        fetchNominals({
            color: SoftnoteColor.Blue,
            currencyKey: selectedCurrency.key
        })
    }, [fetchNominals, selectedCurrency])

    const nominals = data?.nominals ?? []
    const sortedNominals = [...nominals].sort((a, b) => a - b)

    const selectedNominal = useAppSelector(
        state => state.fill.selectedBlueNominal
    )

    const handleSelectNominal = (nominal: number) => {
        dispatch(fillActions.setSelectedNominal(nominal))
    }

    const handleClickBack = () => {
        dispatch(fillActions.reset())
    }

    const handleClickNext = () => {
        dispatch(fillActions.setStep(FillSteps.enterVault))
    }

    return (
        <SelectNominalStepComponent
            nominals={sortedNominals}
            selectedCurrency={selectedCurrency}
            onClickBack={handleClickBack}
            selectedNominal={selectedNominal}
            onSelectNominal={handleSelectNominal}
            onClickNext={handleClickNext}
            error={handleErrors(error)}
            isFetching={isFetching}
        />
    )
}

export default SelectNominalStep
