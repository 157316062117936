import { FillModalSteps } from 'store/fillModal/fillModalTypes'
import { useAppSelector } from 'store/store'
import EnterVaultEmailForm from './EnterVaultEmailForm'
import EnterAddressForm from './EnterAddressForm'
import ResultForm from './ResultForm'
import { ModalWrapper } from '@crispmindltd/client-react-package'
import { FetchBillResponse } from 'services/Bill/types'

interface Props {
    isOpen: boolean
    onClose: () => void
    title: string
    data: FetchBillResponse | undefined
}

const FillModal: React.FC<Props> = props => {
    const { isOpen, onClose, title, data } = props

    const step = useAppSelector(state => state.fillModal.step)

    const stepComponents = {
        [FillModalSteps.enterVault]: EnterVaultEmailForm,
        [FillModalSteps.enterAddress]: EnterAddressForm,
        [FillModalSteps.result]: ResultForm
    }

    if (!data) return null

    const StepComponent = stepComponents[step]

    return (
        <ModalWrapper open={isOpen} onClose={onClose} title={title}>
            <StepComponent softnoteData={data} onClose={onClose} />
        </ModalWrapper>
    )
}

export default FillModal
