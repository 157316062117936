import SectionHeader from 'components/shared/SectionHeader'
import { Currency } from 'store/fill/fillTypes'
import styles from '../styles.module.scss'
import {
    Alert,
    Button,
    CopyButton,
    TextField
} from '@crispmindltd/client-react-package'
import { getCurrencyLabel } from 'utils/functions/currency'
import { formatNumber } from 'utils/functions/format'
import { useTranslation } from 'react-i18next'
import { useFillStatuses } from 'hooks/useFillStatuses'
import { FillStatus } from 'services/Hub/types'
import { exponentialToDecimal } from 'utils/functions/renderBillsFunctions'

interface Props {
    selectedCurrency: Currency | null
    fillAmount: number
    onClickDone: () => void
    address: string
    senderAddress: string
    hashTransaction: string
    status: FillStatus
}

const ResultStepComponent: React.FC<Props> = props => {
    const {
        selectedCurrency,
        fillAmount,
        onClickDone,
        address,
        senderAddress,
        hashTransaction,
        status
    } = props

    const { t } = useTranslation(['common', 'fill'])
    const statuses = useFillStatuses()

    return (
        <>
            <SectionHeader
                title={`${t('labels.fill')} ${getCurrencyLabel(
                    selectedCurrency
                )}`}
                subTitle={t('greenHub', { ns: 'fill' })}
                subTitleColor='#00E979'
                withBackButton={false}
            />

            <Alert
                type={statuses[status].alertType}
                text={`${statuses[status].alertText}${
                    status === FillStatus.Pending
                        ? `. ${t('linkHasBeenSent', { ns: 'fill' })}`
                        : ''
                }`}
                icon
                disableAnimation
                className={styles.alert}
            />

            <div className={styles.amountLabel}>{t('labels.amount')}</div>
            <div className={styles.amountValue}>
                {formatNumber(fillAmount, selectedCurrency?.decimal ?? 8)}{' '}
                {selectedCurrency?.ticker}{' '}
                <CopyButton
                    className={styles.copyAmount}
                    value={exponentialToDecimal(fillAmount)}
                />
            </div>

            <div className={styles.fieldsWrapper}>
                <TextField
                    name='address'
                    label={t('labels.address')}
                    value={address}
                    readOnly
                    withCopy
                />

                <TextField
                    name='senderAddress'
                    label={t('labels.senderAddress')}
                    value={senderAddress}
                    readOnly
                    withCopy
                />

                <TextField
                    name='hashTransaction'
                    label={t('labels.hashTransaction')}
                    value={hashTransaction}
                    readOnly
                    withCopy
                />
            </div>

            <div className={styles.bottom}>
                <Button className={styles.nextButton} onClick={onClickDone}>
                    {t(
                        status !== FillStatus.Pending
                            ? 'buttons.done'
                            : 'buttons.backToHub'
                    )}
                </Button>
            </div>
        </>
    )
}

export default ResultStepComponent
