import {
    Alert,
    Button,
    CopyButton,
    ModalBody,
    ModalFooter,
    TextField
} from '@crispmindltd/client-react-package'
import { FormikProps } from 'formik'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Values } from './types'
import styles from '../styles.module.scss'
import Checkbox from 'components/shared/Checkbox'
import { useTranslation } from 'react-i18next'
import { FetchBillResponse } from 'services/Bill/types'

interface Props {
    formik: FormikProps<Values>
    onSubmitForm: () => void
    onClose: () => void
    isSuccess: boolean
    data: FetchBillResponse
    splitNumber: (num: string) => string
    isLoading: boolean
    error: any
    passCode: string
    checkbox: boolean
    toggleCheckbox: () => void
    successAlertText: React.ReactElement | string
    onChangeCaptcha: (value: string | null) => void
    refreshReCaptcha: boolean
}

const AcceptFormComponent: React.FC<Props> = (props: Props) => {
    const {
        formik,
        onSubmitForm,
        isSuccess,
        onClose,
        data,
        splitNumber,
        isLoading,
        error,
        passCode,
        checkbox,
        toggleCheckbox,
        successAlertText,
        onChangeCaptcha,
        refreshReCaptcha
    } = props

    const { t } = useTranslation(['common', 'accept'])

    return (
        <>
            <ModalBody className={styles.content}>
                {isSuccess && (
                    <Alert
                        className={styles.alert}
                        type='success'
                        text={successAlertText}
                        icon
                        disableAnimation
                    />
                )}

                {error && (
                    <Alert
                        style={{ marginBottom: '24px' }}
                        type='error'
                        text={error}
                        icon
                    />
                )}

                <div className={styles.amountLabel}>{t('labels.nominal')}</div>
                <div className={styles.amountValue}>
                    {data.nominal} {data.currencyInfo.ticker}
                </div>

                <div className={styles.amountLabel}>
                    {t('labels.softnoteNumber')}
                </div>
                <div className={styles.serialWrapper}>
                    <div className={styles.amountValue}>
                        {splitNumber(data.serialNumber)}{' '}
                        <CopyButton
                            value={data.serialNumber}
                            className={styles.copyAmount}
                        />
                    </div>
                </div>

                {!isSuccess ? (
                    <div className={styles.fieldsWrapper}>
                        <div className={styles.passcodeField}>
                            <TextField
                                name='passcode'
                                value={formik.values.passcode}
                                onChange={formik.handleChange}
                                handleClear={() =>
                                    formik.setFieldValue('passcode', '')
                                }
                                label={
                                    <div className={styles.inputLabel}>
                                        {t('labels.passcode')}
                                    </div>
                                }
                                error={formik.errors.passcode}
                            />
                        </div>
                    </div>
                ) : (
                    <>
                        <div className={styles.passcodeLabel}>
                            {t('labels.passcode')}
                        </div>
                        <div className={styles.amountValue}>
                            {passCode}{' '}
                            <CopyButton
                                value={passCode}
                                className={styles.copyAmount}
                            />
                        </div>
                    </>
                )}

                {isSuccess && (
                    <Alert
                        type='warning'
                        text={t('warning', { ns: 'accept' })}
                        description={t('cannotBeRecovered', { ns: 'accept' })}
                        icon
                        disableAnimation
                    />
                )}
            </ModalBody>

            <ModalFooter className={styles.footer}>
                {isSuccess ? (
                    <Button className={styles.nextButton} onClick={onClose}>
                        {t('buttons.done')}
                    </Button>
                ) : (
                    <>
                        <div className={styles.checkField}>
                            <span>
                                {t('onceYourSNAccepted', { ns: 'accept' })}
                            </span>
                        </div>

                        <div className={styles.checkField}>
                            <div>
                                <Checkbox
                                    checked={checkbox}
                                    onClick={toggleCheckbox}
                                    className={styles.checkbox}
                                />
                            </div>

                            <span>
                                {t('stableConnection', { ns: 'accept' })}
                            </span>
                        </div>

                        <Button
                            className={styles.nextButton}
                            disabled={!formik.values.passcode || !checkbox}
                            onClick={onSubmitForm}
                            isLoading={isLoading}
                        >
                            {t('buttons.finish')}
                        </Button>

                        <GoogleReCaptcha
                            refreshReCaptcha={refreshReCaptcha}
                            onVerify={onChangeCaptcha}
                        />
                    </>
                )}
            </ModalFooter>
        </>
    )
}

export default AcceptFormComponent
