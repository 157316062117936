import { ApiRoutes } from 'routes/ApiRoutes'
import { cashApi } from 'services'
import {
    BurnBlueArgs,
    BurnBlueResponse,
    CheckFillArgs,
    CheckFillResponse,
    FetchBillArgs,
    FetchBillResponse,
    FillBlueAcceptArgs,
    FillBlueAcceptResponse,
    FillBlueStartResponse,
    FillGreenStartResponse,
    FillStartArgs
} from './types'

export const BillService = cashApi.injectEndpoints({
    endpoints: build => ({
        fetchBill: build.query<FetchBillResponse, FetchBillArgs>({
            query: args => ({
                url: ApiRoutes.softnote,
                params: args
            }),
            transformResponse: (response: FetchBillResponse) => {
                return {
                    ...response,
                    currencyInfo: {
                        ...response.currencyInfo,
                        ticker: response.currencyInfo.ticker.toUpperCase(),
                        protocol: response.currencyInfo.protocol.toUpperCase(),
                        network: response.currencyInfo.network.toUpperCase()
                    }
                }
            },
            providesTags: ['Bill']
        }),
        import: build.query<any, any>({
            query: args => ({
                url: 'softnote/import',
                body: args,
                method: 'POST'
            })
        }),
        handover: build.query<any, any>({
            query: args => ({
                url: 'softnote/handover',
                body: args,
                method: 'POST'
            })
        }),
        accept: build.query<any, any>({
            query: args => ({
                url: 'softnote/accept',
                body: args,
                method: 'POST'
            })
        }),
        burnBlue: build.query<BurnBlueResponse, BurnBlueArgs>({
            query: args => ({
                url: 'softnote/burn',
                body: args,
                method: 'POST'
            })
        }),
        fillBlueStart: build.query<FillBlueStartResponse, FillStartArgs>({
            query: args => ({
                url: 'softnote/fill/serialnumber',
                body: args,
                method: 'POST'
            })
        }),
        fillBlueAccept: build.query<FillBlueAcceptResponse, FillBlueAcceptArgs>(
            {
                query: args => ({
                    url: 'softnote/fill/accept',
                    body: args,
                    method: 'POST'
                })
            }
        ),
        checkFill: build.query<CheckFillResponse, CheckFillArgs>({
            query: ({ id }) => ({
                url: `softnote/fill/check/${id}`,
                method: 'POST'
            })
        }),
        fillGreenStart: build.query<FillGreenStartResponse, FillStartArgs>({
            query: args => ({
                url: 'sngreen/fill/serialNumber',
                body: args,
                method: 'POST'
            })
        })
    })
})
