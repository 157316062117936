import {
    Alert,
    Button,
    ModalBody,
    ModalFooter,
    TextField
} from '@crispmindltd/client-react-package'
import VaultEmailTab from 'components/shared/VaultEmailTab'
import { FormikProps } from 'formik'
import { Values } from './types'
import styles from '../styles.module.scss'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useTranslation } from 'react-i18next'

interface Props {
    formik: FormikProps<Values>
    onSubmitForm: () => void
    vaultTab: string
    onChangeVaultTab: (value: string) => void
    error: string
    isFetching: boolean
    onChangeCaptcha: (value: string | null) => void
    refreshReCaptcha: boolean
}

const EnterVaultEmailFormComponent: React.FC<Props> = (props: Props) => {
    const {
        formik,
        onSubmitForm,
        vaultTab,
        onChangeVaultTab,
        error,
        isFetching,
        onChangeCaptcha,
        refreshReCaptcha
    } = props

    const { t } = useTranslation(['common', 'fill'])

    return (
        <>
            <ModalBody>
                <TextField
                    name='tectumId'
                    value={formik.values.tectumId}
                    onChange={e =>
                        formik.setFieldValue(
                            'tectumId',
                            e.target.value.replace(/\D/g, '')
                        )
                    }
                    handleClear={() => formik.setFieldValue('tectumId', '')}
                    label={t('beneficiaryTectumID', { ns: 'fill' })}
                    error={formik.errors.tectumId}
                />

                <VaultEmailTab
                    currentTab={vaultTab}
                    onChangeTab={onChangeVaultTab}
                    className={styles.vaultTab}
                />

                {vaultTab === 'vault' && (
                    <TextField
                        name='vaultId'
                        value={formik.values.vaultId}
                        onChange={e =>
                            formik.setFieldValue(
                                'vaultId',
                                e.target.value.replace(/\D/g, '')
                            )
                        }
                        handleClear={() => formik.setFieldValue('vaultId', '')}
                        label={t('labels.vaultID')}
                        error={formik.errors.vaultId}
                    />
                )}

                {vaultTab === 'email' && (
                    <TextField
                        name='email'
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        handleClear={() => formik.setFieldValue('email', '')}
                        label={t('labels.email')}
                        error={formik.errors.email}
                    />
                )}

                {error && (
                    <Alert
                        type='error'
                        text={error}
                        icon
                        className={styles.error}
                    />
                )}
            </ModalBody>

            <ModalFooter>
                <Button
                    className={styles.nextButton}
                    disabled={!formik.values.vaultId && !formik.values.email}
                    onClick={onSubmitForm}
                    isLoading={isFetching}
                >
                    {t('buttons.next')}
                </Button>

                <GoogleReCaptcha
                    refreshReCaptcha={refreshReCaptcha}
                    onVerify={onChangeCaptcha}
                />
            </ModalFooter>
        </>
    )
}

export default EnterVaultEmailFormComponent
