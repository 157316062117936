import RequestFormComponent from './components'
import { FormikProps, useFormik } from 'formik'
import { useAppDispatch, useAppSelector } from 'store/store'
import { Values } from './types'
import { useCallback, useEffect, useState } from 'react'
import { sendModalActions } from 'store/sendModal/sendModaltSlice'
import { BillService } from 'services/Bill'
import handleErrors from 'utils/functions/handleErrors'
import createValidationSchema from './validationSchema'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { SerializedError } from '@reduxjs/toolkit'
import { SendModalOperations } from 'store/sendModal/sendModalTypes'

interface Props {
    data: any
    onClose: () => void
}

const RequestForm: React.FC<Props> = (props: Props) => {
    const { data, onClose } = props

    const [validateOnChange, setValidateOnChange] = useState(false)
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)

    const operation = useAppSelector(state => state.sendModal.operation)

    const [
        importSoftnote,
        {
            isSuccess: isImportSuccess,
            error: importError,
            isFetching: isImportFetching
        }
    ] = BillService.useLazyImportQuery()

    const [
        handoverSoftnote,
        {
            isSuccess: isHandoverSuccess,
            error: handoverError,
            isFetching: isHandoverFetching
        }
    ] = BillService.useLazyHandoverQuery()

    const [isSuccess, setIsSuccess] = useState(false)
    const [error, setError] = useState<
        FetchBaseQueryError | SerializedError | undefined
    >(undefined)
    const [isFetching, setIsFetching] = useState(false)

    const dispatch = useAppDispatch()

    const formik: FormikProps<Values> = useFormik<Values>({
        initialValues: {
            tectumId: '',
            vaultId: '',
            email: '',
            passcode: '',
            token: null
        },
        validationSchema: createValidationSchema(operation),
        validateOnChange,

        onSubmit: async (values: Values) => {
            if (operation === 'import') {
                importSoftnote({
                    serialNumber: data.serialNumber,
                    walletId: values.tectumId,
                    pinCode: values.passcode,
                    token: values.token
                })
            }

            if (operation === 'handover') {
                handoverSoftnote({
                    serialNumber: data.serialNumber,
                    email: values.email,
                    pinCode: values.passcode,
                    token: values.token
                })
            }
        }
    })

    useEffect(() => {
        if (operation === 'import') {
            setIsSuccess(isImportSuccess)
            setError(importError)
            setIsFetching(isImportFetching)
        }

        if (operation === 'handover') {
            setIsSuccess(isHandoverSuccess)
            setError(handoverError)
            setIsFetching(isHandoverFetching)
        }
    }, [
        isImportSuccess,
        importError,
        isImportFetching,
        isHandoverSuccess,
        handoverError,
        isHandoverFetching,
        operation
    ])

    useEffect(() => {
        setValidateOnChange(false)
        formik.resetForm()
        setIsSuccess(false)
        setError(undefined)
        setIsFetching(false)
        setRefreshReCaptcha(r => !r)
    }, [operation])

    const handleSubmit = () => {
        setValidateOnChange(true)
        formik.handleSubmit()
        setRefreshReCaptcha(r => !r)
    }

    const handleClose = () => {
        formik.resetForm()
        onClose()
    }

    const handleChangeTab = (tab: any) => {
        dispatch(sendModalActions.setOperation(tab))
    }

    const splitNum = (num: any) => {
        return `${num.substring(0, 5)}  ${num.substring(5, 8)}  ${num.substring(
            8,
            11
        )}  ${num.substring(11, 14)}  ${num.substring(14, 16)}`
    }

    const onChangeCaptcha = useCallback(
        (value: string | null) => {
            formik.setFieldValue('token', value)
        },
        [refreshReCaptcha]
    )

    return (
        <RequestFormComponent
            formik={formik}
            operation={operation}
            onSubmitForm={handleSubmit}
            onChangeTab={handleChangeTab}
            onClose={handleClose}
            isSuccess={isSuccess}
            data={data}
            splitNumber={splitNum}
            isLoading={isFetching}
            error={handleErrors(error)}
            onChangeCaptcha={onChangeCaptcha}
            refreshReCaptcha={refreshReCaptcha}
        />
    )
}

export default RequestForm
