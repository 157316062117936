import React, { useCallback, useEffect, useState } from 'react'
import AmountTabComponent from './component'
import { useAppDispatch, useAppSelector } from 'store/store'
import { FormikProps, useFormik } from 'formik'
import { Values } from './types'
import { fillActions } from 'store/fill/fillSlice'
import { FillSteps } from 'store/fill/fillTypes'
import { HubService } from 'services/Hub'
import handleErrors from 'utils/functions/handleErrors'
import { useTranslation } from 'react-i18next'

const AmountTab: React.FC = () => {
    const dispatch = useAppDispatch()

    const { t } = useTranslation('fill')

    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)

    const [separateSoftnotes, { data, error, isFetching }] =
        HubService.useLazySeparateGreenSoftnotesQuery()

    const selectedCurrency = useAppSelector(
        state => state.fill.selectedGreenCurrency
    )

    const handleClickBack = () => {
        copyFieldValuesToStore()
        dispatch(fillActions.reset())
    }

    const fillAmount = useAppSelector(state => state.fill.fillAmount)

    const copyFieldValuesToStore = () => {
        dispatch(fillActions.setFillAmount(Number(formik.values.fillAmount)))
    }

    const formik: FormikProps<Values> = useFormik<Values>({
        initialValues: {
            fillAmount: fillAmount ? String(fillAmount) : '',
            token: null
        },

        onSubmit: (values: Values) => {
            copyFieldValuesToStore()

            if (!selectedCurrency) {
                return
            }

            separateSoftnotes({
                amount: Number(values.fillAmount),
                currency: selectedCurrency.key,
                token: values.token
            })

            setRefreshReCaptcha(r => !r)
        }
    })

    const handleChangeCaptcha = useCallback(
        (value: string | null) => {
            formik.setFieldValue('token', value)
        },
        [refreshReCaptcha]
    )

    useEffect(() => {
        if (!data) {
            return
        }

        if (data.closestNumber) {
            setClosestAmount(data.closestNumber)
            formik.setFieldError('fillAmount', t('notEnoughNominals'))
            return
        }

        const nominals = data.bills.reduce(
            (nominals, { count, nominal }) => {
                nominals[nominal] = count
                return nominals
            },
            {} as Record<number, number>
        )
        dispatch(fillActions.setSelectedGreenNominals(nominals))
        dispatch(fillActions.setStepGreen(FillSteps.enterVault))
    }, [data, dispatch, error])

    const [closestAmount, setClosestAmount] = useState<number | null>(null)
    const showClosestAmountField = closestAmount !== 0

    const handleClickClosestAmountButton = () => {
        formik.setFieldValue('fillAmount', closestAmount)
        formik.setFieldError('fillAmount', '')
        setClosestAmount(null)
    }

    const nominalTab = useAppSelector(state => state.fill.nominalTab)

    const handleChangeNominalTab = (tab: string) => {
        dispatch(fillActions.setNominalTab(tab))
    }

    const handleChangeFillAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue(
            'fillAmount',
            e.target.value.replace(/[^\d.]/g, '')
        )
        setClosestAmount(null)
    }

    return (
        <AmountTabComponent
            nominalTab={nominalTab}
            onChangeNominalTab={handleChangeNominalTab}
            onClickBack={handleClickBack}
            selectedCurrency={selectedCurrency}
            formik={formik}
            closestAmount={closestAmount}
            onClickClosestAmountButton={handleClickClosestAmountButton}
            error={handleErrors(error)}
            isFetching={isFetching}
            onChangeCaptcha={handleChangeCaptcha}
            refreshReCaptcha={refreshReCaptcha}
            onChangeFillAmount={handleChangeFillAmount}
        />
    )
}

export default AmountTab
