import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { AcceptState, AcceptSteps } from './acceptTypes'
import { BanknoteResponse } from 'services/Hub/types'

const initialState: AcceptState = {
    step: AcceptSteps.request,
    banknotes: null
}

const acceptSlice = createSlice({
    name: 'accept',
    initialState,
    reducers: {
        setStep: (state, { payload }: PayloadAction<AcceptSteps>) => {
            state.step = payload
        },
        setBanknotes: (
            state,
            { payload }: PayloadAction<BanknoteResponse[] | null>
        ) => {
            state.banknotes = payload
        },
        reset: () => initialState
    }
})

export const { actions: acceptActions } = acceptSlice

export default acceptSlice
