export interface LanguageOption {
    value: string
    text: string
}

export const supportedLanguages: LanguageOption[] = [
    { value: 'en', text: 'English' },
    { value: 'zh', text: '中文' },
    { value: 'tr', text: 'Türkçe' }
]
