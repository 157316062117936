import React, { useEffect } from 'react'
import { fillActions } from 'store/fill/fillSlice'
import { FillSteps } from 'store/fill/fillTypes'
import { useAppDispatch, useAppSelector } from 'store/store'
import PoolTabComponent from './component'
import { HubService } from 'services/Hub'
import { SoftnoteColor } from 'models/Softnote'
import handleErrors from 'utils/functions/handleErrors'
import { useSoftnoteLimits } from 'hooks/useSoftnoteLimits'

const PoolTab: React.FC = () => {
    const dispatch = useAppDispatch()

    const { maxFillGreenBanknotes } = useSoftnoteLimits()

    const selectedCurrency = useAppSelector(
        state => state.fill.selectedGreenCurrency
    )

    const copyFieldValuesToStore = () => {
        dispatch(fillActions.setFillAmount(totalAmount))
    }

    const handleClickBack = () => {
        copyFieldValuesToStore()
        dispatch(fillActions.reset())
    }

    const handleClickNext = () => {
        copyFieldValuesToStore()
        dispatch(fillActions.setStepGreen(FillSteps.enterVault))
    }

    const [fetchNominals, { data, error, isLoading }] =
        HubService.useLazyGetNominalsQuery()

    useEffect(() => {
        if (!selectedCurrency) {
            return
        }

        fetchNominals({
            color: SoftnoteColor.Green,
            currencyKey: selectedCurrency.key
        })
    }, [fetchNominals, selectedCurrency])

    const nominals = data?.nominals ?? []
    const sortedNominals = [...nominals].sort((a, b) => a - b)

    const selectedNominals = useAppSelector(
        state => state.fill.selectedGreenNominals
    )

    const nominalTab = useAppSelector(state => state.fill.nominalTab)

    const handleChangeNominalTab = (tab: string) => {
        dispatch(fillActions.setNominalTab(tab))
    }

    const totalBills = Object.values(selectedNominals).reduce(
        (sum, current) => sum + current,
        0
    )

    const totalAmount = Object.keys(selectedNominals).reduce((sum, nominal) => {
        const billCount = selectedNominals[Number(nominal)]
        return sum + Number(nominal) * billCount
    }, 0)

    const handleChangeNominalCount = (nominal: number, count: number) => {
        if (count < 0) {
            return
        }

        let updatedNominals = { ...selectedNominals }
        if (count === 0) {
            delete updatedNominals[nominal]
        } else {
            updatedNominals[nominal] = count
        }

        dispatch(fillActions.setSelectedGreenNominals(updatedNominals))
    }

    return (
        <PoolTabComponent
            nominalTab={nominalTab}
            onChangeNominalTab={handleChangeNominalTab}
            onClickBack={handleClickBack}
            selectedCurrency={selectedCurrency}
            nominals={sortedNominals}
            onChangeNominalCount={handleChangeNominalCount}
            selectedNominals={selectedNominals}
            totalBills={totalBills}
            totalAmount={totalAmount}
            onNextClick={handleClickNext}
            error={handleErrors(error)}
            isLoading={isLoading}
            maxBanknotes={maxFillGreenBanknotes}
        />
    )
}

export default PoolTab
