import IconLogo from 'assets/icons/logoSoftnote.svg'
import styles from './component.module.scss'
import LanguageSelection from 'components/shared/LanguageSelection'
import HelpButton from 'components/shared/Help'

interface Props {}

const HeaderComponent: React.FC<Props> = (props: Props): React.ReactElement => {
    return (
        <header className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.logo}>
                    <img src={IconLogo} alt='CashLogo' />
                </div>

                <HelpButton />

                <LanguageSelection />
            </div>
        </header>
    )
}
export default HeaderComponent
