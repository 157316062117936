import SectionHeader from 'components/shared/SectionHeader'
import AmountPollTab from 'pages/FillGreen/components/AmountPollTab'
import Softnote from 'pages/FillGreen/components/Softnote'
import { Currency } from 'store/fill/fillTypes'
import styles from '../../styles.module.scss'
import { Alert, Button } from '@crispmindltd/client-react-package'
import { getCurrencyLabel } from 'utils/functions/currency'
import { formatNumber } from 'utils/functions/format'
import { useTranslation } from 'react-i18next'
import Loader from 'components/shared/Loader'

interface Props {
    selectedCurrency: Currency | null
    onClickBack: () => void
    nominalTab: string
    onChangeNominalTab: (tab: string) => void
    nominals: number[]
    onChangeNominalCount: (nominal: number, count: number) => void
    selectedNominals: Record<number, number>
    totalBills: number
    totalAmount: number
    onNextClick: () => void
    error: string
    isLoading: boolean
    maxBanknotes: number
}

const PoolTabComponent: React.FC<Props> = props => {
    const {
        selectedCurrency,
        onClickBack,
        nominalTab,
        onChangeNominalTab,
        nominals,
        onChangeNominalCount,
        selectedNominals,
        totalBills,
        totalAmount,
        onNextClick,
        error,
        isLoading,
        maxBanknotes
    } = props

    const { t } = useTranslation(['common', 'fill'])

    if (!selectedCurrency) {
        return null
    }

    return (
        <>
            <SectionHeader
                title={`${t('labels.fill')} ${getCurrencyLabel(
                    selectedCurrency
                )}`}
                subTitle={t('greenHub', { ns: 'fill' })}
                subTitleColor='#00E979'
                onClickBack={onClickBack}
            />

            <AmountPollTab tab={nominalTab} onChangeTab={onChangeNominalTab} />

            {error && (
                <Alert
                    type='error'
                    text={error}
                    icon
                    className={styles.alert}
                />
            )}

            {isLoading && <Loader />}

            <div className={styles.nominals}>
                {nominals.map(nominal => (
                    <Softnote
                        key={nominal}
                        nominal={nominal}
                        currency={selectedCurrency}
                        count={selectedNominals[nominal] ?? 0}
                        onChangeCount={onChangeNominalCount}
                        totalBills={totalBills}
                        maxBanknotes={maxBanknotes}
                    />
                ))}
            </div>

            <div className={styles.sofnotesSummaryWrapper}>
                <div className={styles.sofnotesSummary}>
                    <div className={styles.sofnotesSummaryRow}>
                        <span className={styles.sofnotesSummaryLabel}>
                            {t('totalAmount', { ns: 'fill' })}:
                        </span>
                        <span className={styles.sofnotesSummaryValue}>
                            {formatNumber(
                                totalAmount,
                                selectedCurrency.decimal
                            )}{' '}
                            {selectedCurrency.ticker}
                        </span>
                    </div>

                    <div className={styles.sofnotesSummaryRow}>
                        <span className={styles.sofnotesSummaryLabel}>
                            {t('bills', { ns: 'fill' })}:
                        </span>
                        <span className={styles.sofnotesSummaryValue}>
                            {totalBills} {t('bills', { ns: 'fill' })}
                        </span>
                    </div>
                </div>

                <Button
                    className={styles.nextButton}
                    disabled={!totalBills}
                    onClick={onNextClick}
                >
                    {t('buttons.next')}
                </Button>
            </div>
        </>
    )
}

export default PoolTabComponent
