import {
    Alert,
    Button,
    CopyButton,
    ModalBody,
    ModalFooter,
    TextField
} from '@crispmindltd/client-react-package'
import styles from '../styles.module.scss'
import { useTranslation } from 'react-i18next'
import { useFillStatuses } from 'hooks/useFillStatuses'
import { FillStatus } from 'services/Hub/types'
import { Currency, Network } from 'store/fill/fillTypes'
import { exponentialToDecimal } from 'utils/functions/renderBillsFunctions'

interface Props {
    nominal: number
    onClickDone: () => void
    address: string
    senderAddress: string
    hashTransaction: string
    status: FillStatus
    currency: Currency
    isBillGreen: boolean
    selectedNetwork: Network | null
}

const ResultFormComponent: React.FC<Props> = (props: Props) => {
    const {
        nominal,
        onClickDone,
        address,
        senderAddress,
        hashTransaction,
        status,
        currency,
        isBillGreen,
        selectedNetwork
    } = props

    const { t } = useTranslation('common')
    const statuses = useFillStatuses()

    return (
        <>
            <ModalBody className={styles.content}>
                <Alert
                    type={statuses[status].alertType}
                    text={`${statuses[status].alertText}${
                        status === FillStatus.Pending
                            ? `. ${t('linkHasBeenSent', { ns: 'fill' })}`
                            : ''
                    }`}
                    icon
                    disableAnimation
                    className={styles.alert}
                />

                <div className={styles.amountLabel}>{t('labels.amount')}</div>
                <div className={styles.amountValue}>
                    {nominal} {currency.ticker}{' '}
                    <CopyButton
                        className={styles.copyAmount}
                        value={exponentialToDecimal(nominal)}
                    />
                </div>

                <div className={styles.fieldsWrapper}>
                    {isBillGreen && selectedNetwork && (
                        <TextField
                            label={t('labels.network')}
                            value={`${selectedNetwork.network} (${selectedNetwork.protocol})`}
                            readOnly
                            className={styles.network}
                        />
                    )}

                    <TextField
                        name='address'
                        label={t('labels.address')}
                        value={address}
                        readOnly
                        withCopy
                    />

                    <TextField
                        name='senderAddress'
                        label={t('labels.senderAddress')}
                        value={senderAddress}
                        readOnly
                        withCopy
                    />

                    <TextField
                        name='hashTransaction'
                        label={t('labels.hashTransaction')}
                        value={hashTransaction}
                        readOnly
                        withCopy
                    />
                </div>
            </ModalBody>

            {status !== FillStatus.Pending && (
                <ModalFooter>
                    <Button className={styles.nextButton} onClick={onClickDone}>
                        {t('buttons.done')}
                    </Button>
                </ModalFooter>
            )}
        </>
    )
}

export default ResultFormComponent
