import { Button, Select } from '@crispmindltd/client-react-package'
import { AppRoutes } from 'routes/AppRoutes'
import SectionHeader from 'components/shared/SectionHeader'
import { Currency } from 'store/fill/fillTypes'
import styles from '../styles.module.scss'
import { OptionModel } from '@crispmindltd/client-react-package/dist/components/Select/types'
import { useTranslation } from 'react-i18next'

interface Props {
    onClickBack: () => void
    selectOptions: OptionModel[] | undefined
    selectedCurrency: Currency | null
    onSelectCurrency: (currency: OptionModel) => void
    onClickNext: () => void
    error: string
    isLoading: boolean
}

const SelectCurrencyStepComponent: React.FC<Props> = props => {
    const {
        onClickBack,
        selectOptions,
        onSelectCurrency,
        selectedCurrency,
        onClickNext,
        error,
        isLoading
    } = props

    const { t } = useTranslation(['common', 'fill'])

    return (
        <>
            <SectionHeader title={t('labels.fill')} onClickBack={onClickBack} />
            <div className={styles.tab}>
                <Button type='primary'>{t('blueHub', { ns: 'fill' })}</Button>
                <Button type='secondary' href={AppRoutes.fillGreen}>
                    {t('greenHub', { ns: 'fill' })}
                </Button>
            </div>

            <Select
                className={styles.select}
                options={selectOptions}
                label={t('labels.currency')}
                selected={selectedCurrency?.key}
                onChange={onSelectCurrency}
                error={error}
                isLoading={isLoading}
            />

            <div className={styles.bottom}>
                <Button
                    className={styles.nextButton}
                    disabled={!selectedCurrency}
                    onClick={onClickNext}
                >
                    {t('buttons.next')}
                </Button>
            </div>
        </>
    )
}

export default SelectCurrencyStepComponent
