import { useTranslation } from 'react-i18next'
import BurnFormBlue from './BurnFormBlue'
import BurnFormGreen from './BurnFormGreen'
import { ModalWrapper } from '@crispmindltd/client-react-package'

interface Props {
    isOpen: boolean
    onClose: () => void
    data: any
}

const BurnModal: React.FC<Props> = (props: Props) => {
    const { isOpen, onClose, data } = props

    const { t } = useTranslation('common')

    return (
        <ModalWrapper open={isOpen} onClose={onClose} title={t('labels.burn')}>
            {data.color === 'Blue' && (
                <BurnFormBlue data={data} onClose={onClose} />
            )}
            {data.color === 'Green' && (
                <BurnFormGreen data={data} onClose={onClose} />
            )}
        </ModalWrapper>
    )
}

export default BurnModal
