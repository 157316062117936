import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
    SendModalOperations,
    SendModalState,
    SendModalSteps
} from './sendModalTypes'

const initialState: SendModalState = {
    step: SendModalSteps.request,
    operation: SendModalOperations.import
}

const sendModalSlice = createSlice({
    name: 'sendModal',
    initialState,
    reducers: {
        setStep: (state, { payload }: PayloadAction<SendModalSteps>) => {
            state.step = payload
        },
        setOperation: (
            state,
            { payload }: PayloadAction<SendModalOperations>
        ) => {
            state.operation = payload
        }
    }
})

export const { actions: sendModalActions } = sendModalSlice

export default sendModalSlice
