import React from 'react'
import BillInfo from './BillInfo'
import styles from './styles.module.scss'
import { formatNumber } from 'utils/functions/format'
import { Nominal } from '../types'
import { BurnPrepareGreenResponse } from 'services/Hub/types'
import { Currency } from 'store/fill/fillTypes'
import { useTranslation } from 'react-i18next'

interface Props {
    info: BurnPrepareGreenResponse
    currency: Currency | undefined
}

const BillsInfo: React.FC<Props> = (props: Props): React.ReactElement => {
    const { info, currency } = props

    const { t } = useTranslation(['common', 'burn'])

    const nominals = info?.banknotes.reduce((acc: Nominal[], curr) => {
        const index = acc.findIndex(item => item.nominal === curr.nominal)
        if (index !== -1) {
            if (curr.nominal) {
                acc[index].bills += 1
                acc[index].amount += curr.nominal
            }
        } else {
            if (curr.nominal) {
                acc.push({
                    nominal: curr.nominal,
                    bills: 1,
                    amount: curr.nominal
                })
            }
        }
        return acc
    }, [])

    const [totalBills, totalAmount] = nominals?.reduce(
        (acc, curr) => {
            if (curr.bills) {
                acc[0] += curr.bills
            }
            if (curr.amount) {
                acc[1] += curr.amount
            }
            return acc
        },
        [0, 0]
    ) ?? [0, 0]

    return (
        <div className={styles.wrapper}>
            {nominals && currency && (
                <>
                    {nominals.map(el => (
                        <BillInfo
                            nominal={el.nominal}
                            currency={currency}
                            bills={el.bills}
                            amount={el.amount}
                            key={el.nominal}
                        />
                    ))}

                    <div className={styles.totalWrapper}>
                        <div className={styles.info}>
                            <span className={styles.counter}>
                                {t('totalBills', { ns: 'burn' })}
                            </span>
                            <span className={styles.nominal}>
                                {totalBills} {t('labels.bills')}
                            </span>
                        </div>

                        <div className={styles.info}>
                            <span className={styles.counterL}>
                                {t('totalAmount', { ns: 'burn' })}
                            </span>
                            <span className={styles.nominalL}>
                                {formatNumber(totalAmount, currency.decimal)}{' '}
                                {currency.ticker}
                            </span>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default React.memo(BillsInfo)
