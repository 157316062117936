import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { DepositSteps, DepositState } from './depositTypes'

const initialState: DepositState = {
    step: DepositSteps.request
}

const depositSlice = createSlice({
    name: 'deposit',
    initialState,
    reducers: {
        setStep: (state, { payload }: PayloadAction<DepositSteps>) => {
            state.step = payload
        }
    }
})

export const { actions: depositActions } = depositSlice

export default depositSlice
