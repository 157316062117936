import { Button } from '@crispmindltd/client-react-package'
import styles from './styles.module.scss'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

interface Props {
    currentTab: string
    onChangeTab: (tab: string) => void
    className?: string
}

const VaultEmailTab: React.FC<Props> = props => {
    const { currentTab, onChangeTab, className } = props

    const { t } = useTranslation('common')

    return (
        <div className={classNames(styles.tab, className)}>
            <Button
                type={currentTab === 'vault' ? 'primary' : 'secondary'}
                className={classNames(styles.tabButton, {
                    [styles.active]: currentTab === 'vault'
                })}
                onClick={() => onChangeTab('vault')}
                disabled
            >
                {t('labels.vaultID')}
            </Button>
            <Button
                type={currentTab === 'email' ? 'primary' : 'secondary'}
                className={classNames(styles.tabButton, {
                    [styles.active]: currentTab === 'email'
                })}
                onClick={() => onChangeTab('email')}
            >
                {t('labels.email')}
            </Button>
        </div>
    )
}

export default VaultEmailTab
