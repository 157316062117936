import classNames from 'classnames'
import styles from './styles.module.scss'

interface Props {
    className?: string
}

const Loader: React.FC<Props> = props => {
    const { className } = props

    return (
        <div className={classNames(styles.wrapper, className)}>
            <div className={styles.loader}>
                <div className={styles.square}></div>
                <div className={styles.square}></div>
                <div className={styles.square}></div>
                <div className={styles.square}></div>
            </div>
        </div>
    )
}

export default Loader
