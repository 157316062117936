import SectionHeader from 'components/shared/SectionHeader'
import styles from '../styles.module.scss'
import { Alert, Button, TextArea } from '@crispmindltd/client-react-package'
import { FormikConsumer, FormikProps } from 'formik'
import { Values } from './types'
import { AppRoutes } from 'routes/AppRoutes'
import Checkbox from 'components/shared/Checkbox'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useSoftnoteLimits } from 'hooks/useSoftnoteLimits'
import { useTranslation } from 'react-i18next'
import ExampleDataFormat from 'components/shared/ExampleDataFormat'

interface Props {
    formik: FormikProps<Values>
    handleChangeSoftnotes: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
    counter: number
    onClickBack: () => void
    fieldError: string
    error: string
    isLoading: boolean
    onSubmit: () => void
    checkbox: boolean
    toggleCheckbox: () => void
    onChangeCaptcha: (value: string | null) => void
    refreshReCaptcha: boolean
}

const BurnBlueRequestComponent: React.FC<Props> = (
    props
): React.ReactElement => {
    const {
        formik,
        handleChangeSoftnotes,
        counter,
        onClickBack,
        fieldError,
        error,
        isLoading,
        onSubmit,
        checkbox,
        toggleCheckbox,
        onChangeCaptcha,
        refreshReCaptcha
    } = props

    const { maxBurnBanknotes } = useSoftnoteLimits()

    const { t } = useTranslation(['common', 'burn'])

    return (
        <>
            <SectionHeader onClickBack={onClickBack} title={t('labels.burn')} />

            <div className={styles.wrapper}>
                <div className={styles.tabs}>
                    <Button>{t('blueHub', { ns: 'burn' })}</Button>

                    <Button type='secondary' href={AppRoutes.burnGreen}>
                        {t('greenHub', { ns: 'burn' })}
                    </Button>
                </div>

                {error && <Alert type='error' text={error} icon />}

                <TextArea
                    error={fieldError || formik.errors.softnotes}
                    className={styles.textArea}
                    placeholder={t('placeholders.softNoteSerialPasscode')}
                    onChange={handleChangeSoftnotes}
                    label={
                        <div className={styles.textAreaLabel}>
                            <div className={styles.title}>
                                {t('labels.softnotes')}
                            </div>
                            <div
                                className={
                                    counter <= maxBurnBanknotes
                                        ? styles.counter
                                        : styles.counterError
                                }
                            >
                                {t('billsCount', {
                                    counter: counter,
                                    maxBurnBanknotes: maxBurnBanknotes,
                                    ns: 'burn'
                                })}
                            </div>
                        </div>
                    }
                    value={formik.values.softnotes}
                    name='softnote'
                />
            </div>

            <ExampleDataFormat />

            <div className={styles.bottom}>
                <div className={styles.checkField}>
                    <span>{t('afterBurn', { ns: 'burn' })}</span>
                </div>

                <div className={styles.checkField}>
                    <div>
                        <Checkbox
                            checked={checkbox}
                            onClick={toggleCheckbox}
                            className={styles.checkbox}
                        />
                    </div>

                    <span>{t('stableConnection', { ns: 'burn' })}</span>
                </div>

                <Button
                    className={styles.btn}
                    onClick={onSubmit}
                    isLoading={isLoading}
                    disabled={!checkbox || !formik.values.softnotes}
                >
                    {t('buttons.finish')}
                </Button>

                <GoogleReCaptcha
                    refreshReCaptcha={refreshReCaptcha}
                    onVerify={onChangeCaptcha}
                />
            </div>
        </>
    )
}

export default BurnBlueRequestComponent
