import {
    Alert,
    Button,
    ModalBody,
    ModalFooter,
    TextField
} from '@crispmindltd/client-react-package'
import { FormikProps } from 'formik'
import { Values } from './types'
import styles from '../styles.module.scss'
import Checkbox from 'components/shared/Checkbox'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useTranslation } from 'react-i18next'
import { FetchBillResponse } from 'services/Bill/types'

interface Props {
    formik: FormikProps<Values>
    onSubmitForm: () => void
    onClose: () => void
    isSuccess: boolean
    data: FetchBillResponse
    isLoading: boolean
    error: string
    successAlertText: React.ReactElement
    checkbox: boolean
    toggleCheckbox: () => void
    onChangeCaptcha: (value: string | null) => void
    refreshReCaptcha: boolean
    downloadLink: JSX.Element | undefined
}

const BurnFormBlueComponent: React.FC<Props> = (props: Props) => {
    const {
        formik,
        onSubmitForm,
        isSuccess,
        onClose,
        data,
        isLoading,
        error,
        successAlertText,
        downloadLink,
        checkbox,
        toggleCheckbox,
        onChangeCaptcha,
        refreshReCaptcha
    } = props

    const { t } = useTranslation(['common', 'burn'])

    return (
        <>
            <ModalBody className={styles.content}>
                {!isSuccess && (
                    <Alert
                        type='warning'
                        icon
                        text='Attention'
                        disableAnimation
                        description={
                            <>
                                <span>
                                    After you burn the bill in Blue mode, you
                                    will receive a zip archives containing
                                    private keys. Passcode for the zip-archive
                                    to access the private key of a certain
                                    SoftNote is the passcode for that given
                                    SoftNote. After burning you will be able to
                                    manage your funds independently and deposit
                                    them to any suitable crypto wallet.
                                </span>
                                <br />
                                <br />
                                <span>
                                    The bill will get a new status and can be
                                    filled with liquidity again.
                                </span>
                            </>
                        }
                        className={styles.alert}
                    />
                )}

                {isSuccess && (
                    <Alert
                        className={styles.alert}
                        type='success'
                        text='SoftNote burned with Success'
                        description={
                            <>
                                <span>
                                    Zip archives containing private keys of
                                    burned SoftNotes will be downloaded
                                    automatically.
                                </span>
                                <br />
                                <br />
                                <span>
                                    If the download has not started,{' '}
                                    {downloadLink}
                                </span>
                                <br />
                                <br />
                                <span>
                                    Passcode for the zip-archive to access the
                                    private key of a certain SoftNote is the
                                    passcode for that given SoftNote.
                                </span>
                            </>
                        }
                        icon
                        disableAnimation
                    />
                )}

                {error && (
                    <Alert
                        style={{ marginBottom: '24px' }}
                        type='error'
                        text={error}
                        icon
                    />
                )}

                {!isSuccess && (
                    <>
                        <div className={styles.amountLabel}>
                            {t('labels.nominal')}
                        </div>
                        <div className={styles.amountValue}>
                            {data.nominal} {data.currencyInfo.ticker}
                        </div>{' '}
                    </>
                )}

                {!isSuccess && (
                    <div className={styles.fieldsWrapper}>
                        <div className={styles.passcodeField}>
                            <TextField
                                name='passcode'
                                value={formik.values.passcode}
                                onChange={formik.handleChange}
                                handleClear={() =>
                                    formik.setFieldValue('passcode', '')
                                }
                                label={
                                    <div className={styles.inputLabel}>
                                        {t('labels.passcode')}
                                    </div>
                                }
                                error={formik.errors.passcode}
                            />
                        </div>
                    </div>
                )}
            </ModalBody>

            <ModalFooter className={styles.footer}>
                {isSuccess ? (
                    <Button className={styles.nextButton} onClick={onClose}>
                        {t('buttons.done')}
                    </Button>
                ) : (
                    <>
                        {/* <div className={styles.checkField}>
                            <span>{t('afterBurn', { ns: 'burn' })}</span>
                        </div> */}

                        <div className={styles.checkField}>
                            <div>
                                <Checkbox
                                    checked={checkbox}
                                    onClick={toggleCheckbox}
                                    className={styles.checkbox}
                                />
                            </div>

                            <span>{t('stableConnection', { ns: 'burn' })}</span>
                        </div>

                        <Button
                            className={styles.nextButton}
                            disabled={!formik.values.passcode || !checkbox}
                            onClick={onSubmitForm}
                            isLoading={isLoading}
                        >
                            {t('buttons.finish')}
                        </Button>

                        <GoogleReCaptcha
                            refreshReCaptcha={refreshReCaptcha}
                            onVerify={onChangeCaptcha}
                        />
                    </>
                )}
            </ModalFooter>
        </>
    )
}

export default BurnFormBlueComponent
