import { useAppDispatch, useAppSelector } from 'store/store'
import EnterAddressStepComponent from './component'
import { fillActions } from 'store/fill/fillSlice'
import { FillSteps } from 'store/fill/fillTypes'
import { useMediaQuery } from 'hooks/useMediaQuery'
import { MEDIA_QUERY_MOBILE } from 'utils/constants/mediaQueryConstants'
import { FormikProps, useFormik } from 'formik'
import { Values } from './types'
import validationSchema from './validationSchema'
import { useEffect, useState } from 'react'
import { HubService } from 'services/Hub'
import handleErrors from 'utils/functions/handleErrors'
import { OptionModel } from '@crispmindltd/client-react-package/dist/components/Select/types'

const EnterAddressStep: React.FC = () => {
    const dispatch = useAppDispatch()

    const selectedCurrency = useAppSelector(
        state => state.fill.selectedGreenCurrency
    )

    const fillAmount = useAppSelector(state => state.fill.fillAmount)

    const fillId = useAppSelector(state => state.fill.fillId)
    const senderAddress = useAppSelector(state => state.fill.senderAddress)
    const hashTransaction = useAppSelector(state => state.fill.hashTransaction)
    const selectedNetwork = useAppSelector(state => state.fill.selectedNetwork)

    const copyFieldValuesToStore = () => {
        dispatch(fillActions.setSenderAddress(formik.values.senderAddress))
        dispatch(fillActions.setHashTransaction(formik.values.hashTransaction))
    }

    const [validateOnChange, setValidateOnChange] = useState<boolean>(false)

    const [fillAccept, { data, error, isFetching }] =
        HubService.useLazyFillGreenAcceptQuery()

    const formik: FormikProps<Values> = useFormik<Values>({
        initialValues: { senderAddress, hashTransaction },
        validationSchema,
        validateOnChange,

        onSubmit: async (values: Values) => {
            copyFieldValuesToStore()

            if (!selectedCurrency || !selectedNetwork) {
                return
            }

            fillAccept({
                id: fillId,
                addressSender: formik.values.senderAddress,
                hash: formik.values.hashTransaction,
                protocol: selectedCurrency.protocol,
                ticker: selectedCurrency.ticker,
                subProtocol: selectedNetwork.protocol,
                subNetwork: selectedNetwork.network
            })
        }
    })

    useEffect(() => {
        if (data) {
            dispatch(fillActions.setStepGreen(FillSteps.result))
        }
    }, [data, dispatch])

    const handleSubmit = () => {
        setValidateOnChange(true)
        formik.handleSubmit()
    }

    const handleClickBack = () => {
        copyFieldValuesToStore()
        dispatch(fillActions.setStepGreen(FillSteps.enterVault))
    }

    const isMobile = useMediaQuery(MEDIA_QUERY_MOBILE)

    const {
        data: masterAccountData,
        error: networkError,
        isFetching: isNetworkLoading
    } = HubService.useGetMasterAccountQuery(
        {
            currencyKey: selectedCurrency?.key ?? ''
        },
        { skip: !selectedCurrency }
    )
    const availableNetworksData = masterAccountData?.account.supportProtocols

    const availableNetworks = availableNetworksData?.map(protocol => ({
        value: protocol.address,
        text: `${protocol.network} (${protocol.protocol})`
    }))

    useEffect(() => {
        if (availableNetworksData?.length) {
            dispatch(fillActions.setSelectedNetwork(availableNetworksData[0]))
        }
    }, [availableNetworksData])

    const handleChangeNetwork = (value: OptionModel) => {
        const selectedNetwork = availableNetworksData?.find(
            network => network.address === value.value
        )

        selectedNetwork &&
            dispatch(fillActions.setSelectedNetwork(selectedNetwork))
    }

    const selectedNetworkOption = availableNetworks?.find(
        network => network.value === selectedNetwork?.address
    )

    return (
        <EnterAddressStepComponent
            selectedCurrency={selectedCurrency}
            onClickBack={handleClickBack}
            fillAmount={fillAmount}
            onSubmitForm={handleSubmit}
            isMobile={isMobile}
            selectedNetworkOption={selectedNetworkOption}
            networks={availableNetworks}
            address={selectedNetwork?.address ?? ''}
            onChangeNetwork={handleChangeNetwork}
            formik={formik}
            error={handleErrors(error)}
            isFetching={isFetching}
            networkError={handleErrors(networkError)}
            isNetworkLoading={isNetworkLoading}
        />
    )
}

export default EnterAddressStep
