import classnames from 'classnames'
import { Link } from 'react-router-dom'
import styles from './styles.module.scss'

interface Props {
    label: React.ReactNode
    link: string
    disabled?: boolean
    icon?: JSX.Element | string
    className?: string
}

const ActionLink: React.FC<Props> = (props: Props): React.ReactElement => {
    const { label, link, disabled, icon, className } = props

    return (
        <Link
            to={link}
            className={classnames(styles.button, className, {
                [styles.disabled]: disabled
            })}
        >
            {icon && <div className={styles.icon}>{icon}</div>}

            <p className={styles.label}>{label}</p>
        </Link>
    )
}
export default ActionLink
