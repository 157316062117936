import { useAppDispatch, useAppSelector } from 'store/store'
import { acceptActions } from 'store/accept/acceptSlice'
import AcceptResultComponent from './component'
import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router'
import { AppRoutes } from 'routes/AppRoutes'
import { AlertData } from './types'
import { createDownloadUrlForTxt } from 'utils/functions/createDownloadUrlForTxt'
import styles from '../styles.module.scss'
import { hubActions } from 'store/hub/hubSlice'
import { useTranslation } from 'react-i18next'

const AcceptResult: React.FC = (): React.ReactElement => {
    const dispatch = useAppDispatch()

    const navigate = useNavigate()

    const banknotes = useAppSelector(state => state.accept.banknotes)

    const banknotesWithSuccess = banknotes?.filter(el => el.errorCode === null)

    const banknotesWithError = banknotes?.filter(el => el.errorCode !== null)

    const { t } = useTranslation('accept')

    const linkRef = useRef<HTMLAnchorElement | null>(null)

    const downloadLink = banknotesWithSuccess && (
        <a
            ref={linkRef}
            href={createDownloadUrlForTxt(
                banknotesWithSuccess
                    .map(el => `${el.serialNumber}/${el.pinCode}`)
                    .join('\n')
            )}
            className={styles.link}
            download={`${t('accept')}-${Date.now()}.txt`}
        >
            {t('clickHere')}
        </a>
    )

    useEffect(() => {
        if (banknotesWithSuccess && linkRef.current) {
            linkRef.current.click()
        }
    }, [banknotesWithSuccess])

    const getAlertData = (): AlertData => {
        if (banknotesWithSuccess && banknotesWithError) {
            if (
                banknotesWithSuccess.length === 0 &&
                banknotesWithError.length > 0
            ) {
                return {
                    type: 'error',
                    text: t('notAccepted')
                }
            }
            if (
                banknotesWithSuccess.length > 0 &&
                banknotesWithError.length > 0
            ) {
                return {
                    type: 'warning',
                    text: (
                        <>
                            {t('halfAccepted')}
                            <br />
                            <br />
                            {t('filesWillBeDownloaded')}
                            <br />
                            <br />
                            {t('ifNotStarted')} {downloadLink}
                        </>
                    )
                }
            }
            if (
                banknotesWithSuccess.length > 0 &&
                banknotesWithError.length === 0
            ) {
                return {
                    type: 'success',
                    text: (
                        <>
                            {t('success')}
                            <br />
                            <br />
                            {t('filesWillBeDownloaded')}
                            <br />
                            <br />
                            {t('ifNotStarted')} {downloadLink}
                        </>
                    )
                }
            }
        }

        return { type: 'error', text: t('error') }
    }

    const handleClickDone = () => {
        dispatch(acceptActions.reset())
        dispatch(hubActions.setOperation(null))
        navigate(AppRoutes.hub)
    }

    const handleClickBack = () => {
        dispatch(acceptActions.reset())
    }

    return (
        <AcceptResultComponent
            onClickDone={handleClickDone}
            onClickBack={handleClickBack}
            alertData={getAlertData()}
            banknotesWithSuccess={banknotesWithSuccess}
            banknotesWithError={banknotesWithError}
        />
    )
}

export default AcceptResult
