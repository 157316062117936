import SectionHeader from 'components/shared/SectionHeader'
import { Currency } from 'store/fill/fillTypes'
import styles from '../styles.module.scss'
import { Alert, Button, TextField } from '@crispmindltd/client-react-package'
import { FormikProps } from 'formik'
import { Values } from './types'
import VaultEmailTab from 'components/shared/VaultEmailTab'
import { getCurrencyLabel } from 'utils/functions/currency'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { formatNumber } from 'utils/functions/format'
import { useTranslation } from 'react-i18next'

interface Props {
    selectedCurrency: Currency | null
    onClickBack: () => void
    fillAmount: number
    formik: FormikProps<Values>
    onSubmitForm: () => void
    vaultTab: string
    onChangeVaultTab: (value: string) => void
    error: string
    isFetching: boolean
    onChangeCaptcha: (value: string | null) => void
    refreshReCaptcha: boolean
}

const EnterVaultStepComponent: React.FC<Props> = props => {
    const {
        selectedCurrency,
        onClickBack,
        formik,
        onSubmitForm,
        vaultTab,
        onChangeVaultTab,
        fillAmount,
        error,
        isFetching,
        onChangeCaptcha,
        refreshReCaptcha
    } = props

    const { t } = useTranslation(['common', 'fill'])

    return (
        <>
            <SectionHeader
                title={`${t('labels.fill')} ${getCurrencyLabel(
                    selectedCurrency
                )}`}
                subTitle={t('greenHub', { ns: 'fill' })}
                subTitleColor='#00E979'
                onClickBack={onClickBack}
            />

            <div className={styles.amountLabel}>{t('labels.amount')}</div>
            <div className={styles.amountValue}>
                {formatNumber(fillAmount, selectedCurrency?.decimal ?? 8)}{' '}
                {selectedCurrency?.ticker}
            </div>

            <TextField
                name='tectumId'
                value={formik.values.tectumId}
                onChange={e =>
                    formik.setFieldValue(
                        'tectumId',
                        e.target.value.replace(/\D/g, '')
                    )
                }
                handleClear={() => formik.setFieldValue('tectumId', '')}
                label={t('beneficiaryTectumID', { ns: 'fill' })}
                error={formik.errors.tectumId}
            />

            <VaultEmailTab
                currentTab={vaultTab}
                onChangeTab={onChangeVaultTab}
                className={styles.vaultTab}
            />

            {vaultTab === 'vault' && (
                <TextField
                    name='vaultId'
                    value={formik.values.vaultId}
                    onChange={e =>
                        formik.setFieldValue(
                            'vaultId',
                            e.target.value.replace(/\D/g, '')
                        )
                    }
                    handleClear={() => formik.setFieldValue('vaultId', '')}
                    label={t('labels.vaultID')}
                    error={formik.errors.vaultId}
                />
            )}

            {vaultTab === 'email' && (
                <TextField
                    name='email'
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    handleClear={() => formik.setFieldValue('email', '')}
                    label={t('labels.email')}
                    error={formik.errors.email}
                />
            )}

            {error && (
                <Alert
                    type='error'
                    text={error}
                    icon
                    className={styles.alert}
                />
            )}

            <div className={styles.bottom}>
                <Button
                    className={styles.nextButton}
                    disabled={!formik.values.vaultId && !formik.values.email}
                    onClick={onSubmitForm}
                    isLoading={isFetching}
                >
                    {t('buttons.next')}
                </Button>

                <GoogleReCaptcha
                    refreshReCaptcha={refreshReCaptcha}
                    onVerify={onChangeCaptcha}
                />
            </div>
        </>
    )
}

export default EnterVaultStepComponent
