import { ResponseBase } from 'models/Response'
import { SoftnoteColor } from 'models/Softnote'

export interface GetNominalsArgs {
    currencyKey: string
    color: SoftnoteColor
}

export interface GetNominalsResponse extends ResponseBase {
    currency: string
    nominals: number[]
    color: string
    maxBanknotes: number
}

export interface FillBlueStartArgs {
    tectumId: number | null
    vaultId: null
    email: string
    currency: string
    nominal: number
    token?: string | null
}

export interface FillBlueStartResponse extends ResponseBase {
    currency: string
    nominal: number
    address: string
    serialNumber: string
    id: string
}

export interface FillBlueAcceptArgs {
    id: string
    serialNumber: string
    hash: string
    address: string
    addressSender: string
    currency?: string
    nominal: number
    currencyKey: string
}

export interface FillBlueAcceptResponse extends ResponseBase {
    status: FillStatus
}

export interface CheckFillArgs {
    id: string
}

export interface CheckFillResponse extends ResponseBase {
    id: string
    status: FillStatus
    currency: number
    amount: number
    hashTransaction: string
    addressSender: string
    address: string
}

export enum FillStatus {
    Init = 'Init',
    Pending = 'Pending',
    Success = 'Success',
    Failed = 'Failed',
    Cancel = 'Cancel'
}

export interface SeparateGreenSoftnotesArgs {
    amount: number
    currency: string
    token: string | null
}

interface SoftnoteCount {
    nominal: number
    count: number
}

export interface SeparateGreenSoftnotesResponse extends ResponseBase {
    bills: SoftnoteCount[]
    closestNumber: number | null
}

export interface FillGreenSoftnotesArgs {
    tectumId: number | null
    vaultId: null
    email: string
    currency: string
    bills: SoftnoteCount[]
    token: string | null
}

export interface FillGreenSoftnotesResponse extends ResponseBase {
    id: string
}

export interface FillGreenAcceptArgs {
    id: string
    hash: string
    addressSender: string
    protocol: string
    ticker: string
    subProtocol: string
    subNetwork: string
    serialNumber?: string
    amount?: number
}

export interface FillGreenAcceptResponse extends ResponseBase {}

export interface GetMasterAccountArgs {
    currencyKey: string
}

export interface GetMasterAccountResponse extends ResponseBase {
    account: {
        ticker: string
        address: string
        network: string
        protocol: string
        supportProtocols: {
            protocol: string
            network: string
            address: string
        }[]
    }
}

export interface BanknoteArg {
    serialNumber: string
    pinCode: string
}

export interface BanknoteResponse {
    pinCode?: string
    privateKey?: string
    nominal?: number
    serialNumber: string
    errorCode: number | null
    errorMessage: string | null
}

export interface ImportArgs {
    walletId: string
    banknotes: BanknoteArg[]
    token?: string | null
}

export interface ImportResponse extends ResponseBase {
    banknotes: BanknoteResponse[]
}

export interface AcceptArgs {
    banknotes: BanknoteArg[]
    token?: string | null
}

export interface AcceptResponse extends ResponseBase {
    banknotes: BanknoteResponse[]
}

export interface HandoverArgs {
    banknotes: BanknoteArg[]
    email: string
    token?: string | null
}

export interface HandoverResponse extends ResponseBase {
    banknotes: BanknoteResponse[]
    isSentEmail: boolean
}

export interface BurnSuccessFile {
    content: string
}

export interface BurnBlueArgs {
    banknotes: BanknoteArg[]
    token?: string | null
}

export interface BurnBlueResponse extends ResponseBase {
    file: BurnSuccessFile
    banknotesWithError?: BanknoteResponse[]
}

export interface BurnPrepareGreenArgs {
    banknotes: { serialNumber: string }[]
    token?: string | null
}

export interface BurnPrepareGreenResponse extends ResponseBase {
    banknotes: BanknoteResponse[]
    totalAmount: number
    totalFee: number
    tectumFee: number
    networkFee: number
    currency: number
    currencyInfo: Currency
}

export interface BurnGreenArgs {
    banknotes: BanknoteArg[]
    walletAddress: string
    networkFee: number
    email: string
    subProtocol: string
    subNetwork: string
    token?: string | null
}

export interface BurnGreenResponse extends ResponseBase {
    requestId: string
}

export interface BurnCheckGreenArgs {
    requestId: string
}

export interface BurnCheckGreenResponse extends BurnPrepareGreenResponse {
    burnStatus: BurnStatus
    hashTransactionOut: string
    walletAddress: string
}

export enum BurnStatus {
    Pending = 'Pending',
    Success = 'Success',
    Failed = 'Failed',
    PartSuccess = 'PartSuccess'
}

export interface GetFeeResponse extends ResponseBase {
    networkFee: number
}

export interface GetLimitsResponse extends ResponseBase {
    maxHandoverBanknotes: number
    maxHandoverGreenBanknotes: number
    maxAcceptBanknotes: number
    maxAcceptGreenBanknotes: number
    maxBurnBanknotes: number
    maxBurnGreenBanknotes: number
    maxImportBanknotes: number
    maxFillGreenBanknotes: number
}

export interface Currency {
    id: number
    network: string
    protocol: string
    ticker: string
    key: string
    decimal: number
    isActual: boolean
}

export interface GetCurrenciesResponse extends ResponseBase {
    currencies: Currency[]
}
