import { BanknoteResponse } from "services/Hub/types"

export interface AcceptState {
    step: AcceptSteps
    banknotes: BanknoteResponse[] | null
}
export enum AcceptSteps {
    request = 'request',
    result = 'result'
}
