export const createDownloadUrlFromBase64 = (data: string) => {
    const decodedData = atob(data)
    const uint8Array = new Uint8Array(decodedData.length)
    for (let i = 0; i < decodedData.length; i++) {
        uint8Array[i] = decodedData.charCodeAt(i)
    }
    const blob = new Blob([uint8Array], { type: 'application/zip' })

    return URL.createObjectURL(blob)
}
