import { useMemo } from 'react'
import { HubService } from 'services/Hub'

type SoftnoteColor = 'blue' | 'green'

export function useCurrencies(color?: SoftnoteColor) {
    const {
        data: getCurrenciesResponse,
        isFetching,
        error
    } = HubService.useGetCurrenciesQuery()

    const currencies = useMemo(() => {
        if (!getCurrenciesResponse || !getCurrenciesResponse.currencies) {
            return undefined
        }

        return getCurrenciesResponse.currencies.reduce(
            (acc, currency) => {
                if (
                    currency.isActual &&
                    (color === undefined ||
                        (color === 'blue' && currency.id < 500) ||
                        (color === 'green' && currency.id >= 500))
                ) {
                    acc.push({
                        ...currency,
                        protocol: currency.protocol.toUpperCase(),
                        ticker: currency.ticker.toUpperCase()
                    })
                }
                return acc
            },
            [] as typeof getCurrenciesResponse.currencies
        )
    }, [getCurrenciesResponse, color])

    return { currencies, isFetching, error }
}
