import i18n from 'i18n'
import { SendModalOperations } from 'store/sendModal/sendModalTypes'
import * as Yup from 'yup'

const createValidationSchema = (operation: SendModalOperations) => {
    let schema = {
        email: Yup.string().email(),
        vaultId: Yup.string()
            .matches(/^\d+$/, ({ path }) =>
                i18n.t('onlyDigits', {
                    ns: 'validation',
                    field: i18n.t(`fields.${path}`, { ns: 'validation' })
                })
            )
            .max(9),
        tectumId: Yup.string()
            .matches(/^\d+$/, ({ path }) =>
                i18n.t('onlyDigits', {
                    ns: 'validation',
                    field: i18n.t(`fields.${path}`, { ns: 'validation' })
                })
            )
            .max(9),
        passcode: Yup.string()
            .required()
            .matches(/^[a-zA-Z0-9]+$/, ({ path }) =>
                i18n.t('onlyLatinLettersAndDigits', {
                    ns: 'validation',
                    field: i18n.t(`fields.${path}`, { ns: 'validation' })
                })
            )
    }

    if (operation === 'handover') {
        schema.email = schema.email.required()
    }
    if (operation === 'deposit') {
        schema.vaultId = schema.vaultId.required()
    }
    if (operation === 'import') {
        schema.tectumId = schema.tectumId.required()
    }

    return Yup.object().shape(schema)
}

export default createValidationSchema
