import { cashApi } from 'services'
import {
    AcceptArgs,
    AcceptResponse,
    BurnBlueArgs,
    BurnBlueResponse,
    BurnCheckGreenArgs,
    BurnCheckGreenResponse,
    BurnGreenArgs,
    BurnGreenResponse,
    BurnPrepareGreenArgs,
    BurnPrepareGreenResponse,
    CheckFillArgs,
    CheckFillResponse,
    FillBlueAcceptArgs,
    FillBlueAcceptResponse,
    FillBlueStartArgs,
    FillBlueStartResponse,
    FillGreenAcceptArgs,
    FillGreenAcceptResponse,
    FillGreenSoftnotesArgs,
    FillGreenSoftnotesResponse,
    GetCurrenciesResponse,
    GetFeeResponse,
    GetLimitsResponse,
    GetMasterAccountArgs,
    GetMasterAccountResponse,
    GetNominalsArgs,
    GetNominalsResponse,
    HandoverArgs,
    HandoverResponse,
    ImportArgs,
    ImportResponse,
    SeparateGreenSoftnotesArgs,
    SeparateGreenSoftnotesResponse
} from './types'

export const HubService = cashApi.injectEndpoints({
    endpoints: build => ({
        getNominals: build.query<GetNominalsResponse, GetNominalsArgs>({
            query: ({ color, currencyKey }) => ({
                url: `v2/softnote/denominations/${currencyKey}`,
                params: { color },
                method: 'GET'
            }),
            providesTags: (result, error, arg) => [
                { type: 'Nominals', id: arg.currencyKey + arg.color }
            ]
        }),
        fillSoftnotesBlueStart: build.query<
            FillBlueStartResponse,
            FillBlueStartArgs
        >({
            query: args => ({
                url: 'softnote/fill/start',
                body: args,
                method: 'POST'
            })
        }),
        fillSoftnotesBlueAccept: build.query<
            FillBlueAcceptResponse,
            FillBlueAcceptArgs
        >({
            query: args => ({
                url: 'softnote/fill/accept',
                body: args,
                method: 'POST'
            })
        }),
        checkFillSoftnotesBlue: build.query<CheckFillResponse, CheckFillArgs>({
            query: ({ id }) => ({
                url: `softnote/fill/check/${id}`,
                method: 'POST'
            })
        }),
        importSoftnotes: build.query<ImportResponse, ImportArgs>({
            query: args => ({
                url: 'softnote/import/list',
                body: args,
                method: 'POST'
            })
        }),
        handoverSoftnotes: build.query<HandoverResponse, HandoverArgs>({
            query: args => ({
                url: 'softnote/handover/list',
                body: args,
                method: 'POST'
            })
        }),
        acceptSoftnotes: build.query<AcceptResponse, AcceptArgs>({
            query: args => ({
                url: 'softnote/accept/list',
                body: args,
                method: 'POST'
            })
        }),
        burnSoftnotes: build.query<BurnBlueResponse, BurnBlueArgs>({
            query: args => ({
                url: 'softnote/burn/list',
                body: args,
                method: 'POST'
            })
        }),
        burnPrepareGreen: build.query<
            BurnPrepareGreenResponse,
            BurnPrepareGreenArgs
        >({
            query: args => ({
                url: 'sngreen/burn/info',
                body: args,
                method: 'POST'
            }),
            keepUnusedDataFor: 0
        }),
        burnGreen: build.query<BurnGreenResponse, BurnGreenArgs>({
            query: args => ({
                url: 'sngreen/burn/list',
                body: args,
                method: 'POST'
            })
        }),
        burnCheckGreen: build.query<BurnCheckGreenResponse, BurnCheckGreenArgs>(
            {
                query: args => ({
                    url: 'sngreen/burn/check',
                    body: args,
                    method: 'POST'
                })
            }
        ),
        separateGreenSoftnotes: build.query<
            SeparateGreenSoftnotesResponse,
            SeparateGreenSoftnotesArgs
        >({
            query: args => ({
                url: 'sngreen/fill/separate',
                body: args,
                method: 'POST'
            })
        }),
        fillGreenSoftnotes: build.query<
            FillGreenSoftnotesResponse,
            FillGreenSoftnotesArgs
        >({
            query: args => ({
                url: 'sngreen/fill',
                body: args,
                method: 'POST'
            })
        }),
        fillGreenAccept: build.query<
            FillGreenAcceptResponse,
            FillGreenAcceptArgs
        >({
            query: ({ id, ...restArgs }) => ({
                url: `sngreen/fill/${id}/accept`,
                body: restArgs,
                method: 'POST'
            })
        }),
        checkFillSoftnotesGreen: build.query<CheckFillResponse, CheckFillArgs>({
            query: ({ id }) => ({
                url: `sngreen/fill/check/${id}`,
                method: 'POST'
            })
        }),
        getMasterAccount: build.query<
            GetMasterAccountResponse,
            GetMasterAccountArgs
        >({
            query: ({ currencyKey }) => ({
                url: `v2/sngreen/master/account/${currencyKey}`,
                method: 'GET'
            })
        }),
        getFee: build.query<GetFeeResponse, string>({
            query: args => ({
                url: `fee/${args}`,
                method: 'GET'
            })
        }),
        getLimits: build.query<GetLimitsResponse, void>({
            query: () => ({
                url: `softnote/limits`,
                method: 'GET'
            })
        }),
        getCurrencies: build.query<GetCurrenciesResponse, void>({
            query: () => ({
                url: `currency/softnotes`,
                method: 'GET'
            })
        })
    })
})
