import SectionHeader from 'components/shared/SectionHeader'
import styles from '../styles.module.scss'
import { Alert, Button, TextArea } from '@crispmindltd/client-react-package'
import { FormikProps } from 'formik'
import { Values } from './types'
import { AppRoutes } from 'routes/AppRoutes'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useSoftnoteLimits } from 'hooks/useSoftnoteLimits'
import { useTranslation } from 'react-i18next'
import ExampleDataFormat from 'components/shared/ExampleDataFormat'

interface Props {
    formik: FormikProps<Values>
    handleChangeSoftnotes: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
    counter: number
    onClickBack: () => void
    fieldError: string
    error: string
    isLoading: boolean
    onSubmit: () => void
    onChangeCaptcha: (value: string | null) => void
    refreshReCaptcha: boolean
}

const BurnGreenRequestComponent: React.FC<Props> = (
    props
): React.ReactElement => {
    const {
        formik,
        handleChangeSoftnotes,
        counter,
        onClickBack,
        fieldError,
        error,
        isLoading,
        onSubmit,
        onChangeCaptcha,
        refreshReCaptcha
    } = props

    const { maxBurnGreenBanknotes } = useSoftnoteLimits()

    const { t } = useTranslation(['common', 'burn'])

    return (
        <>
            <SectionHeader onClickBack={onClickBack} title={t('labels.burn')} />

            <div className={styles.wrapper}>
                <div className={styles.tabs}>
                    <Button href={AppRoutes.burnBlue} type='secondary'>
                        {t('blueHub', { ns: 'burn' })}
                    </Button>
                    <Button>{t('greenHub', { ns: 'burn' })}</Button>
                </div>

                {error && <Alert type='error' text={error} icon />}

                <div className={styles.field}>
                    <TextArea
                        error={fieldError || formik.errors.softnotes}
                        className={styles.textArea}
                        placeholder={t('placeholders.softNoteSerialPasscode')}
                        onChange={handleChangeSoftnotes}
                        label={
                            <div className={styles.textAreaLabel}>
                                <div className={styles.title}>
                                    {t('labels.softnotes')}
                                </div>
                                <div
                                    className={
                                        counter <= maxBurnGreenBanknotes
                                            ? styles.counter
                                            : styles.counterError
                                    }
                                >
                                    {t('billsCount', {
                                        counter: counter,
                                        maxBurnBanknotes: maxBurnGreenBanknotes,
                                        ns: 'burn'
                                    })}
                                </div>
                            </div>
                        }
                        value={formik.values.softnotes}
                        name='softnote'
                    />

                    <ExampleDataFormat />
                </div>
            </div>

            <div className={styles.bottom}>
                <Button
                    className={styles.btn}
                    onClick={onSubmit}
                    isLoading={isLoading}
                    disabled={!formik.values.softnotes}
                >
                    {t('buttons.next')}
                </Button>

                <GoogleReCaptcha
                    refreshReCaptcha={refreshReCaptcha}
                    onVerify={onChangeCaptcha}
                />
            </div>
        </>
    )
}

export default BurnGreenRequestComponent
