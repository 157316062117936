import SelectCurrencyStepComponent from './component'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from 'routes/AppRoutes'
import { FillSteps } from 'store/fill/fillTypes'
import { useAppDispatch, useAppSelector } from 'store/store'
import { fillActions } from 'store/fill/fillSlice'
import { useEffect } from 'react'
import { hubActions } from 'store/hub/hubSlice'
import { useCurrencies } from 'hooks/useCurrencies'
import handleErrors from 'utils/functions/handleErrors'

const SelectCurrencyStep = () => {
    const dispatch = useAppDispatch()

    const navigate = useNavigate()

    const handleClickBack = () => {
        dispatch(hubActions.setOperation(null))
        navigate(AppRoutes.hub)
    }

    const { currencies, error, isFetching } = useCurrencies('blue')

    useEffect(() => {
        currencies &&
            dispatch(fillActions.setSelectedBlueCurrency(currencies[0]))
    }, [currencies])

    const selectedCurrency = useAppSelector(
        state => state.fill.selectedBlueCurrency
    )

    const selectOptions = currencies?.map(currency => ({
        value: currency.key,
        text: `${currency.ticker} (${currency.protocol})`.toUpperCase()
    }))

    const handleSelectCurrency = (currencyOption: {
        value: string
        text: string
    }) => {
        const currency = currencies?.find(
            currency => currency.key === currencyOption.value
        )
        if (!currency) {
            return
        }

        dispatch(fillActions.setSelectedBlueCurrency(currency))
    }

    const handleClickNext = () => {
        dispatch(fillActions.setStep(FillSteps.selectNominal))
    }

    return (
        <SelectCurrencyStepComponent
            onClickBack={handleClickBack}
            selectOptions={selectOptions}
            selectedCurrency={selectedCurrency}
            onSelectCurrency={handleSelectCurrency}
            onClickNext={handleClickNext}
            error={handleErrors(error)}
            isLoading={isFetching}
        />
    )
}

export default SelectCurrencyStep
