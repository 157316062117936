import React, { useEffect } from 'react'
import { BurnSteps } from 'store/burn/burnTypes'
import { useAppDispatch, useAppSelector } from 'store/store'
import BurnBlueRequest from './request'
import BurnBlueResult from './result'
import styles from './styles.module.scss'
import { hubActions } from 'store/hub/hubSlice'
import { HubOperations } from 'store/hub/hubTypes'

const BurnPageBlue: React.FC = (): React.ReactElement => {
    const step = useAppSelector(state => state.burn.stepBlue)

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(hubActions.setOperation(HubOperations.burnBlue))
    }, [])

    return (
        <section className={styles.section}>
            {step === BurnSteps.requestBlue && <BurnBlueRequest />}
            {step === BurnSteps.resultBlue && <BurnBlueResult />}
        </section>
    )
}

export default BurnPageBlue
