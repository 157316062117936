import SectionHeader from 'components/shared/SectionHeader'
import { Currency } from 'store/fill/fillTypes'
import styles from '../styles.module.scss'
import {
    Alert,
    Button,
    CopyButton,
    TextField
} from '@crispmindltd/client-react-package'
import { getCurrencyLabel } from 'utils/functions/currency'
import { useTranslation } from 'react-i18next'
import { useFillStatuses } from 'hooks/useFillStatuses'
import { FillStatus } from 'services/Hub/types'
import Loader from 'components/shared/Loader'
import { exponentialToDecimal } from 'utils/functions/renderBillsFunctions'

interface Props {
    currency: Currency | null
    amount: number | null
    onClickDone: () => void
    address: string
    senderAddress: string
    hashTransaction: string
    status: FillStatus
    isLoading: boolean
    error: string
}

const StatusCheckComponent: React.FC<Props> = props => {
    const {
        currency,
        amount,
        onClickDone,
        address,
        senderAddress,
        hashTransaction,
        status,
        isLoading,
        error
    } = props

    const { t } = useTranslation('common')
    const statuses = useFillStatuses()

    if (isLoading) {
        return <Loader className={styles.loader} />
    }

    if (error) {
        return (
            <Alert
                type='error'
                text={error}
                icon
                disableAnimation
                className={styles.alert}
            />
        )
    }

    return (
        <>
            <SectionHeader
                title={`${t('labels.fill')} ${getCurrencyLabel(currency)}`}
                subTitle={t('blueHub', { ns: 'fill' })}
                subTitleColor='#336BFF'
                withBackButton={false}
            />

            <Alert
                type={statuses[status].alertType}
                text={statuses[status].alertText}
                icon
                disableAnimation
                className={styles.alert}
            />

            <div className={styles.amountLabel}>{t('labels.amount')}</div>
            <div className={styles.amountValue}>
                {amount} {currency?.ticker}{' '}
                <CopyButton
                    className={styles.copyAmount}
                    value={exponentialToDecimal(amount ?? 0)}
                />
            </div>

            <div className={styles.fieldsWrapper}>
                <TextField
                    name='address'
                    label={t('labels.address')}
                    value={address}
                    readOnly
                    withCopy
                />

                <TextField
                    name='senderAddress'
                    label={t('labels.senderAddress')}
                    value={senderAddress}
                    readOnly
                    withCopy
                />

                <TextField
                    name='hashTransaction'
                    label={t('labels.hashTransaction')}
                    value={hashTransaction}
                    readOnly
                    withCopy
                />
            </div>

            <div className={styles.bottom}>
                <Button className={styles.nextButton} onClick={onClickDone}>
                    {t(
                        status !== FillStatus.Pending
                            ? 'buttons.done'
                            : 'buttons.backToHub'
                    )}
                </Button>
            </div>
        </>
    )
}

export default StatusCheckComponent
