export const IconDiscord: React.FC = (): React.ReactElement => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='21'
            height='20'
            viewBox='0 0 21 20'
            fill='none'
        >
            <rect
                x='2.67578'
                y='2.5'
                width='15'
                height='15'
                rx='4.16667'
                stroke='currentColor'
                strokeWidth='1.25'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M6.72524 7.19757C6.82098 7.03655 6.96666 6.91118 7.14014 6.8405C9.08877 6.05317 11.267 6.05317 13.2156 6.8405C13.3891 6.91118 13.5348 7.03655 13.6305 7.19757C14.51 8.68798 14.8925 10.4196 14.7226 12.1418C14.6966 12.4005 14.5498 12.6316 14.3268 12.7652C14.0069 12.9549 13.6742 13.1223 13.3313 13.2663C12.9337 13.4354 12.4726 13.278 12.2614 12.9012C12.133 12.6686 12.0151 12.4297 11.9078 12.1845C10.7682 12.4358 9.58755 12.4358 8.44798 12.1845C8.34053 12.4293 8.22265 12.6682 8.09433 12.9012C7.88313 13.278 7.42205 13.4354 7.02449 13.2663C6.68153 13.1224 6.34888 12.9549 6.02892 12.7652C5.80591 12.6316 5.65918 12.4005 5.63315 12.1418C5.46324 10.4196 5.84572 8.68798 6.72524 7.19757Z'
                stroke='currentColor'
                strokeWidth='1.25'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M11.5286 9.91707C11.5284 9.97454 11.4817 10.021 11.4242 10.0208C11.3667 10.0207 11.3202 9.97401 11.3203 9.91653C11.3204 9.85906 11.367 9.8125 11.4245 9.8125C11.4522 9.81235 11.4789 9.82333 11.4984 9.84299C11.518 9.86264 11.5289 9.88933 11.5286 9.91707'
                stroke='currentColor'
                strokeWidth='1.25'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M9.02864 9.91707C9.02842 9.97454 8.98169 10.021 8.92421 10.0208C8.86673 10.0207 8.82024 9.97401 8.82031 9.91653C8.82038 9.85906 8.867 9.8125 8.92448 9.8125C8.95222 9.81235 8.97886 9.82333 8.99844 9.84299C9.01802 9.86264 9.0289 9.88933 9.02864 9.91707'
                stroke='currentColor'
                strokeWidth='1.25'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export const IconFacebook: React.FC = (): React.ReactElement => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M6.25 2.5H13.7533C15.8225 2.5 17.5 4.1775 17.5 6.24667V13.7542C17.5 15.8225 15.8225 17.5 13.7533 17.5H6.24667C4.1775 17.5 2.5 15.8225 2.5 13.7533V6.25C2.5 4.17917 4.17917 2.5 6.25 2.5V2.5Z'
                stroke='currentColor'
                strokeWidth='1.25'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M9.25 10.7497H13.75'
                stroke='currentColor'
                strokeWidth='1.25'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M13.75 7H12.9625C11.7408 7 10.75 7.99083 10.75 9.2125V10V17.5'
                stroke='currentColor'
                strokeWidth='1.25'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export const IconInstagram: React.FC = (): React.ReactElement => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='21'
            height='20'
            viewBox='0 0 21 20'
            fill='none'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M6.36385 2.5H13.8714C15.9397 2.5 17.6172 4.17667 17.6172 6.24667V13.7542C17.6172 15.8225 15.9405 17.5 13.8705 17.5H6.36385C4.29469 17.5 2.61719 15.8233 2.61719 13.7533V6.24667C2.61719 4.1775 4.29385 2.5 6.36385 2.5V2.5Z'
                stroke='currentColor'
                strokeWidth='1.25'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M14.2405 5.59391C14.0855 5.59475 13.9596 5.72058 13.9596 5.87558C13.9596 6.03058 14.0863 6.15641 14.2413 6.15641C14.3963 6.15641 14.5221 6.03058 14.5221 5.87558C14.523 5.71975 14.3963 5.59391 14.2405 5.59391'
                stroke='currentColor'
                strokeWidth='1.25'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M12.2385 7.87868C13.4101 9.05026 13.4101 10.9497 12.2385 12.1213C11.0669 13.2929 9.16744 13.2929 7.99587 12.1213C6.82429 10.9497 6.82429 9.05026 7.99587 7.87868C9.16744 6.70711 11.0669 6.70711 12.2385 7.87868'
                stroke='currentColor'
                strokeWidth='1.25'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export const IconLinkedin: React.FC = (): React.ReactElement => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='21'
            height='20'
            viewBox='0 0 21 20'
            fill='none'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M6.30859 2.5H13.8119C15.8811 2.5 17.5586 4.1775 17.5586 6.24667V13.7542C17.5586 15.8225 15.8811 17.5 13.8119 17.5H6.30526C4.23609 17.5 2.55859 15.8225 2.55859 13.7533V6.25C2.55859 4.17917 4.23776 2.5 6.30859 2.5V2.5Z'
                stroke='currentColor'
                strokeWidth='1.25'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M6.82682 9.25V13.75'
                stroke='currentColor'
                strokeWidth='1.25'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M9.82422 13.75V11.125C9.82422 10.0892 10.6634 9.25 11.6992 9.25V9.25C12.7351 9.25 13.5742 10.0892 13.5742 11.125V13.75'
                stroke='currentColor'
                strokeWidth='1.25'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M6.82209 6.53141C6.71875 6.53141 6.63459 6.61558 6.63542 6.71891C6.63542 6.82225 6.71959 6.90641 6.82292 6.90641C6.92625 6.90641 7.01042 6.82225 7.01042 6.71891C7.01042 6.61475 6.92625 6.53141 6.82209 6.53141'
                stroke='currentColor'
                strokeWidth='1.25'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export const IconTelegram: React.FC = (): React.ReactElement => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='21'
            height='20'
            viewBox='0 0 21 20'
            fill='none'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M6.77734 2.5H14.2807C16.3498 2.5 18.0273 4.1775 18.0273 6.24667V13.7542C18.0273 15.8225 16.3498 17.5 14.2807 17.5H6.77401C4.70484 17.5 3.02734 15.8225 3.02734 13.7533V6.25C3.02734 4.17917 4.70651 2.5 6.77734 2.5V2.5Z'
                stroke='currentColor'
                strokeWidth='1.25'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M8.93967 12.9091L8.26301 10.6807C8.26217 10.6799 6.32467 10.0757 6.32467 10.0757C6.10301 10.0066 6.09217 9.69572 6.30967 9.61239L13.6855 6.76739C13.958 6.66239 14.2388 6.90156 14.1788 7.18739L12.9397 13.0274C12.8672 13.3699 12.4647 13.5224 12.1838 13.3141L10.4238 12.0141L9.38634 13.0241C9.24051 13.1641 8.99884 13.1016 8.93967 12.9091V12.9091Z'
                stroke='currentColor'
                strokeWidth='1.25'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export const IconTwitter: React.FC = (): React.ReactElement => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='21'
            height='20'
            viewBox='0 0 21 20'
            fill='none'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M6.89453 2.5H14.3979C16.467 2.5 18.1445 4.1775 18.1445 6.24667V13.7542C18.1445 15.8225 16.467 17.5 14.3979 17.5H6.8912C4.82203 17.5 3.14453 15.8225 3.14453 13.7533V6.25C3.14453 4.17917 4.8237 2.5 6.89453 2.5V2.5Z'
                stroke='currentColor'
                strokeWidth='1.25'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M14.2797 5.34693C14.0239 5.11514 13.6287 5.1346 13.3969 5.39039L11.1545 7.86498L9.37445 5.4402C9.2567 5.27981 9.06961 5.18506 8.87063 5.18506H6.26563C6.03019 5.18506 5.81473 5.31737 5.70823 5.52734C5.60173 5.73731 5.62226 5.98932 5.76134 6.17928L8.85399 10.4033L5.80267 13.7706C5.57089 14.0264 5.59035 14.4216 5.84614 14.6534C6.10192 14.8852 6.49717 14.8657 6.72895 14.6099L9.60785 11.4329L11.897 14.5595C12.0147 14.7203 12.2021 14.8153 12.4013 14.8153H15.0227C15.2583 14.8153 15.4738 14.6828 15.5803 14.4727C15.6867 14.2625 15.6659 14.0104 15.5265 13.8205L11.9095 8.89339L14.3232 6.22974C14.5549 5.97396 14.5355 5.57871 14.2797 5.34693ZM7.49784 6.43506L12.7183 13.5653H13.7885L8.55412 6.43506H7.49784Z'
                fill='currentColor'
            />
        </svg>
    )
}

export const IconYoutube: React.FC = (): React.ReactElement => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='21'
            height='20'
            viewBox='0 0 21 20'
            fill='none'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M18.5692 5.31081C18.3783 4.47414 17.8133 3.81414 17.095 3.58747C15.7958 3.18164 10.5833 3.18164 10.5833 3.18164C10.5833 3.18164 5.37333 3.18164 4.07167 3.58747C3.35583 3.81081 2.79083 4.47081 2.5975 5.31081C2.25 6.82914 2.25 9.99997 2.25 9.99997C2.25 9.99997 2.25 13.1708 2.5975 14.6891C2.78833 15.5258 3.35333 16.1858 4.07167 16.4125C5.37333 16.8183 10.5833 16.8183 10.5833 16.8183C10.5833 16.8183 15.7958 16.8183 17.095 16.4125C17.8108 16.1891 18.3758 15.5291 18.5692 14.6891C18.9167 13.1708 18.9167 9.99997 18.9167 9.99997C18.9167 9.99997 18.9167 6.82914 18.5692 5.31081Z'
                stroke='currentColor'
                strokeWidth='1.25'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M8.92188 12.5L13.2519 10L8.92188 7.5V12.5Z'
                stroke='currentColor'
                strokeWidth='1.25'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
