import {
    Alert,
    Button,
    ModalBody,
    ModalFooter,
    TextField
} from '@crispmindltd/client-react-package'
import { FormikProps } from 'formik'
import { Values } from './types'
import styles from '../styles.module.scss'
import classNames from 'classnames'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useTranslation } from 'react-i18next'
import { FetchBillResponse } from 'services/Bill/types'

interface Props {
    formik: FormikProps<Values>
    onSubmitForm: () => void
    operation: string
    onChangeTab: (operation: string) => void
    onClose: () => void
    isSuccess: boolean
    data: FetchBillResponse
    splitNumber: (num: string) => string
    isLoading: boolean
    error: any
    onChangeCaptcha: (value: string | null) => void
    refreshReCaptcha: boolean
}

const RequestFormComponent: React.FC<Props> = (props: Props) => {
    const {
        formik,
        onSubmitForm,
        operation,
        onChangeTab,
        isSuccess,
        onClose,
        data,
        splitNumber,
        isLoading,
        error,
        onChangeCaptcha,
        refreshReCaptcha
    } = props

    const { t } = useTranslation('common')

    return (
        <>
            <ModalBody className={styles.content}>
                {isSuccess ? (
                    <Alert
                        className={styles.alert}
                        type='success'
                        text={t('statuses.success')}
                        icon
                        disableAnimation
                    />
                ) : (
                    <div className={styles.tabs}>
                        <Button
                            type={
                                operation === 'import' ? 'primary' : 'secondary'
                            }
                            className={classNames(styles.tabButton, {
                                [styles.active]: operation === 'import'
                            })}
                            onClick={() => onChangeTab('import')}
                        >
                            {t('labels.import')}
                        </Button>
                        <Button
                            type={
                                operation === 'deposit'
                                    ? 'primary'
                                    : 'secondary'
                            }
                            className={classNames(styles.tabButton, {
                                [styles.active]: operation === 'deposit'
                            })}
                            onClick={() => onChangeTab('deposit')}
                            disabled
                        >
                            {t('labels.deposit')}
                        </Button>
                        <Button
                            type={
                                operation === 'handover'
                                    ? 'primary'
                                    : 'secondary'
                            }
                            className={classNames(styles.tabButton, {
                                [styles.active]: operation === 'handover'
                            })}
                            onClick={() => onChangeTab('handover')}
                        >
                            {t('labels.handover')}
                        </Button>
                    </div>
                )}

                {error && (
                    <Alert
                        style={{ marginBottom: '24px' }}
                        type='error'
                        text={error}
                        icon
                    />
                )}

                <div className={styles.fieldsWrapper}>
                    {operation === 'import' && (
                        <TextField
                            name='tectumId'
                            value={formik.values.tectumId}
                            onChange={formik.handleChange}
                            handleClear={() =>
                                formik.setFieldValue('tectumId', '')
                            }
                            label={t('labels.tectumID')}
                            error={formik.errors.tectumId}
                            readOnly={isSuccess}
                            withCopy
                        />
                    )}

                    {operation === 'deposit' && (
                        <TextField
                            name='vaultId'
                            value={formik.values.vaultId}
                            onChange={formik.handleChange}
                            handleClear={() =>
                                formik.setFieldValue('vaultId', '')
                            }
                            label={t('labels.vaultID')}
                            error={formik.errors.vaultId}
                            readOnly={isSuccess}
                            withCopy
                        />
                    )}

                    {operation === 'handover' && (
                        <TextField
                            name='email'
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            handleClear={() =>
                                formik.setFieldValue('email', '')
                            }
                            label={t('labels.email')}
                            error={formik.errors.email}
                            readOnly={isSuccess}
                            withCopy
                        />
                    )}

                    {!isSuccess && (
                        <div className={styles.passcodeField}>
                            <TextField
                                name='passcode'
                                value={formik.values.passcode}
                                onChange={formik.handleChange}
                                handleClear={() =>
                                    formik.setFieldValue('passcode', '')
                                }
                                label={
                                    <div className={styles.inputLabel}>
                                        {t('labels.passcode')}
                                    </div>
                                }
                                error={formik.errors.passcode}
                            />
                        </div>
                    )}
                </div>

                <div className={styles.amountLabel}>{t('labels.nominal')}</div>
                <div className={styles.amountValue}>
                    {data.nominal} {data.currencyInfo.ticker}
                </div>

                <div className={styles.amountLabel}>
                    {t('labels.softnoteNumber')}
                </div>
                <div className={styles.amountValue}>
                    {splitNumber(data.serialNumber)}
                </div>
            </ModalBody>

            <ModalFooter>
                {isSuccess ? (
                    <Button className={styles.nextButton} onClick={onClose}>
                        {t('buttons.close')}
                    </Button>
                ) : (
                    <Button
                        className={styles.nextButton}
                        disabled={
                            !formik.values.vaultId &&
                            !formik.values.email &&
                            !formik.values.tectumId &&
                            !formik.values.passcode
                        }
                        onClick={onSubmitForm}
                        isLoading={isLoading}
                    >
                        {t('buttons.finish')}
                    </Button>
                )}

                <GoogleReCaptcha
                    refreshReCaptcha={refreshReCaptcha}
                    onVerify={onChangeCaptcha}
                />
            </ModalFooter>
        </>
    )
}

export default RequestFormComponent
