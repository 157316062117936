import * as Yup from 'yup'

const validationSchema = () =>
    Yup.object().shape({
        email: Yup.string().email(),
        vaultId: Yup.string().max(9),
        tectumId: Yup.string().max(9)
    })

export default validationSchema
