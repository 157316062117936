import { formatNumber } from 'utils/functions/format'
import { Currency } from 'store/fill/fillTypes'
import styles from './styles.module.scss'
import { ChangeEvent } from 'react'

interface Props {
    nominal: number
    currency: Currency
    count: number
    onChangeCount: (nominal: number, count: number) => void
    totalBills: number
    maxBanknotes: number
}

const Softnote: React.FC<Props> = props => {
    const {
        nominal,
        count,
        onChangeCount,
        currency,
        totalBills,
        maxBanknotes
    } = props

    const isMaxReached = totalBills >= maxBanknotes

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newCount = Number(event.target.value)
        const newTotal = newCount + totalBills - count

        if (
            isNaN(newCount) ||
            newCount > maxBanknotes ||
            (newCount > count && newTotal > maxBanknotes)
        ) {
            return
        }

        onChangeCount(nominal, newCount)
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.info}>
                <span>
                    {formatNumber(nominal, currency.decimal)} {currency.ticker}
                </span>
            </div>

            <div className={styles.counter}>
                <button
                    className={count === 0 ? styles.disabled : ''}
                    onClick={() => onChangeCount(nominal, count - 1)}
                >
                    -
                </button>
                <input value={count} onChange={handleInputChange} />
                <button
                    className={isMaxReached ? styles.disabled : ''}
                    onClick={() => {
                        if (isMaxReached) return
                        onChangeCount(nominal, count + 1)
                    }}
                >
                    +
                </button>
            </div>
        </div>
    )
}

export default Softnote
