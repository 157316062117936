import { useAppDispatch, useAppSelector } from 'store/store'
import { HandoverSteps } from 'store/handover/handoverTypes'
import HandoverRequest from './request'
import HandoverResult from './result'
import styles from './styles.module.scss'
import { useEffect } from 'react'
import { hubActions } from 'store/hub/hubSlice'
import { HubOperations } from 'store/hub/hubTypes'

const HandoverPage: React.FC = (): React.ReactElement => {
    const step = useAppSelector(state => state.handover.step)

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(hubActions.setOperation(HubOperations.handover))
    }, [])

    return (
        <section className={styles.section}>
            {step === HandoverSteps.request && <HandoverRequest />}
            {step === HandoverSteps.result && <HandoverResult />}
        </section>
    )
}

export default HandoverPage
