import { BanknoteResponse } from 'services/Hub/types'

export interface ImportState {
    step: ImportSteps
    banknotes: BanknoteResponse[] | null
}
export enum ImportSteps {
    request = 'request',
    result = 'result'
}
