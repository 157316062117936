import { formatNumber } from 'utils/functions/format'
import { Currency } from 'store/fill/fillTypes'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'

interface Props {
    nominal: number
    currency: Currency
    bills: number
    amount: number
}

const BillInfo: React.FC<Props> = props => {
    const { nominal, currency, bills, amount } = props

    const { t } = useTranslation(['common'])

    return (
        <div className={styles.wrapper}>
            <div className={styles.info}>
                <span className={styles.nominal}>
                    {formatNumber(nominal, currency.decimal)} {currency.ticker}
                </span>
                <span className={styles.counter}>{`${t(
                    'labels.bills'
                )} ${bills}`}</span>
            </div>

            <div className={styles.info}>
                <span className={styles.counterL}>{t('labels.amount')}</span>
                <span className={styles.nominalL}>
                    {formatNumber(amount, currency.decimal)} {currency.ticker}
                </span>
            </div>
        </div>
    )
}

export default BillInfo
