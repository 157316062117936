import { HubOperations } from 'store/hub/hubTypes'
import { AppRoutes } from './AppRoutes'

export const operationRoutes = {
    [HubOperations.fillBlue]: AppRoutes.fillBlue,
    [HubOperations.fillGreen]: AppRoutes.fillGreen,
    [HubOperations.import]: AppRoutes.import,
    [HubOperations.handover]: AppRoutes.handover,
    [HubOperations.accept]: AppRoutes.accept,
    [HubOperations.burnBlue]: AppRoutes.burnBlue,
    [HubOperations.burnGreen]: AppRoutes.burnGreen,
    [HubOperations.deposit]: AppRoutes.deposit
}
