import { Trans, useTranslation } from 'react-i18next'
import styles from '../index.module.scss'
import {
    Button,
    ModalBody,
    ModalFooter
} from '@crispmindltd/client-react-package'

interface Props {
    onClose: () => void
}

const HelpContent: React.FC<Props> = props => {
    const { onClose } = props

    const { t } = useTranslation(['common', 'help'])

    return (
        <>
            <ModalBody>
                <p className={styles.title}>{t('howToSend', { ns: 'help' })}</p>
                <p className={styles.p}>
                    <Trans
                        i18nKey='1'
                        t={t}
                        ns='help'
                        components={{
                            link1: (
                                <a href='https://softnote.cash/bill?serialNumber=0000300000000019'>
                                    https://softnote.cash/bill?serialNumber=0000300000000019
                                </a>
                            ),
                            link2: (
                                <a href='https://softnote.cash'>
                                    https://softnote.cash
                                </a>
                            )
                        }}
                    />
                </p>
                <p className={styles.p}>
                    <Trans
                        i18nKey='2'
                        t={t}
                        ns='help'
                        components={{
                            link3: (
                                <a href='https://softnote.cash'>
                                    https://softnote.cash
                                </a>
                            )
                        }}
                    />
                </p>

                <p className={styles.p}>{t('3', { ns: 'help' })}</p>
                <p className={styles.p}>{t('4', { ns: 'help' })}</p>
                <p className={styles.p}>{t('5', { ns: 'help' })}</p>
            </ModalBody>

            <ModalFooter>
                <Button className={styles.closeButton} onClick={onClose}>
                    {t('buttons.close')}
                </Button>
            </ModalFooter>
        </>
    )
}

export default HelpContent
