export const AppRoutes = {
    event: '/event',
    hub: '/',
    bill: '/bill',
    fill: '/fill',
    fillBlue: '/fill-blue',
    fillGreen: '/fill-green',
    import: '/import',
    deposit: '/deposit',
    handover: '/handover',
    accept: '/accept',
    burnBlue: '/burn-blue',
    burnGreen: '/burn-green'
}
